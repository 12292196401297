import { CompositeDecorator, ContentState, convertFromRaw, EditorState } from 'draft-js';
import findLast from 'lodash/findLast';

import { PARAM_PLACEHOLDER } from 'components/LineMessageEditor/constants';

import type { URIAction } from '@line/bot-sdk';
import type { QuickReplyItems } from 'components/LineMessageEditor/components/QuickReplyEditor';
import type {
  CustomUriAction,
  ImageCarouselEditorData,
} from 'components/LineMessageEditor/models/templateDataAndTypes/imageCarousel';
import type {
  EditorDataType,
  EditorStateObject,
  ImageMapActionUrlType,
  ImageMapCustomParameterType,
} from 'components/LineMessageEditor/models/templateDataAndTypes/types';

import {
  ImageMapCarouselActions,
  isImageMapCarouselUriAction,
} from 'components/LineMessageEditor/models/templateDataAndTypes/imageMapCarousel';
import {
  CardEditorData,
  CarrouselData,
  ImageData,
  ImageMapActions,
  isCardDataRule,
  isCarrouselDataRule,
  isCustomParameter,
  isImageDataRule,
  isImageMapCarouselDataRule,
  isImageMapDataRule,
  isImageMapFlexDataRule,
  isLINEDataRule,
  isLinkParameter,
  isShareButtonParameter,
  isSMSDataRule,
  isTextDataRule,
} from 'components/LineMessageEditor/models/templateDataAndTypes/types';
import { toNumber } from 'utils/pitch-shifter/number';

import { parseFlexToImageMap } from '../components/ImageMapFlexEditor/parseFlexToImageMap';
import { composedDecorator } from '../components/RichTextEditor/Decorators/ComposedDecorator';
import { isImageCarouselDataRule } from '../models/templateDataAndTypes/imageCarousel';

import { parseWidthAndHeight } from './imageMapCarousel';
import {
  postProcessCardDataForState,
  revertCardBodyContents,
  sanitizeHeroImage,
} from './processCardData';
import { toEditorState } from './toEditorState';
import { isMappingKey, transformKey } from './transformKey';

export function richTextIdReIndex(initialData: EditorDataType[] | undefined): void {
  if (initialData && initialData.some((d) => isTextDataRule(d) && d.format === undefined)) {
    initialData.forEach((message, index) => {
      if (isTextDataRule(message)) {
        message.format = { ...message.format, richEditorId: index };
      }
    });
  }
}

/**
 * Parse the initial data and transform the rich text data to a draft-js editor eligible format data
 * @param initialData - editor data
 * @returns
 */
export function parseToState(initialData: EditorDataType[] | undefined): EditorStateObject[] {
  const finalStateArray = [] as EditorStateObject[];

  if (initialData) {
    initialData.forEach((message) => {
      if (isTextDataRule(message)) {
        try {
          finalStateArray.push({
            id: message.format.richEditorId,
            editorState: EditorState.createWithContent(
              convertFromRaw(JSON.parse(message.format.rowData)),
              new CompositeDecorator(composedDecorator),
            ),
          });
        } catch (error) {
          // If the "rowData" can not be parsed, we use plain text directly

          let convertText = message.data.text;

          // Process the custom api key (a kind of decorator available in text editor). Turn <$var:key> to <$var:mappingKey>.
          message.parameters.forEach((d) => {
            if (d.mappingKey) {
              convertText = convertText.replace(`var:${d.key}`, `var:${d.mappingKey}`);
            }
          });

          finalStateArray.push({
            id: message.format.richEditorId,
            editorState: EditorState.createWithContent(
              /**
               * By doing this, we can recover the content.
               * But the side effect is decorator in the editor can be modified at arbitrary cursor index.
               */
              ContentState.createFromText(convertText),
              new CompositeDecorator(composedDecorator),
            ),
          });
        }
      }
    });
  }

  return finalStateArray;
}

/**
 * Parse the `initialData` and transform the param rich text data to a draft-js
 * editor eligible formatted data - targeted for `CardEditor` and `CarrouselEditor`
 * data transformations and extractions.
 *
 * While transforming, this util function **WILL MUTATE** the `initialData`
 * by using {@link postProcessCardDataForState} for single-source-of-truth sake.
 *
 * @param initialData - editor data
 */
export function parseParamRichTextToStateWithSideEffects(
  initialData: EditorDataType[],
): EditorStateObject[] {
  const editorStates: EditorStateObject[] = [];
  if (!initialData) {
    return editorStates;
  }
  initialData.forEach((message) => {
    if (isCardDataRule(message)) {
      message.data.contents.body.contents = revertCardBodyContents(
        message.data.contents.body.contents,
        message.parameters,
      );
      const result = toEditorState(message.data);
      editorStates.push(...result);
      postProcessCardDataForState(result, message.data);
    } else if (isCarrouselDataRule(message)) {
      message.data.contents.forEach((cardData) => {
        cardData.contents.body.contents = revertCardBodyContents(
          cardData.contents.body.contents,
          message.parameters,
        );
        const result = toEditorState(cardData);
        editorStates.push(...result);
        postProcessCardDataForState(result, cardData);
      });
    }
  });
  return editorStates;
}

/**
 * Initialize image data
 *
 * @param initialData - Initial data
 */
export function imageDataInit(initialData: EditorDataType[] | undefined): EditorDataType[] {
  if (!initialData) return [];

  return initialData.map((message) => {
    if (isSMSDataRule(message)) {
      return message;
    }
    if (isImageDataRule(message)) {
      // Revert image placeholder if is custom
      const initMessage = JSON.parse(JSON.stringify(message)) as ImageData;
      const customImage = initMessage.parameters.find((p) =>
        isMappingKey('customImage', p.mappingKey),
      );
      if (customImage?.mappingKey) {
        initMessage.data.content_url = PARAM_PLACEHOLDER.image;
        initMessage.data.key = customImage.mappingKey;
      }
      return new ImageData(initMessage);
    }
    if (isImageMapFlexDataRule(message)) {
      const imageMapData = parseFlexToImageMap(message);
      const finalAction = imageMapData.data.actions.map((d) => {
        if (d.type === ImageMapActions.uri) {
          const customParameter = message.parameters.find(
            (c) => `<$var:${c.key}>` === (d as ImageMapActionUrlType).linkUri,
          ) as ImageMapCustomParameterType;
          if (customParameter && customParameter.mappingKey) {
            return {
              ...d,
              linkUri: (d as ImageMapActionUrlType).linkUri.replace(
                customParameter.key,
                customParameter.mappingKey,
              ),
            };
          }
          return d;
        }
        return d;
      });
      return {
        ...imageMapData,
        data: { ...imageMapData.data, actions: finalAction },
        activeBlockIndex: 1,
      };
    }
    if (isImageMapDataRule(message)) {
      const finalAction = message.data.actions.map((d) => {
        if (d.type === ImageMapActions.uri) {
          const customParameter = message.parameters.find(
            (c) => `<$var:${c.key}>` === (d as ImageMapActionUrlType).linkUri,
          ) as ImageMapCustomParameterType;
          if (customParameter && customParameter.mappingKey) {
            return {
              ...d,
              linkUri: (d as ImageMapActionUrlType).linkUri.replace(
                customParameter.key,
                customParameter.mappingKey,
              ),
            };
          }
          return d;
        }
        return d;
      });

      return {
        ...message,
        data: { ...message.data, actions: finalAction },
        activeBlockIndex: 1,
      };
    }
    if (isImageMapCarouselDataRule(message)) {
      // Find the smallest aspect ratio value from all imagemap carousel images
      const smallestAspectRatio = Math.min(
        ...message.data.contents.contents.map((imageMap) => {
          const [imageMapImage] = imageMap.body.contents;
          const { aspectRatioValue } = parseWidthAndHeight(imageMapImage.aspectRatio || '1:1');
          return aspectRatioValue;
        }),
      );

      return {
        ...message,
        data: {
          ...message.data,
          contents: {
            ...message.data.contents,
            contents: message.data.contents.contents.map((imageMap) => {
              const [imageMapImage, ...imageMapActionBlocks] = imageMap.body.contents;

              // Calculate the resize rate based on the smallest aspect ratio value
              const { aspectRatioValue } = parseWidthAndHeight(imageMapImage.aspectRatio || '1:1');
              const resizeRate = smallestAspectRatio / aspectRatioValue;

              const imageMapActionBlocksWithCustomUri = imageMapActionBlocks.map(
                ({ action, height, offsetTop, ...rest }) => {
                  /*
                   * Resize the height and offsetTop of the image map action block.
                   * Since we do the resizing before sending data to backend to comply LINE's spec, we need to covert them back to the original value after receiving data from backend.
                   */
                  const resizedBlock = {
                    ...rest,
                    height: height
                      ? `${(
                          toNumber(height.replace('%', ''), { defaultValue: 0 }) / resizeRate
                        ).toFixed(2)}%`
                      : undefined,
                    offsetTop: offsetTop
                      ? `${(
                          toNumber(offsetTop.replace('%', ''), { defaultValue: 0 }) / resizeRate
                        ).toFixed(2)}%`
                      : undefined,
                  };

                  if (
                    isImageMapCarouselUriAction(action) &&
                    isMappingKey('customUri', action.key)
                  ) {
                    return {
                      ...resizedBlock,
                      action: {
                        ...action,
                        // Convert action type "uri" to "customUri" since we use "customUri" in UI logic to distinguish URI and custom URI
                        type: ImageMapCarouselActions.customUri,
                      },
                    };
                  }
                  return {
                    ...resizedBlock,
                    action,
                  };
                },
              );

              imageMap.body.contents = [imageMapImage, ...imageMapActionBlocksWithCustomUri];
              return imageMap;
            }),
          },
        },
      };
    }
    if (isCardDataRule(message)) {
      const initMessage = JSON.parse(JSON.stringify(message)) as CardEditorData;
      const customHero = initMessage.parameters.find((p) =>
        isMappingKey('customHero', p.mappingKey),
      );
      const uris = initMessage.parameters.filter(
        (p) => isLinkParameter(p) || isMappingKey('customUri', p.mappingKey),
      );
      const finalFooterContents = initMessage.data.contents.footer.contents.map((content) => {
        if (content.action.type === 'uri') {
          // Revert Link Parameter and/or Custom URI Parameter to plain text key name
          const parameter = uris.find((u) => u?.mappingKey === content.key);
          if (parameter && isCustomParameter(parameter)) {
            content.action.uri = transformKey(parameter.key);
          }
        }
        return content;
      });
      // Revert image placeholder if is custom
      if (customHero && initMessage.data.contents.hero?.key) {
        initMessage.data.contents.hero.url = PARAM_PLACEHOLDER.image;
        initMessage.data.contents.hero.key = customHero.mappingKey;
      }
      initMessage.data = sanitizeHeroImage(initMessage.data);
      initMessage.data.contents.footer.contents = finalFooterContents;
      return new CardEditorData(initMessage);
    }
    if (isCarrouselDataRule(message)) {
      const initMessage = JSON.parse(JSON.stringify(message)) as CarrouselData;
      const customHeroes = initMessage.parameters.filter((p) =>
        isMappingKey('customHero', p.mappingKey),
      );
      const uris = initMessage.parameters.filter(
        (p) => isLinkParameter(p) || isMappingKey('customUri', p.mappingKey),
      );
      initMessage.data.contents.forEach((cardMessage, index) => {
        const finalFooterContents = cardMessage.contents.footer.contents.map((content) => {
          // Revert Link Parameter and/or Custom URI Parameter to plain text key name
          if (content.action.type === 'uri') {
            const parameter = uris.find((u) => u.mappingKey === content.key);
            if (parameter && (isCustomParameter(parameter) || isShareButtonParameter(parameter))) {
              content.action.uri = transformKey(parameter.key);
            }
          }
          return content;
        });
        initMessage.data.contents = initMessage.data.contents.map((card) => {
          const mappedCustomHero = customHeroes.find(
            (h) => h.mappingKey === cardMessage.contents?.hero?.key,
          );
          const isCardMappedWithCustomHero = isMappingKey(
            'customHero',
            mappedCustomHero?.mappingKey,
            card.contents.hero?.key,
          );
          if (
            mappedCustomHero?.mappingKey &&
            card.contents.hero?.key &&
            isCardMappedWithCustomHero
          ) {
            // Revert image placeholders by finding mapped custom heroes
            card.contents.hero.url = PARAM_PLACEHOLDER.image;
            card.contents.hero.key = mappedCustomHero.mappingKey;
            return card;
          } else {
            // Sanitize hero image if empty
            return sanitizeHeroImage(card);
          }
        });
        initMessage.data.contents[index].contents.footer.contents = finalFooterContents;
      });
      return new CarrouselData(initMessage);
    }
    if (isImageCarouselDataRule(message)) {
      const initMessage = JSON.parse(JSON.stringify(message)) as ImageCarouselEditorData;
      const customLinks = initMessage.parameters.filter((p) => isLinkParameter(p));
      const customImages = initMessage.parameters.filter((p) =>
        isMappingKey('customImage', p.mappingKey),
      );
      const customUris = initMessage.parameters.filter((p) =>
        isMappingKey('customUri', p.mappingKey),
      );
      initMessage.data.contents = initMessage.data.contents.map((content) => {
        const mappedCustomImage = customImages.find(
          (img) => img.mappingKey === transformKey(content.hero.contents[0].key),
        );
        const mappedCustomUri = customUris.find(
          (uri) =>
            uri.mappingKey === transformKey((content.hero.action as CustomUriAction)?.key ?? ''),
        );
        const mappedCustomLink = customLinks.find(
          (link) => link.key === transformKey((content.hero.action as URIAction)?.uri ?? ''),
        );
        if (mappedCustomImage?.mappingKey) {
          content.hero.contents[0].url = PARAM_PLACEHOLDER.image;
          content.hero.contents[0].key = mappedCustomImage.mappingKey;
        }
        if (mappedCustomUri?.mappingKey) {
          (content.hero.action as CustomUriAction).type = 'customUri';
          (content.hero.action as CustomUriAction).key = mappedCustomUri.mappingKey;
          (content.hero.action as CustomUriAction).uri = mappedCustomUri.key;
        }
        if (mappedCustomLink?.key) {
          (content.hero.action as URIAction).type = 'uri';
          (content.hero.action as URIAction).uri = mappedCustomLink.key;
        }
        return content;
      });
      return initMessage;
    }
    return message;
  });
}

export function getQuickReplyItems(messages?: EditorDataType[]): QuickReplyItems {
  const lastReplyMessage = findLast(messages, isLINEDataRule);
  if (!lastReplyMessage) return [];
  return lastReplyMessage.quick_reply?.items ?? [];
}
