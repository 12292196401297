import PictureOutlined from '@ant-design/icons/PictureOutlined';
import classnames from 'classnames';
import { Children, useCallback, useContext, useState } from 'react';
import { useAsync } from 'react-async';
import { SketchPicker } from 'react-color';
import { Trans, useTranslation } from 'react-i18next';

import type { Feed, NewsEditorProps } from './types';
import type { ReactNode } from 'react';
import type { ColorChangeHandler } from 'react-color';

import { Box, Divider, Flex, Loading, StyledIcon, Text } from 'components';
import { FileInput } from 'components/Input';
import { FeedSelectPopover } from 'components/LineMessageEditor/components/NewsEditor/components/FeedSelectPopover';
import { Context } from 'components/LineMessageEditor/models';
import { DeleteSvg } from 'icons/delete';
import { isAxiosError } from 'lib/axios';
import { rss as rssParser } from 'lib/parser/rss';
import * as Validator from 'lib/validator';
import {
  notificationTextNameHelper,
  rssUrlNameHelper,
} from 'lib/validator/helper/validatorHelpers';
import { FieldScopeProvider } from 'lib/validator/hooks/useValidateScope';
import { Button } from 'shared/components/Button';
import { Input } from 'shared/components/Input';
import { Popover } from 'shared/components/Popover';
import { Tooltip } from 'shared/components/Tooltip';
import { useMessage } from 'shared/hooks/ui/useMessage';
import { theme } from 'theme';
import { lowerFirst } from 'utils/string/changeCase';
import { inspectMessage } from 'utils/string/inspectMessage';

import { UrlUTMModule } from '../UrlUTMModule';

import { EditorContextProvider, useEditorContext } from './contexts';
import { useNewsEditorState, useUploadImage } from './hooks';
import * as S from './styled';
import { castItemToFeed, deferRss, join, pickValidFeed } from './utils';

const NotificationInput = () => {
  const { t } = useTranslation();

  const { rowIndex, message, setNotificationText } = useEditorContext();

  return (
    <>
      <S.PopupSubTitle>
        <Text>{t('message.notificationText')}</Text>
      </S.PopupSubTitle>
      <S.PopupSubContent>
        <Validator.FieldInput
          name={notificationTextNameHelper({
            rowIndex: rowIndex,
            entityKey: '',
            editorType: 'NewsEditor',
          })}
          rules={[Validator.Rules.maxLength(300), Validator.Rules.required]}
          onChange={(e) => {
            setNotificationText({ rowIndex, notificationText: e.target.value });
          }}
          placeholder={t('message.inputWithLengthLimit', {
            fieldName: lowerFirst(t('message.notificationText')),
            length: 300,
          })}
          value={message.data.notification_text}
          enableReinitialize={true}
          checkOnChange={true}
        ></Validator.FieldInput>
      </S.PopupSubContent>
    </>
  );
};

const HeadlineUrlInput = () => {
  const { t } = useTranslation();
  const { rowIndex, message, setUrlData, setUrlAndUTM } = useEditorContext();

  const parameter = message.parameters.find((d) => d.key === 'url1');

  return (
    <>
      <S.PopupSubTitle>
        <Text>{t('glossary.urlAction')}</Text>
      </S.PopupSubTitle>
      <Box>
        <FieldScopeProvider>
          <UrlUTMModule
            rowIndex={rowIndex}
            entityKey=""
            urlValue={parameter?.data.url ?? ''}
            openExternal={parameter?.data.open_external_browser ?? false}
            onOpenExternalChange={(openExternal) => {
              setUrlData({ rowIndex, openExternal });
            }}
            onFieldsChange={(url, utmFields) => {
              setUrlAndUTM({ rowIndex, url, utmFields });
            }}
            UTMSourceValue={parameter?.data.utm_source ?? ''}
            onUTMSourceChange={(text) => {
              setUrlData({ rowIndex, type: 'utm_source', text });
            }}
            UTMMediumValue={parameter?.data.utm_medium ?? ''}
            onUTMMediumChange={(text) => {
              setUrlData({ rowIndex, type: 'utm_medium', text });
            }}
            UTMCampaignValue={parameter?.data.utm_campaign ?? ''}
            onUTMCampaignChange={(text) => {
              setUrlData({ rowIndex, type: 'utm_campaign', text });
            }}
            UTMContentValue={parameter?.data.utm_content ?? ''}
            onUTMContentChange={(text) => {
              setUrlData({ rowIndex, type: 'utm_content', text });
            }}
            tagList={parameter?.data.tag_list ?? []}
            onTagChange={(tagList) => {
              setUrlData({ rowIndex, tagList });
            }}
            editorType="NewsEditor"
          ></UrlUTMModule>
        </FieldScopeProvider>
      </Box>
    </>
  );
};

const GetOpenGraph = () => {
  const { message, ogLoader } = useEditorContext();
  const parameter = message.parameters.find((d) => d.key === 'url1');

  return (
    <Box mb="20px">
      <Button
        loading={ogLoader.isLoading}
        onClick={() => {
          ogLoader.run(parameter?.data.url ?? '');
        }}
      >
        <span>
          <Trans>common.uploadTitleImage</Trans>
        </span>
      </Button>
    </Box>
  );
};

const CoverImageUploadInput = () => {
  const { t } = useTranslation();

  const { rowIndex, message, setHeadlineImageUrl } = useEditorContext();
  const [image, ,] = message.data.contents.header.contents[0].contents;
  const { onUploadImage, isLoading } = useUploadImage();

  return (
    <>
      <S.PopupSubTitle>
        <Text>
          <Trans>glossary.image</Trans>
          <Text color={theme.colors.neutral007} textAlign="center">
            {t('message.breakingNews.fileSpec')}
          </Text>
        </Text>
      </S.PopupSubTitle>
      <S.PopupSubContent>
        <Tooltip
          placement="right"
          overlayClassName="transparent"
          align={{ offset: [0, 0] }}
          title={
            image.url ? (
              <S.ToolBox>
                <StyledIcon
                  fontSize={20}
                  component={<DeleteSvg />}
                  className="delete-icon"
                  color={theme.colors.neutral007}
                  onClick={() => setHeadlineImageUrl({ rowIndex, url: '' })}
                />
              </S.ToolBox>
            ) : undefined
          }
        >
          <S.UploadImageLabel type="imageMap" bgUrl={image.url ?? ''}>
            <FileInput
              accept="image/jpeg,image/png,image/gif"
              onChange={(e) =>
                onUploadImage(e, (uploadUrl) => {
                  setHeadlineImageUrl({ rowIndex, url: uploadUrl });
                })
              }
            />
            {!isLoading && image.url === '' ? t('common.upload') : null}
            {isLoading ? <Loading /> : null}
            {!isLoading && image.url !== '' ? (
              <S.ImageHoverLayer>
                <PictureOutlined />
              </S.ImageHoverLayer>
            ) : null}
          </S.UploadImageLabel>
        </Tooltip>
      </S.PopupSubContent>
    </>
  );
};

const HeadlineTitleInput = () => {
  const { rowIndex, message, setHeadlineTitle } = useEditorContext();
  const [, , target] = message.data.contents.header.contents[0].contents;
  return (
    <>
      <S.PopupSubTitle>
        <Trans>glossary.title</Trans>
      </S.PopupSubTitle>
      <S.PopupSubContent>
        <Input
          value={target.contents ? target.contents[0].text : ''}
          onChange={(e) => {
            setHeadlineTitle({ rowIndex, text: e.target.value });
          }}
        />
      </S.PopupSubContent>
    </>
  );
};

const HeadlineLabelTextInput = () => {
  const { rowIndex, message, setLabelText } = useEditorContext();
  const { label } = message.format;
  const text = label ? label.text : '';
  return (
    <>
      <S.PopupSubTitle>
        <Trans>message.breakingNews.tags</Trans>
      </S.PopupSubTitle>
      <S.PopupSubContent>
        <Input
          value={text}
          maxLength={25}
          onChange={(e) => {
            setLabelText({ rowIndex, text: e.target.value });
          }}
        />
      </S.PopupSubContent>
    </>
  );
};

const defaultLabelBackgroundColor = '#EE220Cdd';

const HeadlineLabelBackgroundColor = () => {
  const { rowIndex, message, setLabelBackgroundColor } = useEditorContext();
  const { label } = message.format;
  const backgroundColor = label ? label.backgroundColor : defaultLabelBackgroundColor;
  const handleBackgroundColorChanged: ColorChangeHandler = (color) => {
    setLabelBackgroundColor({ rowIndex, backgroundColor: color.hex });
  };
  return (
    <S.PopupSubTitle style={{ display: 'flex' }}>
      <Trans>message.breakingNews.tagsBackground</Trans>
      <Popover
        content={
          <div style={{ margin: '-12px -16px' }}>
            <SketchPicker
              onChange={handleBackgroundColorChanged}
              color={backgroundColor}
            ></SketchPicker>
          </div>
        }
        trigger="click"
      >
        <S.Picker style={{ backgroundColor: backgroundColor }}></S.Picker>
      </Popover>
    </S.PopupSubTitle>
  );
};

const HeadlineEditorPopover = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation();

  const content = (
    <S.HeadlineEditorWrapper>
      <NotificationInput />
      <HeadlineUrlInput />
      <GetOpenGraph />
      <CoverImageUploadInput />
      <HeadlineTitleInput />
      <HeadlineLabelTextInput />
      <HeadlineLabelBackgroundColor />
    </S.HeadlineEditorWrapper>
  );
  return (
    <Popover
      trigger="click"
      placement="right"
      title={t('common.editModule', { module: lowerFirst(t('glossary.title')) })}
      content={content}
    >
      {children}
    </Popover>
  );
};

const FeedsEditorPopover = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation();

  const { message: messageToast } = useMessage();

  const { rowIndex, message, setRssUrl, setNewsFeeds } = useEditorContext();
  const [feeds, setFeeds] = useState<Feed[]>([]);
  const [selectPopoverVisible, setSelectPopoverVisible] = useState(false);
  const [urlPopoverVisible, setUrlPopoverVisible] = useState(false);

  const rssLoader = useAsync({
    deferFn: deferRss,
    onResolve: useCallback(
      (document: XMLDocument) => {
        try {
          const rss = rssParser.parse(document);
          const feeds = rss.items.filter(pickValidFeed).map(castItemToFeed);
          setFeeds(feeds);
          setUrlPopoverVisible(false);
          setSelectPopoverVisible(true);
        } catch (nativeError) {
          const error =
            nativeError instanceof Error
              ? nativeError
              : new Error(inspectMessage`rssLoaderError: ${nativeError}`);
          messageToast.error(t(error.message));
        }
      },
      [messageToast, t],
    ),
    onReject: useCallback(
      (e) => {
        if (isAxiosError(e)) {
          messageToast.error(t('Fail to fetch rss'));
        }
      },
      [messageToast, t],
    ),
  });

  const content = (
    <Box width="391px">
      <S.PopupSubTitle>
        <Text>{t('message.rssUrl')}</Text>
      </S.PopupSubTitle>
      <S.PopupSubContent>
        <Validator.FieldInput
          name={rssUrlNameHelper({
            rowIndex: rowIndex,
            entityKey: '',
            editorType: 'NewsEditor',
          })}
          rules={[Validator.Rules.url, Validator.Rules.required]}
          onChange={(e) => {
            setRssUrl({ rowIndex, rss: e.target.value });
          }}
          placeholder={t('common.inputFieldName', { fieldName: t('message.rssUrl') })}
          value={message.format.rss}
          enableReinitialize={true}
          checkOnChange={true}
        ></Validator.FieldInput>
      </S.PopupSubContent>
      <Button
        loading={rssLoader.isLoading}
        onClick={() => {
          rssLoader.run(message.format.rss);
        }}
      >
        <span>
          <Trans>common.import</Trans>
        </span>
      </Button>
    </Box>
  );
  return (
    <>
      <Popover
        trigger="click"
        placement="right"
        title={t('message.rssUrl')}
        content={content}
        open={urlPopoverVisible}
        onOpenChange={(visible) => setUrlPopoverVisible(visible)}
      >
        {children}
      </Popover>
      <FeedSelectPopover
        feeds={feeds}
        open={selectPopoverVisible}
        onOk={(newsFeeds) => {
          setNewsFeeds({ rowIndex, feeds: newsFeeds });
          setSelectPopoverVisible(false);
        }}
        onCancel={() => {
          setSelectPopoverVisible(false);
        }}
      />
    </>
  );
};

const EditorProvider = ({ children, ...props }: NewsEditorProps & { children: ReactNode }) => {
  const editorState = useNewsEditorState(props.rowIndex);
  return (
    <EditorContextProvider value={{ ...props, ...editorState }}>{children}</EditorContextProvider>
  );
};

export const NewsEditor = ({ rowIndex, message }: NewsEditorProps) => {
  const { store } = useContext(Context);
  const { t } = useTranslation();

  return (
    <EditorProvider rowIndex={rowIndex} message={message}>
      <S.NewsEditor className={classnames({ 'is-drag-mode': store.isDragMode })}>
        <HeadlineEditorPopover>
          <S.HeadlineEditor>
            <Flex alignItems="center" flexDirection="column">
              <StyledIcon
                mb={2}
                fontSize={19}
                width={21}
                height={21}
                color={theme.colors.neutral006}
                component={<PictureOutlined />}
              />
              <Text color={theme.colors.neutral008} mb="8px">
                <Trans values={{ object: lowerFirst(t('glossary.image')) }}>
                  common.uploadObject
                </Trans>
              </Text>
              <Text color={theme.colors.neutral007} textAlign="center">
                {t('message.breakingNews.fileSpec')}
              </Text>
            </Flex>
          </S.HeadlineEditor>
        </HeadlineEditorPopover>
        <Divider />
        <FeedsEditorPopover>
          <S.FeedEditor>
            {Children.toArray(
              join(
                [80, 60, 99, 90, 75, 40, 65].map((width) => (
                  <Box lineHeight="20px" p="10px">
                    <Box height="16px" bg="#f2f2f2" borderRadius="8px" width={`${width}%`} />
                  </Box>
                )),
                <Divider />,
              ),
            )}
          </S.FeedEditor>
        </FeedsEditorPopover>
      </S.NewsEditor>
    </EditorProvider>
  );
};
