import { Fragment, useContext, useState } from 'react';

import type { QuickReplyItems } from '../components/QuickReplyEditor';
import type { EditorStateObject } from '../models/templateDataAndTypes/types';
import type { BoxProps } from 'components';
import type { ReactNode, RefObject } from 'react';

import { Box } from 'components';
import { PreviewImageHint } from 'components/LineMessageEditor/components/PreviewImageHint';
import { Context } from 'components/LineMessageEditor/models';
import {
  isCardDataRule,
  isCarrouselDataRule,
  isFlexDataRule,
  isImageCarouselDataRule,
  isImageDataRule,
  isImageMapCarouselDataRule,
  isImageMapDataRule,
  isNewsDataRule,
  isPrizeRedeemRule,
  isSMSDataRule,
  isTextDataRule,
  isVideoDataRule,
  LineFieldTypeName,
} from 'components/LineMessageEditor/models/templateDataAndTypes/types';
import { CardPreview } from 'components/LineMessageEditor/previewComponents/CardPreview';
import { CarouselPreview } from 'components/LineMessageEditor/previewComponents/CarouselPreview';
import { FlexPreview } from 'components/LineMessageEditor/previewComponents/FlexPreview';
import { ImageCarouselPreview } from 'components/LineMessageEditor/previewComponents/imageCarouselPreview';
import { ImageMapCarouselPreview } from 'components/LineMessageEditor/previewComponents/ImageMapCarouselPreview';
import { ImageMapPreview } from 'components/LineMessageEditor/previewComponents/ImageMapPreview';
import { ImagePreview } from 'components/LineMessageEditor/previewComponents/ImagePreview';
import { NotificationPreview } from 'components/LineMessageEditor/previewComponents/NotificationPreview';
import { QuickReplyPreview } from 'components/LineMessageEditor/previewComponents/QuickReplyPreview';
import { RichTextPreview } from 'components/LineMessageEditor/previewComponents/RichTextPreview';
import { SMSPreview } from 'components/LineMessageEditor/previewComponents/SMSPreview';
import { VideoPreview } from 'components/LineMessageEditor/previewComponents/VideoPreview';
import { useHandler } from 'hooks/useEventCallback';
import { theme } from 'theme';

import * as S from './Styled';

interface LineMessagePreviewProps extends Omit<LineMessagePreviewWrapperProps, 'texts'> {
  editorStateArray: EditorStateObject[];
  quickReplyItems: QuickReplyItems;
  headingMessage?: React.ReactNode;
}

export const LineMessagePreview = ({
  editorStateArray,
  quickReplyItems,
  headingMessage,
  ...restBoxProps
}: LineMessagePreviewProps) => {
  const { store } = useContext(Context);
  const [userMessageTextArray, setUserMessageTextArray] = useState<string[]>([]);
  const onAddUserMessageText = useHandler((text: string) => {
    setUserMessageTextArray((userMessageTextArray) => [...userMessageTextArray, text]);
  });

  return (
    <LineMessagePreviewWrapper texts={userMessageTextArray} {...restBoxProps}>
      {store.editorData.length > 0 ? (
        <NotificationPreview
          key="NotificationPreviewBox"
          editorStateArray={editorStateArray}
          message={store.editorData[0] ?? undefined}
        />
      ) : null}
      {headingMessage}
      {store.editorData.map((message, index) => {
        if (isTextDataRule(message)) {
          return editorStateArray.length !== 0 ? (
            <RichTextPreview
              key={index + 'RichTextPreview'}
              editorStateArray={editorStateArray}
              message={message}
            />
          ) : null;
        } else if (isImageDataRule(message)) {
          return message.data.content_url ? (
            <Fragment key={index + 'ImagePreview'}>
              <ImagePreview src={message.data.content_url} parameters={message.parameters} />
              <PreviewImageHint />
            </Fragment>
          ) : null;
        } else if (isImageMapDataRule(message)) {
          return message.data.base_url ? (
            <Fragment key={index + 'ImageMapPreview'}>
              <ImageMapPreview
                src={message.data.base_url}
                message={message}
                onAddUserMessageText={onAddUserMessageText}
              />
              <PreviewImageHint />
            </Fragment>
          ) : null;
        } else if (isImageMapCarouselDataRule(message)) {
          return (
            <Fragment key={index + 'ImageMapCarouselPreview'}>
              <ImageMapCarouselPreview
                message={message}
                onAddUserMessageText={onAddUserMessageText}
              />
              <PreviewImageHint />
            </Fragment>
          );
        } else if (isCardDataRule(message)) {
          return (
            <Fragment key={index + 'CardPreview'}>
              <CardPreview
                src={message.data.contents.hero ? message.data.contents.hero.url : ''}
                cardData={message.data}
                parameters={message.parameters}
                editorStateArray={editorStateArray}
                onAddUserMessageText={onAddUserMessageText}
              />
              <PreviewImageHint />
            </Fragment>
          );
        } else if (isCarrouselDataRule(message)) {
          return (
            <Fragment key={index + 'CarouselPreview'}>
              <Box mb="8px">
                <CarouselPreview
                  data={message.data.contents.map((c) => ({
                    src: c.contents.hero ? c.contents.hero.url : '',
                    cardData: c,
                    parameters: message.parameters,
                    editorStateArray,
                    onAddUserMessageText,
                  }))}
                />
              </Box>
              <PreviewImageHint />
            </Fragment>
          );
        } else if (isImageCarouselDataRule(message)) {
          return (
            <Fragment key={index + 'ImageCarouselPreview'}>
              <Box mb="8px">
                <ImageCarouselPreview
                  rowIndex={index}
                  message={message}
                  onAddUserMessageText={onAddUserMessageText}
                />
              </Box>
              <PreviewImageHint />
            </Fragment>
          );
        } else if (
          isNewsDataRule(message) ||
          isFlexDataRule(message) ||
          isPrizeRedeemRule(message)
        ) {
          return (
            <FlexPreview
              key={index + 'FlexPreview'}
              rowIndex={index}
              message={message}
              onAddUserMessageText={onAddUserMessageText}
              data-test={`flex-preview-${LineFieldTypeName[message.module_id]}`}
            />
          );
        } else if (isSMSDataRule(message)) {
          return <SMSPreview key={index + 'SMSPreview'} message={message} />;
        } else if (isVideoDataRule(message)) {
          if (message.data.video.originalContentUrl)
            return <VideoPreview key={index + 'VideoPreview'} message={message} />;
        }
        return null;
      })}
      {store.editorData.length > 0 ? (
        <QuickReplyPreview
          quickReplyItems={quickReplyItems}
          onAddUserMessageText={onAddUserMessageText}
        />
      ) : null}
    </LineMessagePreviewWrapper>
  );
};

type LineLiffPreviewProps = BoxProps & { children: ReactNode } & {
  title: string;
  subTitle: string;
};

export const LineLiffPreview = ({
  children,
  title,
  subTitle,
  ...restBoxProps
}: LineLiffPreviewProps) => {
  return (
    <LineMessagePreviewWrapper texts={[]} {...restBoxProps}>
      <Box position="relative" top="20%" height="80%" overflow="hidden">
        <Box height="40px" textAlign="center" backgroundColor="white" borderRadius="10px 10px 0 0">
          <Box lineHeight="1.7">{title}</Box>
          <Box lineHeight="1" fontSize="9px" color={theme.colors.neutral005}>
            {subTitle}
          </Box>
        </Box>
        <Box height="100%" overflow="auto">
          {children}
        </Box>
      </Box>
    </LineMessagePreviewWrapper>
  );
};

type LineMessagePreviewWrapperProps = BoxProps & { children?: ReactNode } & {
  disableAnimation?: boolean;
  texts: string[];
  bottomChildren?: ReactNode;
  outerWrapperChildren?: ReactNode;
  contentRef?: ((instance: HTMLDivElement | null) => void) | RefObject<HTMLDivElement> | null;
};

export const LineMessagePreviewWrapper = ({
  disableAnimation,
  contentRef,
  children,
  texts,
  bottomChildren,
  outerWrapperChildren,
  ...restBoxProps
}: LineMessagePreviewWrapperProps) => {
  return (
    <S.PreviewWrapperAniWrapper data-test="preview-wrapper" {...restBoxProps}>
      <S.PreviewWrapper>
        <S.LineContent ref={contentRef}>
          {children}
          <S.LineUserBlockWrapper data-test="userBlock-preview">
            {texts.map((text, index) => {
              return (
                <div key={'usermessage' + index}>
                  <S.LineMessageBubble>{text}</S.LineMessageBubble>
                </div>
              );
            })}
          </S.LineUserBlockWrapper>
          {bottomChildren}
        </S.LineContent>
        {outerWrapperChildren}
        <S.HomeButton />
      </S.PreviewWrapper>
    </S.PreviewWrapperAniWrapper>
  );
};
