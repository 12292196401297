import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import type {
  ImageMapDataType,
  LinkParameter,
} from 'components/LineMessageEditor/models/templateDataAndTypes/types';

import {
  isImageMapDataActionCustomUrlRule,
  isImageMapDataActionMessageRule,
  isImageMapDataActionUrlRule,
} from 'components/LineMessageEditor/models/templateDataAndTypes/types';
import { transformKey } from 'components/LineMessageEditor/utils/transformKey';
import { Tooltip, TooltipDescription } from 'shared/components/Tooltip';

import * as S from './Styled';

const IMAGE_WIDTH = S.IMAGEMAP_PREVIEW_IMAGE_WIDTH;
interface ImageMapPreviewProps {
  src: string;
  message: ImageMapDataType;
  onAddUserMessageText: (text: string) => void;
}

export const ImageMapPreview = ({ src, message, onAddUserMessageText }: ImageMapPreviewProps) => {
  const { t } = useTranslation();
  const wrapperEl = useRef(null);
  return (
    <S.LineImageMapPreviewWrapper
      style={
        message.data.base_size.height !== 1040
          ? {
              height: `calc( ${
                (message.data.base_size.height / message.data.base_size.width) * IMAGE_WIDTH
              }px  )`,
            }
          : {}
      }
      ref={wrapperEl}
    >
      <S.LineImageMapImg src={src} data-test="imagemap-preview-local-upload-image" />
      {message.data.actions.map((action, index) => {
        let text = '';
        let actionType = '';
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        let clickCallback = () => {};
        if (isImageMapDataActionMessageRule(action)) {
          text = action.text;
          actionType = t('message.send');
          clickCallback = () => {
            if (text) onAddUserMessageText(text);
          };
        } else if (isImageMapDataActionUrlRule(action)) {
          text =
            (
              message.parameters.find(
                (d) => d.key === transformKey(action.linkUri),
              ) as LinkParameter
            )?.data.url ?? '';
          actionType = t('common.openUrl');
          clickCallback = () => {
            window.open(text);
          };
        } else if (isImageMapDataActionCustomUrlRule(action)) {
          text =
            // @ts-expect-error Property `linkUri` does not exist on `never`
            message.parameters.find((d) => d.mappingKey === transformKey(action.linkUri))?.key ??
            '';
          actionType = t('message.apiUrl');
        }
        const styledProps = {
          top: (action.area.y / 1040) * IMAGE_WIDTH,
          left: (action.area.x / 1040) * IMAGE_WIDTH,
          width: (action.area.width / 1040) * IMAGE_WIDTH,
          height: (action.area.height / 1040) * IMAGE_WIDTH,
        };
        return (
          <Tooltip
            key={'actions' + index}
            trigger="hover"
            title={<TooltipDescription>{actionType + ' : ' + text}</TooltipDescription>}
          >
            <S.ImageMapActionsBlock
              style={{
                ...styledProps,
              }}
              onClick={clickCallback}
            />
          </Tooltip>
        );
      })}
    </S.LineImageMapPreviewWrapper>
  );
};
