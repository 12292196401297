import AppstoreOutlined from '@ant-design/icons/AppstoreOutlined';
import cn from 'classnames';
import { useContext, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { IMAGE_MAP_UI_IMAGE_WIDTH } from 'components/LineMessageEditor/constants';

import type { ImageMapDataType } from 'components/LineMessageEditor/models/templateDataAndTypes/types';
import type { ChangeEvent } from 'react';

import { Body, Box, Loading, StyledIcon, Text } from 'components';
import { FileInput } from 'components/Input';
import { Flex } from 'components/layoutUtils';
import { Context } from 'components/LineMessageEditor/models';
import { useAuth } from 'context/authContext';
import { useIsMounted } from 'hooks/useIsMounted';
import * as Validator from 'lib/validator';
import { uploadImageHelper } from 'lib/validator/helper/validatorHelpers';
import { useMessage } from 'shared/hooks/ui/useMessage';
import { theme } from 'theme';
import { checkAPNG } from 'utils/detection/checkAPNG';
import { checkImageTransparency } from 'utils/detection/checkImageTransparency';
import { lowerFirst } from 'utils/string/changeCase';

import * as S from '../Styled';
import { ImageEditOverlay, RowToolBox } from '..';

import { useImageMapImageUpload } from './hooks/useImageMapImageUpload';
import { ImageMapActiveBlock } from './ImageMapActiveBlock';
import { ImageMapPopContent } from './ImageMapPopContent';

const MAX_SIZE = 1024 * 1024;
const IMAGE_WIDTH_LIMIT = '1040 px';
const APNG_FILE_LIMIT = MAX_SIZE * 0.3;

interface ImageMapEditorProps {
  message: ImageMapDataType;
  rowIndex: number;
}

export const ImageMapEditor = ({ rowIndex, message }: ImageMapEditorProps) => {
  const { t } = useTranslation();

  const { store, dispatch } = useContext(Context);
  const authContext = useAuth();
  const isMounted = useIsMounted();
  const { message: messageToast } = useMessage();

  const wrapperEl = useRef(null);
  const imageBoxEl = useRef(null);
  const uploadImageFile = useRef<File>();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const { update, invalid, validate, value } = Validator.useField({
    name: uploadImageHelper({
      rowIndex: rowIndex,
      editorType: 'ImageMapEditor',
      entityKey: '',
    }),
    rules: [Validator.Rules.required],
    checkOnChange: true,
    value: message.data.base_url === '' ? '' : 'imageUpload',
    enableReinitialize: true,
  });

  const onImageUploaded = (uploadUrl: string, height: number) => {
    dispatch('setImageMapHeight', { rowIndex, height });

    // 等高度動畫跑完跑完 在把圖塞進去
    setTimeout(() => {
      if (isMounted() === false) return;

      update('imageUpload');
      dispatch('setImageMapUrl', { rowIndex, url: uploadUrl });
      setIsOpen(true);
      setIsLoading(false);
    }, 300);
  };

  const uploadImage = useImageMapImageUpload(uploadImageFile, onImageUploaded);

  const onUploadImage = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files !== null && e.currentTarget.files.length === 1) {
      const file = e.currentTarget.files[0];

      try {
        const isApng = await checkAPNG(file);

        if (isApng && file.size > APNG_FILE_LIMIT) {
          messageToast.error(t('common.fileSizeExceedWithSize', { size: '300 kb' }));
          return;
        }

        const img = new Image();
        img.src = window.URL.createObjectURL(file);
        uploadImageFile.current = file;

        img.onload = async () => {
          if (file.size > MAX_SIZE) {
            messageToast.error(t('common.fileSizeExceedWithSize', { size: '1 mb' }));
            return;
          }

          if (img.naturalWidth !== 1040) {
            messageToast.error(t('validation.imageWidthWithValue', { value: IMAGE_WIDTH_LIMIT }));
            return;
          }

          dispatch('setImageMapImageAnimated', { rowIndex, isAnimated: isApng });

          dispatch('setImageMapModuleSwitch', {
            rowIndex,
            isFlex:
              img.naturalHeight / img.naturalWidth > 3 || checkImageTransparency(img)
                ? false
                : true,
          });

          setIsLoading(true);
          await uploadImage();
        };
      } catch (error) {
        messageToast.error(t('common.failToFetchModule', { module: t('glossary.image') }));
      }
    }
  };

  useEffect(() => {
    validate(value);
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <S.ImageWrapper
      ref={wrapperEl}
      onClick={() => {
        dispatch('setEditingRow', { rowIndex });
      }}
      style={
        message.data.base_size.height !== 1040
          ? {
              height: (message.data.base_size.height / 1040) * IMAGE_MAP_UI_IMAGE_WIDTH + 4,
            }
          : {}
      }
      className={cn({
        'is-error': Validator.isInvalid(invalid),
        'is-drag-mode': store.isDragMode,
      })}
    >
      {message.data.base_url !== '' ? (
        <>
          <S.ImageMapScaledImage src={message.data.base_url} ref={imageBoxEl} />{' '}
          {isOpen ? (
            <ImageMapActiveBlock
              area={message.data.actions.map((action) => action.area)}
              activeBlockIndex={message.activeBlockIndex}
            />
          ) : null}
        </>
      ) : null}
      <S.UploadImageLabel type="imageMap" bgUrl="">
        <FileInput
          accept="image/jpeg,image/png"
          onChange={onUploadImage}
          data-test="imagemap-editor-popover-local-upload-image-input"
        />
        {isLoading ? <Loading /> : null}
      </S.UploadImageLabel>
      {message.data.base_url !== '' ? (
        <ImageEditOverlay
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          onUploadImage={onUploadImage}
          popOverProps={{
            title: <Body fontWeight={500}>{t('message.imageMap.settings')}</Body>,
            content: (
              <Box width="350px" maxHeight="540px" overflow="auto">
                <Validator.FieldScopeProvider>
                  <ImageMapPopContent
                    message={message}
                    rowIndex={rowIndex}
                    imageMapRef={imageBoxEl}
                    currentChannel={authContext.state.currentChannel}
                  />
                </Validator.FieldScopeProvider>
              </Box>
            ),
          }}
        />
      ) : null}
      {!isLoading && message.data.base_url === '' ? (
        <Flex alignItems="center" flexDirection="column">
          <StyledIcon
            mb={2}
            component={<AppstoreOutlined />}
            fontSize={21}
            color={theme.colors.neutral006}
          />
          <Text color={theme.colors.neutral008} mb="8px">
            <Trans values={{ object: lowerFirst(t('glossary.image')) }}>common.uploadObject</Trans>
          </Text>
          <Text color={theme.colors.neutral007} textAlign="center" lineHeight="1.4">
            {t('message.imageMap.imageLimitWithApng', {
              fileSize: '1 mb',
              width: IMAGE_WIDTH_LIMIT,
            })}
          </Text>
        </Flex>
      ) : null}
      <RowToolBox
        moduleType={store.editorData[rowIndex].module_id}
        onRemoveClick={() => {
          dispatch('deleteEditorData', { rowIndex });
        }}
      />
    </S.ImageWrapper>
  );
};
