import { useTranslation } from 'react-i18next';

import { InfoBox } from 'shared/components/InfoBox';

export const LineMessageEditorPreviewHint = () => {
  const { t } = useTranslation();
  return (
    <InfoBox status="plain" transparent={true}>
      {t('message.imageReferenceOnly')}
    </InfoBox>
  );
};
