import urlJoin from 'url-join';

import type {
  TagCreateArgs,
  TagCreateResponse,
  TagDeleteArgs,
  TagDeleteResponse,
  TagEditArgs,
  TagEditResponse,
  TagMetadataResponse,
} from 'shared/types/tag';

import { AuthAxios as axios } from 'lib/axios';

/**
 * Fetch all available tags in the database, scoped to the current organization
 */
export const getTagMetadataItem = (signal?: AbortSignal): Promise<TagMetadataResponse> =>
  axios.get('/tag/v1/tag/raw_tags/', { signal }).then((resp) => resp.data);

/**
 * Tag mutations
 */
export const createTag = (args: TagCreateArgs): Promise<TagCreateResponse> =>
  axios.post('/tag/v1/tag/', args).then((resp) => resp.data);

export const editTag = (args: TagEditArgs): Promise<TagEditResponse> =>
  axios.patch(urlJoin('/tag/v1/tag/', String(args.id), '/'), args).then((resp) => resp.data);

export const deleteTag = (args: TagDeleteArgs): Promise<TagDeleteResponse> =>
  axios.delete(urlJoin('/tag/v1/tag/', String(args.id), '/'));
