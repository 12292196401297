import CheckCircleFilled from '@ant-design/icons/CheckCircleFilled';
import ExclamationCircleFilled from '@ant-design/icons/ExclamationCircleFilled';
import { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { Utm } from 'components/LineMessageEditor/models/templateDataAndTypes/types';
import type { EditorType } from 'lib/validator/helper/validatorHelpers';
import type { ChangeEvent, CSSProperties, FocusEvent } from 'react';

import { Box } from 'components/layoutUtils';
import { useGATrack } from 'components/LineMessageEditor/hooks/useGATrack';
import { useTaggable } from 'components/LineMessageEditor/hooks/useTaggable';
import { OpenExternalTooltip } from 'components/Tooltip/OpenExternalTooltip';
import { Text } from 'components/Typography';
import { useAuth } from 'context/authContext';
import { useHandler } from 'hooks/useEventCallback';
import { stopPropagationBundle } from 'lib/react-createPortal';
import * as Validator from 'lib/validator';
import {
  campaignUTMNameHelper,
  contentUTMNameHelper,
  mediumUTMNameHelper,
  sourceUTMNameHelper,
  urlNameHelper,
} from 'lib/validator/helper/validatorHelpers';
import { Checkbox } from 'shared/components/Checkbox';
import { TagPickerByName } from 'shared/components/TagPicker/TagPickerByName';
import { Tooltip, TooltipDescription } from 'shared/components/Tooltip';
import { theme } from 'theme';
import { pluckSpaces } from 'utils/string/whiteSpaces';

import * as S from '../Styled';

interface UrlUTMModuleProps {
  urlValue: string;
  openExternal: boolean;
  onOpenExternalChange: (open: boolean) => void;
  onFieldsChange: (uri: string, utmFields: Utm) => void;
  tagList: string[];
  onTagChange: (tags: string[]) => void;
  UTMSourceValue: string;
  onUTMSourceChange: (value: string) => void;
  UTMMediumValue: string;
  onUTMMediumChange: (value: string) => void;
  UTMCampaignValue: string;
  onUTMCampaignChange: (value: string) => void;
  UTMContentValue: string;
  onUTMContentChange: (value: string) => void;
  rowIndex: number;
  entityKey: string;
  wrapperStyle?: CSSProperties;
  editorIndex?: number;
  editorType: EditorType;
  'data-test'?: string;
}

interface TaggableHintProps {
  message: string;
}

interface GaTrackTextProps {
  isGATrackable: boolean;
}

export const TaggableHint = memo(({ message }: TaggableHintProps) => (
  <Box textAlign="left" mt="8px" lineHeight="1.2">
    <Text color={theme.colors.yellow007} mr="8px">
      <ExclamationCircleFilled />
    </Text>
    <Text color={theme.colors.yellow007}>{message}</Text>
  </Box>
));

export const GaTrackText = ({ isGATrackable }: GaTrackTextProps) => {
  const { t } = useTranslation();
  if (!isGATrackable) {
    return (
      <Box textAlign="left" mt="8px" lineHeight="1.2">
        <Text color={theme.colors.yellow007} mr="8px">
          <ExclamationCircleFilled />
        </Text>
        <Text color={theme.colors.yellow007}>{t('message.gaNotConnected')}</Text>
      </Box>
    );
  }

  return (
    <Box textAlign="left" mt="8px">
      <Text color={theme.colors.green006} mr="8px">
        <CheckCircleFilled />
      </Text>
      <Text color={theme.colors.green006}>{t('message.gaConnected')}</Text>
    </Box>
  );
};

export const UrlUTMModule = (props: UrlUTMModuleProps) => {
  const { t } = useTranslation();
  const authContext = useAuth();

  const { canUseUTMTracking, checkUrl, isGATrackable, setIsTracked, isTracked } = useGATrack(
    authContext,
    props.urlValue,
    {
      utm_source: props.UTMSourceValue,
      utm_medium: props.UTMMediumValue,
      utm_campaign: props.UTMCampaignValue,
      utm_content: props.UTMContentValue,
    },
  );

  const { taggable, message, tooltip } = useTaggable(props.urlValue);

  const checkUrlHandler = useHandler((url: string) => {
    checkUrl(
      url,
      (fields) => {
        props.onFieldsChange(url, fields);
      },
      () => {
        props.onFieldsChange(url, {
          utm_campaign: '',
          utm_content: '',
          utm_medium: '',
          utm_source: '',
        });
      },
    );
  });

  const handleUrlChange = useHandler((e: ChangeEvent<HTMLInputElement>) => {
    checkUrlHandler(e.target.value);
  });

  const handleUrlBlur = useHandler((e: FocusEvent<HTMLInputElement>) => {
    const formattedUrl = pluckSpaces(e.target.value);
    if (formattedUrl === e.target.value) return;
    checkUrlHandler(formattedUrl);
  });

  return (
    <div style={props.wrapperStyle} {...stopPropagationBundle}>
      <S.PopOverFieldsContent>
        <Validator.PopoverField
          name={urlNameHelper({
            rowIndex: props.rowIndex,
            entityKey: props.entityKey,
            editorIndex: props.editorIndex,
            editorType: props.editorType,
          })}
          rules={[Validator.Rules.required, Validator.Rules.url, Validator.Rules.maxLength(1000)]}
          placeholder={t('common.inputFieldName', { fieldName: t('glossary.url') })}
          value={props.urlValue}
          enableReinitialize={true}
          checkOnChange={true}
          onChange={handleUrlChange}
          onBlur={handleUrlBlur}
          data-test={props['data-test']}
        />
        {props.urlValue.length ? (
          !taggable ? (
            <TaggableHint message={t(message)} />
          ) : canUseUTMTracking ? (
            <GaTrackText isGATrackable={isGATrackable} />
          ) : null
        ) : null}
        <S.PopOverFieldsContent>
          <Checkbox
            checked={props.openExternal}
            onChange={(e) => props.onOpenExternalChange(e.target.checked)}
          >
            <Trans>common.openInExternal</Trans>
          </Checkbox>
          <OpenExternalTooltip />
        </S.PopOverFieldsContent>
      </S.PopOverFieldsContent>
      <Tooltip
        placement="topLeft"
        title={!taggable && tooltip ? <TooltipDescription>{t(tooltip)}</TooltipDescription> : ''}
      >
        <TagPickerByName
          tagList={props.tagList}
          onTagListChange={props.onTagChange}
          disabled={!taggable}
        />
      </Tooltip>
      {canUseUTMTracking ? (
        <>
          <S.PopOverFieldsContent>
            <Checkbox
              disabled={!isGATrackable}
              checked={isTracked}
              onChange={(e) => {
                setIsTracked(e.target.checked);
                if (!e.target.checked) {
                  props.onFieldsChange(props.urlValue, {
                    utm_campaign: '',
                    utm_content: '',
                    utm_medium: '',
                    utm_source: '',
                  });
                }
              }}
              data-test={`${props['data-test']}-isUtmTracked`}
            />{' '}
            <Trans>message.trackPageBehavior</Trans>
          </S.PopOverFieldsContent>
          <S.PopOverFieldTitle>
            <Trans>glossary.utmSource</Trans>
          </S.PopOverFieldTitle>
          <S.PopOverFieldsContent>
            <Validator.PopoverField
              name={
                isTracked
                  ? sourceUTMNameHelper({
                      rowIndex: props.rowIndex,
                      entityKey: props.entityKey,
                      editorIndex: props.editorIndex,
                      editorType: props.editorType,
                    })
                  : 'UTMSource'
              }
              rules={isTracked ? [Validator.Rules.required, Validator.Rules.maxLength(128)] : []}
              checkOnChange={true}
              placeholder={t('common.inputFieldName', { fieldName: t('glossary.utmSource') })}
              value={props.UTMSourceValue}
              onChange={(e) => props.onUTMSourceChange(e.target.value)}
              onBlur={(e) => props.onUTMSourceChange(pluckSpaces(e.target.value))}
              enableReinitialize={true}
              disabled={!isGATrackable || !isTracked}
              data-test={`${props['data-test']}-utm-source`}
            />
          </S.PopOverFieldsContent>

          <S.PopOverFieldTitle>
            <Trans>glossary.utmMedium</Trans>
          </S.PopOverFieldTitle>
          <S.PopOverFieldsContent>
            <Validator.PopoverField
              name={
                isTracked
                  ? mediumUTMNameHelper({
                      rowIndex: props.rowIndex,
                      entityKey: props.entityKey,
                      editorIndex: props.editorIndex,
                      editorType: props.editorType,
                    })
                  : 'UTMMedium'
              }
              placeholder={t('common.inputFieldName', { fieldName: t('glossary.utmMedium') })}
              checkOnChange={true}
              rules={isTracked ? [Validator.Rules.required, Validator.Rules.maxLength(128)] : []}
              value={props.UTMMediumValue}
              onChange={(e) => props.onUTMMediumChange(e.target.value)}
              onBlur={(e) => props.onUTMMediumChange(pluckSpaces(e.target.value))}
              enableReinitialize={true}
              disabled={!isGATrackable || !isTracked}
              data-test={`${props['data-test']}-utm-medium`}
            />
          </S.PopOverFieldsContent>
          <S.PopOverFieldTitle>
            <Trans>glossary.utmCampaign</Trans>
          </S.PopOverFieldTitle>
          <S.PopOverFieldsContent>
            <Validator.PopoverField
              name={
                isTracked
                  ? campaignUTMNameHelper({
                      rowIndex: props.rowIndex,
                      entityKey: props.entityKey,
                      editorIndex: props.editorIndex,
                      editorType: props.editorType,
                    })
                  : 'UTMCampaign'
              }
              placeholder={t('common.inputFieldName', { fieldName: t('glossary.utmCampaign') })}
              checkOnChange={true}
              rules={isTracked ? [Validator.Rules.required, Validator.Rules.maxLength(128)] : []}
              value={props.UTMCampaignValue}
              onChange={(e) => props.onUTMCampaignChange(e.target.value)}
              onBlur={(e) => props.onUTMCampaignChange(pluckSpaces(e.target.value))}
              enableReinitialize={true}
              disabled={!isGATrackable || !isTracked}
              data-test={`${props['data-test']}-utm-campaign`}
            />
          </S.PopOverFieldsContent>
          <S.PopOverFieldTitle>
            <Trans>glossary.utmContent</Trans>
            <Trans>common.optional</Trans>
          </S.PopOverFieldTitle>
          <S.PopOverFieldsContent>
            <S.PopOverFieldsContent>
              <Validator.PopoverField
                name={
                  isTracked
                    ? contentUTMNameHelper({
                        rowIndex: props.rowIndex,
                        entityKey: props.entityKey,
                        editorIndex: props.editorIndex,
                        editorType: props.editorType,
                      })
                    : 'UTMContent'
                }
                placeholder={t('common.inputFieldName', { fieldName: t('glossary.utmContent') })}
                checkOnChange={true}
                rules={isTracked ? [Validator.Rules.maxLength(128)] : []}
                value={props.UTMContentValue}
                onChange={(e) => props.onUTMContentChange(e.target.value)}
                onBlur={(e) => props.onUTMContentChange(pluckSpaces(e.target.value))}
                enableReinitialize={true}
                disabled={!isGATrackable || !isTracked}
                data-test={`${props['data-test']}-utm-content`}
              />
            </S.PopOverFieldsContent>
          </S.PopOverFieldsContent>
        </>
      ) : null}
    </div>
  );
};

UrlUTMModule.defaultProps = {
  entityKey: '',
  rowIndex: 0,
};
