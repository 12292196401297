/* eslint-disable tsdoc/syntax */
import { safeString } from '@chatbotgang/etude/string/safeString';
import { convertToRaw } from 'draft-js';
import omit from 'lodash/omit';

import { PARAM_RICH_TEXT_FIELD_NAME_INDEX } from 'components/LineMessageEditor/constants';

import type { SupportedToEditorStateReturnType, toEditorState } from './toEditorState';
import type { FlexText } from '@line/bot-sdk';
import type {
  CardDataType,
  CardEditorData,
} from 'components/LineMessageEditor/models/templateDataAndTypes/card';
import type { TextFormatData } from 'components/LineMessageEditor/models/templateDataAndTypes/richtext';
import type {
  CarrouselData,
  EditorParameter,
  EditorStateObject,
} from 'components/LineMessageEditor/models/templateDataAndTypes/types';
import type { ContentState, Editor } from 'draft-js';

import {
  CardDataBodyType,
  createParamRichTextFormat,
} from 'components/LineMessageEditor/models/templateDataAndTypes/card';
import { isRichTextCustomParameter } from 'components/LineMessageEditor/models/templateDataAndTypes/types';
import { stringifyEditorStateObjectToUnixText } from 'components/LineMessageEditor/utils/editorState';
import { logError } from 'lib/logger';

import { composeKey, isMappingKey } from './transformKey';

const { CardTitle: cardTitleIndex, CardDescription: cardDescriptionIndex } =
  PARAM_RICH_TEXT_FIELD_NAME_INDEX;

// TODO: Add unit test for `migrateTestFormatForCardData`
/**
 * 1. Insert {@link TextFormatData cardData.format = [TextFormatData, TextFormatData]}
 * if {@link CardDataType cardData} wasn't updated to the latest implementations.
 *
 * 2. Use the same {@link EditorStateObject.id richEditorId} generated from
 * {@link toEditorState} for the {@link CardDataType cardData} for the sake of
 * single source of truth.
 *
 * @param editorStateObjects
 * @param cardData
 */
function migrateTextFormatForCardData(
  editorStateObjects: SupportedToEditorStateReturnType<CardDataType>,
  cardData: CardDataType,
): void {
  if (cardData.format) {
    return;
  }
  cardData.format = [
    createParamRichTextFormat({ richEditorId: editorStateObjects[cardTitleIndex].id }),
    createParamRichTextFormat({ richEditorId: editorStateObjects[cardDescriptionIndex].id }),
  ];
}

// TODO: Add unit test for `matchRichEditorIdByEditorStateObjects`
/**
 * Use the same {@link EditorStateObject.id richEditorId} generated from
 * {@link toEditorState} for the {@link CardDataType cardData} for the sake of
 * single source of truth.
 *
 * @param editorStateObjects
 * @param cardData
 */
function matchRichEditorIdByEditorStateObjects(
  editorStateObjects: SupportedToEditorStateReturnType<CardDataType>,
  cardData: CardDataType,
): void {
  if (!cardData.format) {
    return;
  }
  cardData.format[cardTitleIndex].richEditorId = editorStateObjects[cardTitleIndex].id;
  cardData.format[cardDescriptionIndex].richEditorId = editorStateObjects[cardDescriptionIndex].id;
}

/**
 * The endpoint to determine which strategy to mutate {@link CardDataType cardData}
 * by either:
 *
 * (1) {@link migrateTextFormatForCardData migrating cardData.format}, or
 *
 * (2) {@link matchRichEditorIdByEditorStateObjects matching richEditorId}
 *
 * to ensure {@link TextFormatData.richEditorId richEditorId} is consistent with
 * {@link EditorStateObject.id}.
 *
 * @param editorStateObjects
 * @param cardData
 */
export function postProcessCardDataForState(
  editorStateObjects: SupportedToEditorStateReturnType<CardDataType>,
  cardData: CardDataType,
): void {
  if (!cardData.format) {
    migrateTextFormatForCardData(editorStateObjects, cardData);
  } else {
    matchRichEditorIdByEditorStateObjects(editorStateObjects, cardData);
  }
}

// TODO: Add unit test to `transposeKeysFromRawText`
/**
 * "Turn '<$var:mappingKey>' to '<$var:key>', so backends can replace the key then."
 *
 * @see {@link https://tinyurl.com/57k9f6mx} before the refactor of this util function.
 *
 * @param text
 * @param parameters
 */
export function transposeKeysFromRawText(
  text: string,
  parameters: CardEditorData['parameters'],
): string {
  let result = text;
  parameters.forEach((p) => {
    if (!isRichTextCustomParameter(p)) {
      return;
    }
    const regex = new RegExp(p.mappingKey, 'g');
    result = result.replace(regex, p.key);
  });
  return result;
}

// TODO: Add unit test to `transposeMappingKeysForEditorState`
/**
 * Turn '<$var:key>' to '<$var:mappingKey>', so UI components can display the tags.
 *
 * @param text
 * @param parameters
 */
export function transposeMappingKeysForEditorState(
  text: string,
  parameters: EditorParameter[],
): string {
  return text
    .split('<$var:')
    .map((t) => {
      const [key] = t.split('>');
      if (key === 'name') {
        return '<$var:' + t;
      }
      const param = parameters.find((p) => isRichTextCustomParameter(p) && p.key === key);
      if (param === undefined || !isRichTextCustomParameter(param)) {
        return t;
      }
      return t.replace(key, '<$var:' + param.mappingKey);
    })
    .join('');
}

// TODO: Add unit test to `revertCardBodyContents`
/**
 * Revert backend saved card data to frontend compatible formatted the tuple of {@link FlexText}
 * as body contents.
 *
 * @param flexTexts
 * @param parameters
 */
export function revertCardBodyContents(
  flexTexts: [FlexText, FlexText],
  parameters: EditorParameter[],
): [FlexText, FlexText] {
  const [revertedCardTitleText, revertedCardDescriptionText] = flexTexts.map((flexText) =>
    transposeMappingKeysForEditorState(flexText.text, parameters),
  );
  flexTexts[cardTitleIndex].text = revertedCardTitleText;
  flexTexts[cardDescriptionIndex].text = revertedCardDescriptionText;
  return flexTexts;
}

interface GetRawDataByIdResponse {
  rawData: string;
  text: string;
}

// TODO: Add unit test to `getRawDataById`
/**
 * Get converted {@link ContentState draft-js ContentData} as `rawData` and
 * transposed keys of the unix text as `text`; store them in backend.
 * Therefore, we can convert again for frontend state to display UI components.
 *
 * @see {@link https://tinyurl.com/5n8bx76v} and {@link transposeKeysFromRawText} before the refactor of this util function.
 *
 * @param id
 * @param editorStateArray
 * @param parameters
 */
function getRawDataById(
  id: TextFormatData['richEditorId'],
  editorStateArray: EditorStateObject[],
  parameters: CardEditorData['parameters'],
): GetRawDataByIdResponse {
  const result: GetRawDataByIdResponse = { rawData: '', text: '' };
  const editorState = editorStateArray[editorStateArray.findIndex((d) => d.id === id)].editorState;

  try {
    result.rawData = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
  } catch (e) {
    logError(e);
  }
  try {
    result.text = transposeKeysFromRawText(
      stringifyEditorStateObjectToUnixText(editorState),
      parameters,
    );
  } catch (e) {
    logError(e);
  }

  return {
    ...result,
    text: safeString(result.text),
  };
}

// TODO: Add unit test to `preProcessCardDataForFinalSaveData`
/**
 * Process {@link CardDataType cardData} for transitioning periods of having the
 * feature of `texts-as-params`. The data type may be created when:
 *
 * (1) Dev Mode Feature Flag Off -> Shouldn't have {@link TextFormatData format}
 *     field -> Omit it
 *
 * (2) Dev Mode Feature Flag On  -> Should have {@link TextFormatData format}
 *     field -> Keep it
 *
 * DISCLAIMER: This function will cast the result as {@link CardEditorData.data}
 *
 * @param cardData
 * @param editorStateArray
 * @param parameters
 */
export function preProcessCardDataForFinalSaveData(
  cardData: CardDataType,
  parameters: CardEditorData['parameters'],
  editorStateArray: EditorStateObject[],
): CardEditorData['data'] {
  if (!cardData.format) {
    return omit(cardData, ['format']) as CardEditorData['data'];
  }
  const [titleRes, descriptionRes] = cardData.format.map((f) =>
    getRawDataById(f.richEditorId, editorStateArray, parameters),
  );
  cardData.format[cardTitleIndex].rowData = titleRes.rawData;
  cardData.format[cardDescriptionIndex].rowData = descriptionRes.rawData;
  cardData.contents.body.contents[cardTitleIndex].text = titleRes.text;
  cardData.contents.body.contents[cardDescriptionIndex].text = descriptionRes.text;

  return cardData as CardEditorData['data'];
}

// TODO: Add unit test to `isParamKeyEmpty`
function isParamKeyEmpty(parameter: EditorParameter): boolean {
  /* TODO: Improve checking logic if end user:
        (1) entered the param name but then deleted the custom param
        (2) same "parameter.key" after creations and deletions
    */
  return parameter.key === '';
}

type SupportedSanitizeRichTextParameterTypes =
  | CardEditorData['parameters']
  | CarrouselData['parameters'];
type SupportedSanitizeRichTextParameterReturnTypes<T> = T extends CardEditorData['parameters']
  ? CardEditorData['parameters']
  : T extends CarrouselData['parameters']
    ? CarrouselData['parameters']
    : EditorParameter[];

// TODO: Add unit test to `sanitizeRichTextParameters`
/**
 * Clean up unnecessary {@link CardEditorData.parameters} if
 * {@link CardDataBodyType cardData.contents.body.contents} doesn't contain the
 * specified keys after {@link Editor} deletions and updates.
 *
 * @remark Use this function after {@link preProcessCardDataForFinalSaveData} so that it can parse and filter out the keys not contained after {@link transposeKeysFromRawText}
 *
 * @param flexTexts
 * @param parameters
 */
function sanitizeRichTextParameters<T extends SupportedSanitizeRichTextParameterTypes>(
  flexTexts: FlexText[],
  parameters: T,
): SupportedSanitizeRichTextParameterReturnTypes<T>;
function sanitizeRichTextParameters(
  flexTexts: FlexText[],
  parameters: SupportedSanitizeRichTextParameterTypes,
): CardEditorData['parameters'] | CarrouselData['parameters'] | EditorParameter[] {
  const concatTexts = flexTexts.map((f) => f.text).join(' ');
  return parameters.filter((p) => {
    if (!isMappingKey('customText', p.mappingKey)) {
      return true;
    }
    if (isParamKeyEmpty(p)) {
      return false;
    }
    return concatTexts.indexOf(composeKey(p.key)) !== -1;
  });
}

type SupportedSanitizedHeroImageTypes =
  | CardEditorData['data']
  | CarrouselData['data']['contents'][number];
type SupportedSanitizedHeroImageReturnTypes<T> = T extends SupportedSanitizedHeroImageTypes
  ? T
  : CardDataType;

function sanitizeHeroImage<T extends SupportedSanitizedHeroImageTypes>(
  data: T,
): SupportedSanitizedHeroImageReturnTypes<T>;
function sanitizeHeroImage(
  cardData: SupportedSanitizedHeroImageTypes,
): CardEditorData['data'] | CarrouselData['data']['contents'][number] | CardDataType {
  if (cardData.contents.hero?.url === '') {
    return omit(cardData, 'contents.hero');
  }
  return cardData;
}

export { sanitizeRichTextParameters, sanitizeHeroImage };
