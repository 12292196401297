/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import urlJoin from 'url-join';

import type { EditorDataType } from 'components/LineMessageEditor/models/templateDataAndTypes/types';
import type { ITracelink, PageResponse, UniversalTemplateCategory } from 'components/Types';
import type { SegmentV3 } from 'components/Types/Segment';
import type { BotInfo } from 'layout/types';
import type { User } from 'layout/types/layout';
import type { AvailableLangs } from 'modules/G11n/i18n';
import type { IBroadcast } from 'shared/types/broadcast';

import { AuthAxios as axios } from 'lib/axios';
import { useUniversalTemplateQuery } from 'shared/hooks/rubato';

type BotLiffChannel = {
  liff_channel_id: string;
};

export const getBotInfo = (botId: number): Promise<BotInfo> =>
  axios.get<BotInfo>(`/line/v1/bot/${botId}/`).then((resp) => resp.data);

export const getBotLiffChannelId = (botId: number) =>
  axios
    .get<BotLiffChannel>(urlJoin('/line/v2/bot/', String(botId), 'liff_channel_id/'))
    .then((resp) => resp.data);

/**
 * Note: this service also accepts `is_predefined` as a boolean param, but it seems unused
 * @param botId - bot ID
 * @returns SegmentV3[]
 */
export const getSegmentList = (botId: number) =>
  axios
    .get<SegmentV3[]>('/audience/v2/segment/', {
      params: {
        bot: botId,
      },
    })
    .then((resp) => resp.data);

/**
 * Note: this service has been replaced by `shared/services/broadcast/metadata`
 * @param bot - bot ID
 * @param status - already preconfigured to be "sent" in this implementation
 * @param is_open_count_trackable - boolean value
 * @returns Broadcast metadata
 */
export const getRawBroadcasts = (
  bot: string | number,
  status = 'sent',
  is_open_count_trackable?: boolean,
) =>
  axios
    .get<IBroadcast[]>('/line/v1/message/broadcast/raw_broadcasts/', {
      params: {
        bot,
        status,
        is_open_count_trackable,
      },
    })
    .then((resp) => resp.data);

export const getRawTracelinks = (bot: string | number) =>
  axios
    .get<ITracelink[]>('/line/v1/guidelink/outlink/raw_outlinks/', {
      params: {
        bot,
      },
    })
    .then((resp) => resp.data);

/**
 * @deprecated use `UniversalTemplateCategory` in `src/shared/types/rubato.ts` instead
 */
export type EditorDataTypeTemplateCategories = UniversalTemplateCategory;

type GetUniversalTemplate = {
  (category: EditorDataTypeTemplateCategories, language: AvailableLangs): Promise<EditorDataType[]>;
};

/**
 * Get message template by bot language
 * @param category - template category
 * @param language - channel language
 * @returns Promise data
 * @deprecated should not use this directly, use {@link useUniversalTemplateQuery} instead
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getUniversalTemplate: GetUniversalTemplate = (category: any, language: any) =>
  axios.get(`/system/v1/universal_template/${category}/${language}/`).then((resp) => resp.data);

export const getCaacActivationStatus = (
  channelId: string,
): Promise<{
  channel_id: number;
  is_caac_activate: boolean;
  born_before_caac: boolean;
}> => axios.get(`/caac/v1/channels/${channelId}/`).then((resp) => resp.data);

export const getUsers = (): Promise<PageResponse<Omit<User, 'zendesk_token'>>> =>
  axios.get('/accounts/v1/user/').then((resp) => resp.data);

/**
 *
 * @param data - CAAC owner user ID in "caac_owner_id" property
 * @returns
 * Possible error detail: "User does not exist: org_id=1 id=999999999999999", "Bot number already activated CAAC module"
 */
export const activateCaac = (data: { caac_owner_id: number }): Promise<null> =>
  axios.post('/caac/v1/channels/activate/', data).then((resp) => resp.data);
