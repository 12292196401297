import CameraFilled from '@ant-design/icons/CameraFilled';
import EnvironmentFilled from '@ant-design/icons/EnvironmentFilled';
import PictureFilled from '@ant-design/icons/PictureFilled';
import Space from 'antd/es/space';
import { useTranslation } from 'react-i18next';

import type {
  LineMessageEditorInput,
  QuickReply,
} from 'shared/components/Editor/LineMessageEditor/types';

import {
  QuickReplyPill,
  QuickReplyPillImage,
} from 'shared/components/Editor/LineMessageEditor/components/QuickReplyEditor/components/Styled';
import { Tooltip, TooltipDescription } from 'shared/components/Tooltip';
import { styled } from 'shared/utils/styled';

const LineMessageEditorQuickReplyPreviewWrapper = styled.div`
  position: relative;
  display: flex;
  padding: 12px 0;
  overflow-x: scroll;
`;

export const LineMessageEditorQuickReplyPreview = ({
  quickReply,
}: {
  quickReply: LineMessageEditorInput['quick_reply'];
}) => {
  const { t } = useTranslation();

  const getTitle = (item: QuickReply['items'][0]) => {
    switch (item.action.type) {
      case 'camera':
        return t('message.openCamera');
      case 'cameraRoll':
        return t('common.uploadObject', { object: t('glossary.image') });
      case 'location':
        return t('common.shareLocation');
      case 'message':
        return t('common.textsWithColon', {
          textBeforeColon: t('message.send'),
          textAfterColon: item.action.text,
        });
      default:
        break;
    }
  };

  return (quickReply?.items ?? []).length > 0 ? (
    <LineMessageEditorQuickReplyPreviewWrapper>
      <Space>
        {quickReply?.items.map((item, index) => (
          <Tooltip key={index} title={<TooltipDescription>{getTitle(item)}</TooltipDescription>}>
            <QuickReplyPill isPreview={true}>
              <Space size={4}>
                {item.action.type === 'camera' ? <CameraFilled /> : null}
                {item.action.type === 'cameraRoll' ? <PictureFilled /> : null}
                {item.action.type === 'location' ? <EnvironmentFilled /> : null}
                {item.action.type === 'message' && item.imageUrl ? (
                  <QuickReplyPillImage src={item.imageUrl} />
                ) : null}
                {item.action.label}
              </Space>
            </QuickReplyPill>
          </Tooltip>
        ))}
      </Space>
    </LineMessageEditorQuickReplyPreviewWrapper>
  ) : null;
};
