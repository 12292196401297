import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined';
import PlayCircleOutlined from '@ant-design/icons/PlayCircleOutlined';
import cn from 'classnames';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { VideoEditorData } from 'components/LineMessageEditor/models/templateDataAndTypes/video';
import type { ComponentProps } from 'react';

import { Loading, StyledIcon, Text } from 'components';
import { FileInput } from 'components/Input';
import { Box, Flex } from 'components/layoutUtils';
import { Context } from 'components/LineMessageEditor/models';
import { DEFAULT_AREA_SIZE } from 'components/LineMessageEditor/models/templateDataAndTypes/video';
import {
  CAN_NOT_GET_BLOB_ERROR,
  getImageFromVideoFrame,
  getVideoMetadata,
  VIDEO_LOAD_ERROR,
} from 'components/LineMessageEditor/utils/video';
import { useUpload } from 'lib/fileUpload';
import * as Validator from 'lib/validator';
import { uploadVideoHelper } from 'lib/validator/helper/validatorHelpers';
import { useMessage } from 'shared/hooks/ui/useMessage';
import { theme } from 'theme';

import { RowToolBox } from '../RowToolBox';
import * as S from '../Styled';

import { VideoEditOverlay } from './VideoEditOverlay';
import { VideoPopContent } from './VideoPopContent';

const VIDEO_MINUTE_LIMIT = 3;
const VIDEO_SIZE_LIMIT = 30;
const MB = 1024 * 1024;
const SECONDS_PER_MINUTE = 60;
const VIDEO_DIMENSION_LIMIT = 1920;

const MAX_SIZE = VIDEO_SIZE_LIMIT * MB;
const MAX_DURATION = VIDEO_MINUTE_LIMIT * SECONDS_PER_MINUTE;

interface VideoEditorProps {
  message: VideoEditorData;
  rowIndex: number;
}

export const VideoEditor = ({ message, rowIndex }: VideoEditorProps) => {
  const { t } = useTranslation();

  const {
    data: { video },
    module_id,
  } = message;
  const { store, dispatch } = useContext(Context);
  const onUpload = useUpload();
  const { message: messageToast } = useMessage();

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { update, invalid, validate, value } = Validator.useField({
    name: uploadVideoHelper({
      rowIndex: rowIndex,
      editorType: 'VideoEditor',
      entityKey: '',
    }),
    rules: [Validator.Rules.required],
    checkOnChange: true,
    value: video.originalContentUrl === '' ? '' : 'videoUpload',
    enableReinitialize: true,
  });

  const handleVideoUpload = useCallback<NonNullable<ComponentProps<typeof FileInput>['onChange']>>(
    async (e, parsedMediainfo) => {
      if (e.currentTarget.files !== null && e.currentTarget.files.length === 1) {
        const file = e.currentTarget.files[0];

        if (file.size > MAX_SIZE) {
          messageToast.error(
            t('common.fileSizeExceedWithSize', { fileSize: `${VIDEO_SIZE_LIMIT} mb` }),
          );
          return;
        }

        const { width, height, aspectRatio, duration, encodeLibSupported } =
          getVideoMetadata(parsedMediainfo);

        if (width > VIDEO_DIMENSION_LIMIT || height > VIDEO_DIMENSION_LIMIT) {
          return messageToast.error(t('validation.exceedResolutionLimit'));
        }

        if (!encodeLibSupported) {
          return messageToast.error(t('validation.invalidCodec'));
        }

        if (Math.round(duration) > MAX_DURATION) {
          return messageToast.error(
            t('validation.video.duration', {
              duration: t('common.withSeconds', { count: MAX_DURATION }),
            }),
          );
        }

        setIsLoading(true);
        dispatch('setVideoHeight', { rowIndex, height: (1 / aspectRatio) * DEFAULT_AREA_SIZE });
        const objectURL = window.URL.createObjectURL(file);

        try {
          const { blob, sec } = await getImageFromVideoFrame(objectURL, 0);
          const snapshot = new File([blob], `video-frame-${sec}.jpeg`);
          const uploadImageUrl = await onUpload(snapshot, 'Broadcast');

          dispatch('setVideoCoverUrl', { rowIndex, coverUrl: uploadImageUrl });
        } catch (error) {
          if (error instanceof Error) {
            if (error.message === CAN_NOT_GET_BLOB_ERROR) {
              return messageToast.error(t('validation.invalidCodec'));
            }
            if (error.message === VIDEO_LOAD_ERROR) {
              return messageToast.error(t('common.failToFetch'));
            }
          }
          return messageToast.error(t('common.unexpectedError'));
        }

        const uploadVideoUrl = await onUpload(file, 'Broadcast');

        dispatch('setVideoUrl', { rowIndex, url: uploadVideoUrl });
        dispatch('setVideoExpired', { rowIndex, expired: false });
        update('videoUpload');
        setIsLoading(false);
        setIsOpen(true);
        window.URL.revokeObjectURL(objectURL);
      }
    },
    [onUpload, dispatch, rowIndex, update, messageToast, t],
  );

  useEffect(() => {
    validate(value);
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box mb="32px">
      <S.ImageWrapper
        onClick={() => {
          dispatch('setEditingRow', { rowIndex });
        }}
        mb={0}
        style={
          message.data.base_size.height !== DEFAULT_AREA_SIZE
            ? {
                height: (message.data.base_size.height / DEFAULT_AREA_SIZE) * 349 + 4,
              }
            : {}
        }
        className={cn({
          'is-error': Validator.isInvalid(invalid),
          'is-drag-mode': store.isDragMode,
        })}
      >
        {!video.expired && video.originalContentUrl !== '' ? (
          <>
            <video
              key={video.originalContentUrl}
              style={{ width: '100%', borderRadius: '10px' }}
              onError={() => {
                dispatch('setVideoExpired', { rowIndex, expired: true });
                update('');
              }}
            >
              <source src={video.originalContentUrl} type="video/mp4" />
            </video>
            <StyledIcon
              position="absolute"
              width={42}
              height={42}
              fontSize={42}
              color={theme.colors.white001}
              type="play-circle"
            />
          </>
        ) : null}
        <S.UploadImageLabel type="image" bgUrl="">
          <FileInput
            accept="video/mp4"
            onChange={handleVideoUpload}
            data-test="video-editor-popover-local-upload-video-input"
          />
          {isLoading ? <Loading /> : null}
        </S.UploadImageLabel>
        {!video.expired && video.originalContentUrl !== '' ? (
          <VideoEditOverlay
            setOpen={() => setIsOpen(true)}
            onUploadVideo={handleVideoUpload}
            popupProps={{
              popupVisible: isOpen,
              popup: <VideoPopContent message={message} rowIndex={rowIndex} />,
              placement: 'right',
              onPopupVisibleChange: (v) => setIsOpen(v),
            }}
          />
        ) : null}
        {!isLoading && video.originalContentUrl === '' ? (
          <Flex alignItems="center" flexDirection="column" textAlign="center">
            <StyledIcon
              mb="10px"
              fontSize={19}
              width={21}
              height={21}
              color={theme.colors.neutral008}
              component={<PlayCircleOutlined />}
            />
            <Text color={theme.colors.neutral008} mb="12px">
              <Trans>message.video.upload</Trans>
            </Text>
            <Text color={theme.colors.neutral007} mb="4px">
              {t('message.video.videoLimit1', {
                length: t('common.withMinutes', { count: VIDEO_MINUTE_LIMIT }),
                fileSize: `${VIDEO_SIZE_LIMIT} mb`,
              })}
            </Text>
            <Text color={theme.colors.neutral007} mb="4px">
              {t('message.video.videoLimit2', {
                limit: `${VIDEO_DIMENSION_LIMIT}x${VIDEO_DIMENSION_LIMIT}px`,
              })}
            </Text>
          </Flex>
        ) : null}
        {video.expired && video.originalContentUrl !== '' ? (
          <Flex alignItems="center" flexDirection="column" textAlign="center">
            <StyledIcon
              mb="10px"
              fontSize={19}
              width={21}
              height={21}
              color={theme.colors.neutral008}
              component={<PlayCircleOutlined />}
            />
            <Text color={theme.colors.neutral008} mb="4px">
              <Trans>message.video.expired</Trans>
            </Text>
            <Text color={theme.colors.neutral008} mb="12px">
              <Trans>message.video.reUpload</Trans>
            </Text>
            <Text color={theme.colors.neutral007} mb="4px">
              {t('message.video.videoLimit1', {
                length: t('common.withMinutes', { count: VIDEO_MINUTE_LIMIT }),
                fileSize: `${VIDEO_SIZE_LIMIT} mb`,
              })}
            </Text>
            <Text color={theme.colors.neutral007} mb="4px">
              {t('message.video.videoLimit2', {
                limit: `${VIDEO_DIMENSION_LIMIT}x${VIDEO_DIMENSION_LIMIT}px`,
              })}
            </Text>
          </Flex>
        ) : null}
        <RowToolBox
          moduleType={module_id}
          onRemoveClick={() => {
            dispatch('deleteEditorData', { rowIndex });
          }}
        />
      </S.ImageWrapper>
      <Text mt="5px" display="block" color={theme.colors.neutral007} textAlign="center">
        <StyledIcon component={<ExclamationCircleOutlined />} fontSize="14px" />
        <Trans values={{ time: t('common.withMonths', { count: 1 }) }}>
          message.video.preservedTime
        </Trans>
      </Text>
    </Box>
  );
};
