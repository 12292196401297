import Col from 'antd/es/col';
import Form from 'antd/es/form';
import Select from 'antd/es/select';
import range from 'lodash/range';
import { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { isEmpty } from 'remeda';

import type { Feed } from '../types';
import type { ModalProps } from 'shared/components/Modal/types';

import { Box } from 'components';
import { Modal } from 'shared/components/Modal';
import { useMessage } from 'shared/hooks/ui/useMessage';

import { getDuplicates } from '../utils';

import { Item, Row } from './styled';

const { useForm } = Form;
const { Option } = Select;

/**
 * [fieldName]: index
 */
const initialValues = {
  row_0: 0,
  row_1: 1,
  row_2: 2,
  row_3: 3,
  row_4: 4,
  row_5: 5,
  row_6: 6,
};

const toOrderIndex = (index: number) => index + 1;

export const FeedSelectPopover = memo(function FeedSelectPopover({
  feeds,
  onOk,
  open,
  onCancel,
}: {
  feeds: Feed[];
  onOk?: (feeds: Feed[]) => void;
} & Pick<ModalProps, 'open' | 'onCancel'>) {
  const { t } = useTranslation();
  const [form] = useForm();

  const { message } = useMessage();

  return (
    <Modal
      open={open}
      width="718px"
      title={
        <Box textAlign="center">
          <Trans>message.breakingNews.rss.title</Trans>
        </Box>
      }
      okText={<Trans>common.import</Trans>}
      cancelText={<Trans>common.cancel</Trans>}
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            const selections: number[] = Object.values(values);
            const duplicates = getDuplicates(selections);
            if (isEmpty(duplicates)) {
              const results = selections.map((i) => feeds[i]);
              onOk?.(results);
              form.resetFields();
            } else {
              Object.values(duplicates).forEach((indexes) => {
                message.error(
                  t('message.breakingNews.rss.duplicated', {
                    indexes: indexes.map(toOrderIndex).join(', '),
                  }),
                );
              });
            }
          })
          .catch((info) => {
            message.error('Validate Failed:', info);
          });
      }}
    >
      <Form form={form} initialValues={initialValues} style={{ overflow: 'hidden' }}>
        <Row>
          <Col span={3} style={{ textAlign: 'center', lineHeight: '40px' }}>
            <Trans>message.breakingNews.sorting</Trans>
          </Col>
          <Col span={21} style={{ lineHeight: '40px' }}>
            <Trans>message.breakingNews.content</Trans>
          </Col>
        </Row>
        {range(7).map((i) => (
          <Row key={i}>
            <Col span={3} style={{ textAlign: 'center', lineHeight: '40px' }}>
              {i + 1}
            </Col>
            <Col span={21}>
              <Item name={`row_${i}`} rules={[{ required: true }]}>
                <Select style={{ width: '100%' }}>
                  {feeds.map(({ title }, j) => (
                    <Option value={j}>{title}</Option>
                  ))}
                </Select>
              </Item>
            </Col>
          </Row>
        ))}
      </Form>
    </Modal>
  );
});
