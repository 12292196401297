import { generateIcon } from './util';

export const OutBoundIcon = generateIcon(function OutBoundSvg() {
  return (
    <svg viewBox="0 0 16 16" width="1em" height="1em" fill="currentColor">
      <path d="M13.52 14H2.48a.48.48 0 0 1-.48-.48V2.48A.48.48 0 0 1 2.48 2h5.4a.12.12 0 0 1 .12.12v.84a.12.12 0 0 1-.12.12h-4.8v9.84h9.84v-4.8a.12.12 0 0 1 .12-.12h.84a.12.12 0 0 1 .12.12v5.4a.48.48 0 0 1-.48.48Z" />
      <path d="m11.099 2.52.784.786-3.848 3.852a.12.12 0 0 0 0 .17l.637.637a.12.12 0 0 0 .17 0l3.85-3.85.787.787a.12.12 0 0 0 .205-.07l.315-2.697A.12.12 0 0 0 13.865 2l-2.695.315a.12.12 0 0 0-.071.205Z" />
    </svg>
  );
});
