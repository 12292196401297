/* eslint-disable no-restricted-imports */
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import isoWeek from 'dayjs/plugin/isoWeek';
import isToday from 'dayjs/plugin/isToday';
import localeData from 'dayjs/plugin/localeData';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';

import 'dayjs/locale/ja';
import 'dayjs/locale/th';
import 'dayjs/locale/zh-tw';

// Update all locales to use Monday as the start of the week
dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  weekStart: 1,
});
dayjs.updateLocale('th', {
  weekStart: 1,
});
dayjs.updateLocale('ja', {
  weekStart: 1,
});
dayjs.updateLocale('zh-tw', {
  weekStart: 1,
});

dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(isBetween);
dayjs.extend(isToday);
dayjs.extend(isoWeek);
dayjs.extend(localeData);
dayjs.extend(relativeTime);
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(duration);

export type { Dayjs } from 'dayjs';

export { dayjs };
