import { useContext, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { IMAGEMAP_CAROUSEL_UI_IMAGE_WIDTH } from 'components/LineMessageEditor/constants';

import type {
  ImageMapCarousel,
  ImageMapCarouselItem,
} from 'components/LineMessageEditor/models/templateDataAndTypes/imageMapCarousel';
import type { FlatChannel } from 'layout/types';
import type { MutableRefObject } from 'react';

import { CustomBlockEditor } from 'components/LineMessageEditor/components/ImageMapeditor/ImageMapCustomBlockEditor';
import { Context } from 'components/LineMessageEditor/models';
import {
  ImageMapCarouselActions,
  isImageMapCarouselCustomUriAction,
  isImageMapCarouselMessageAction,
  isImageMapCarouselUriAction,
} from 'components/LineMessageEditor/models/templateDataAndTypes/imageMapCarousel';
import {
  getActionBlockStylesFromOffsets,
  parseWidthAndHeight,
} from 'components/LineMessageEditor/utils/imageMapCarousel';
import { SubText } from 'components/Typography/Text';
import * as Validator from 'lib/validator';
import {
  messageNameHelper,
  notificationTextNameHelper,
  urlNameHelper,
} from 'lib/validator/helper/validatorHelpers';
import { Radio } from 'shared/components/Radio';
import { lowerFirst } from 'utils/string/changeCase';

import * as S from '../Styled';

import { ImageMapPopupSelection } from './ImageMapPopupSelection';
import { ImageMapTextOrUrl } from './ImageMapTextOrUrl';

interface ImageMapPopContentProps {
  notificationText: string;
  isCustomBlocks: boolean;
  message: ImageMapCarouselItem;
  rowIndex: number;
  carouselIndex: number;
  activeBlockIndex: number;
  parameters: ImageMapCarousel['parameters'];
  imageMapRef: MutableRefObject<null>;
  currentChannel: FlatChannel | null;
}

export const ImageMapPopContent = ({
  notificationText,
  isCustomBlocks,
  message,
  rowIndex,
  carouselIndex,
  activeBlockIndex,
  parameters,
  imageMapRef,
  currentChannel,
}: ImageMapPopContentProps) => {
  const { t } = useTranslation();
  const { dispatch } = useContext(Context);
  const validatorCtx = useContext(Validator.Context);
  const [isCustomEditorShow, setIsCustomEditorShow] = useState(false);

  const actionError = Validator.getInvalid(
    validatorCtx.invalid,
    message.body.contents.slice(1).map(({ action }, i) => {
      if (isImageMapCarouselMessageAction(action))
        return messageNameHelper({
          rowIndex: rowIndex,
          editorType: 'ImageMapCarouselEditor',
          entityKey: i.toString(),
        });
      else
        return urlNameHelper({
          rowIndex: rowIndex,
          editorType: 'ImageMapCarouselEditor',
          entityKey: i.toString(),
        });
    }),
  );

  const activeBlockAction = useMemo(
    // Might be undefined due to no action block at the point the user switch to custom block
    () => message.body.contents[activeBlockIndex]?.action,
    [activeBlockIndex, message.body.contents],
  );

  const actionBlocks = useMemo(() => {
    const [_, ...blocks] = message.body.contents;
    return blocks;
  }, [message.body.contents]);

  return (
    <>
      <S.PopOverFieldTitle>{t('message.notificationText')}</S.PopOverFieldTitle>
      <SubText>{t('message.notificationHint')}</SubText>
      <S.PopOverFieldsContent>
        <Validator.PopoverField
          name={notificationTextNameHelper({
            rowIndex: rowIndex,
            entityKey: '',
            editorType: 'ImageMapCarouselEditor',
            editorIndex: carouselIndex,
          })}
          rules={[Validator.Rules.maxLength(300), Validator.Rules.required]}
          onChange={(e) => {
            dispatch('setImageMapCarouselNotificationText', {
              rowIndex,
              text: e.currentTarget.value,
            });
          }}
          placeholder={t('message.inputWithLengthLimit', {
            fieldName: lowerFirst(t('message.notificationText')),
            length: 300,
          })}
          value={notificationText}
          enableReinitialize={true}
          data-test="imagemap-carousel-settings-notification-input"
        ></Validator.PopoverField>
      </S.PopOverFieldsContent>
      <ImageMapPopupSelection
        // The first item is fixed imagemap image and the others are action blocks. We only take care about the others
        actionCount={message.body.contents.length - 1}
        activeBlockIndex={activeBlockIndex}
        isCustom={isCustomBlocks}
        setCustomEditorShow={setIsCustomEditorShow}
        onIsCustomChange={() =>
          dispatch('chooseImageMapCarouselCustomBlockMode', {
            rowIndex,
            carouselIndex,
            isCustom: true,
          })
        }
        setImageMapActiveBlock={(index) =>
          dispatch('setImageMapCarouselActiveBlock', {
            rowIndex,
            carouselIndex,
            activeIndex: index,
          })
        }
        setImageMapActions={(count) =>
          dispatch('setImageMapCarouselPredefinedBlocks', { rowIndex, carouselIndex, count })
        }
        rowIndex={rowIndex}
        actionError={actionError}
        carouselIndex={carouselIndex}
      />
      {/* The first item is fixed imagemap image and the others are action blocks. We only take care about the others */}
      {message.body.contents.length > 1 ? (
        <>
          <Radio
            checked={isImageMapCarouselMessageAction(activeBlockAction)}
            style={{ display: 'block' }}
            onClick={() => {
              if (!isImageMapCarouselMessageAction(activeBlockAction)) {
                dispatch('setImageMapCarouselActionType', {
                  rowIndex,
                  carouselIndex,
                  type: ImageMapCarouselActions.message,
                });
              }
            }}
            data-test={`imagemap-settings-action-block-${activeBlockIndex}-message-radio-${carouselIndex}`}
          >
            <Trans>message.send</Trans>
          </Radio>
          <Radio
            checked={isImageMapCarouselUriAction(activeBlockAction)}
            style={{ display: 'block' }}
            onClick={() => {
              if (!isImageMapCarouselUriAction(activeBlockAction)) {
                dispatch('setImageMapCarouselActionType', {
                  rowIndex,
                  carouselIndex,
                  type: ImageMapCarouselActions.uri,
                });
              }
            }}
            data-test={`imagemap-settings-action-block-${activeBlockIndex}-uri-radio-${carouselIndex}`}
          >
            <Trans>common.openUrl</Trans>
          </Radio>
          <Radio
            checked={isImageMapCarouselCustomUriAction(activeBlockAction)}
            style={{ display: 'block' }}
            onClick={() => {
              if (!isImageMapCarouselCustomUriAction(activeBlockAction)) {
                dispatch('setImageMapCarouselActionType', {
                  rowIndex,
                  carouselIndex,
                  type: ImageMapCarouselActions.customUri,
                });
              }
            }}
            data-test={`imagemap-settings-action-block-${activeBlockIndex}-customUri-radio-${carouselIndex}`}
          >
            <Trans>message.apiUrl</Trans>
          </Radio>
        </>
      ) : null}
      <ImageMapTextOrUrl
        rowIndex={rowIndex}
        carouselIndex={carouselIndex}
        actionBlocks={actionBlocks}
        activeBlockIndex={activeBlockIndex}
        parameters={parameters}
        currentChannel={currentChannel}
      />
      {isCustomEditorShow ? (
        <CustomBlockEditor
          blocks={parseBlockData(message)}
          imageRef={imageMapRef}
          maxBlockCount={20}
          onClose={(blocks) => {
            dispatch('setImageMapCarouselCustomBlocks', {
              rowIndex,
              carouselIndex,
              previewBlocks: blocks,
            });
            setIsCustomEditorShow(false);
          }}
        />
      ) : null}
    </>
  );
};

const parseBlockData = (message: ImageMapCarouselItem) => {
  const { aspectRatioValue } = parseWidthAndHeight(message.body.contents[0].aspectRatio ?? '1:1');
  const [_, ...imageMapBlocks] = message.body.contents;

  return imageMapBlocks.map(
    ({ width = '', height = '', offsetStart = '', offsetTop = '' }, index) => {
      const styles = getActionBlockStylesFromOffsets(
        {
          width: IMAGEMAP_CAROUSEL_UI_IMAGE_WIDTH,
          height: IMAGEMAP_CAROUSEL_UI_IMAGE_WIDTH / aspectRatioValue,
        },
        { width, height, offsetStart, offsetTop },
      );
      return {
        ...styles,
        id: index + 1,
      };
    },
  );
};
