import styled from '@emotion/styled';
import { colorTokens } from 'init/initMotifColors';

import { Typography } from 'shared/components/Typography/Typography';
import { theme } from 'theme';

/**
 * @deprecated Use `<Typography variant="body" />` instead.
 * @see {@link Typography}
 */
export const Text = styled.span`
  font-size: 14px;
  line-height: 1.4;
`;

/**
 * @deprecated Use `<Typography variant="body" fontWeight="bold" />` instead.
 * @see {@link Typography}
 */
export const BoldText = styled(Text)`
  font-weight: bold;
`;

export const SubText = styled(Typography)`
  color: ${theme.colors.neutral008};
`;

export const BodyText = styled.div`
  color: ${theme.colors.neutral010};
  font-size: 14px;
  line-height: 1.4;
`;

/**
 * Break long URLs with this component
 */
export const UrlText = styled(Typography)`
  word-break: break-word;
`;

/**
 * Limit the width of a `span` element containing text to a specified value
 * Note: this only applies to block-level elements e.g. display property `block` or `inline-block`
 * But this also works if it's used in a flexbox context
 */
export const TextWidthLimited = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const FormHelperText = styled(Typography)`
  color: ${colorTokens.createVarCall('--static-fg-negative')};
`;
