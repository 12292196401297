import { Children, useMemo } from 'react';

import type { SMSData } from '../models/templateDataAndTypes';
import type { ReactNode } from 'react';

import { createVariablePattern } from 'components/LineMessageEditor/utils/transformKey';

import * as S from './Styled';

interface SMSPreviewProps {
  message: SMSData;
}

const splitByVariables = (message: SMSData): ReactNode[] => {
  const {
    data: { text },
    parameters,
  } = message;

  const keysGroup = `(${parameters.map((p) => p.key).join('|')})`;
  const pattern = createVariablePattern(keysGroup);
  let execArray = null;
  let start = 0;
  const results: ReactNode[] = [];

  while ((execArray = pattern.exec(text)) !== null) {
    const {
      // <$var:key>
      0: variableReplace,
      // key
      1: variableKey,
      // start position of variableReplace
      index: end,
    } = execArray; // RegExpExecArray

    const textBeforePattern = text.substring(start, end);
    if (textBeforePattern !== '') {
      results.push(textBeforePattern);
    }

    results.push(<S.NameSpan>{variableKey}</S.NameSpan>);
    start = end + variableReplace.length;
  }
  if (start < text.length) {
    results.push(text.substring(start, text.length));
  }
  return results;
};

export const SMSPreview = ({ message }: SMSPreviewProps) => {
  const content = useMemo<ReactNode>(() => {
    const children = splitByVariables(message);
    return Children.toArray(children);
  }, [message]);

  return (
    <div>
      <S.SMSBubble>{content}</S.SMSBubble>
    </div>
  );
};
