import { useCallback } from 'react';

import type { UseQueryResult } from '@tanstack/react-query';
import type { TagMetadataItem, TagMetadataQueryData } from 'shared/types/tag';

import { useTagMetadataQuery } from 'shared/hooks/tag/useTagMetadataQuery';
import {
  getTagIdByName,
  getTagIdsByNames,
  getTagNameById,
  getTagNamesByIds,
  tagNameExists,
} from 'utils/tag';

export interface TagMetadataHook {
  isTagMetadataLoading: boolean;
  tagMetadataItems: TagMetadataItem[] | undefined;
  tagMetadataQuery: UseQueryResult<TagMetadataQueryData>;
  getTagIdByName: (tagName: string) => ReturnType<typeof getTagIdByName>;
  getTagIdsByNames: (tagNames: string[]) => ReturnType<typeof getTagIdsByNames>;
  getTagNameById: (tagId: number) => ReturnType<typeof getTagNameById>;
  getTagNamesByIds: (tagIds: number[]) => ReturnType<typeof getTagNamesByIds>;
  tagNameExists: (tagName: string) => ReturnType<typeof tagNameExists>;
}

/**
 * A simple wrapper around the tag metadata query with some common utility functions
 * This returns the "raw" metadata as well as some lookup functions using the index
 * Mostly created as a developer convenience; no need to invoke the main query every time
 */
export const useTagMetadata = ({ enabled }: { enabled: boolean }): TagMetadataHook => {
  const tagMetadataQuery = useTagMetadataQuery({ enabled });

  return {
    isTagMetadataLoading: tagMetadataQuery.isLoading,
    tagMetadataItems: tagMetadataQuery?.data?.items,
    tagMetadataQuery,
    getTagIdByName: useCallback(
      (tagName: string) => getTagIdByName(tagName, tagMetadataQuery.data?.items),
      [tagMetadataQuery.data],
    ),
    getTagIdsByNames: useCallback(
      (tagNames: string[]) => getTagIdsByNames(tagNames, tagMetadataQuery.data?.items),
      [tagMetadataQuery.data],
    ),
    getTagNameById: useCallback(
      (tagId: number) => getTagNameById(tagId, tagMetadataQuery.data?.records),
      [tagMetadataQuery.data],
    ),
    getTagNamesByIds: useCallback(
      (tagIds: number[]) => getTagNamesByIds(tagIds, tagMetadataQuery.data?.records),
      [tagMetadataQuery.data],
    ),
    tagNameExists: useCallback(
      (tagName: string) => tagNameExists(tagName, tagMetadataQuery.data?.items),
      [tagMetadataQuery.data],
    ),
  };
};
