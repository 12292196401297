/**
 * Consume the list and use the specified key in the list item as a unique object key.
 * Inspired by this https://redux.js.org/usage/structuring-reducers/normalizing-state-shape
 *
 * @param payloadArray a list of data
 * @param keyField specified key in the list item, should be unique
 * @returns a normalized object
 * @example
 * ```typescript
 * const normalizedData = normalizer(
 *  [
 *    { id: 1, name: 'one'},
 *    { id: 2, name: 'two'}
 *  ],
 *  'id'
 * )
 *  /* normalizedData would be
 *  {
 *     byId: {
 *       '1': { id: 1, name: 'one'},
 *       '2': { id: 2, name: 'two'}
 *     },
 *     allId: [1, 2]
 *   }
 * ```
 */
export function normalizer<T>(
  payloadArray: T[],
  keyField: keyof T,
): { byId: Record<string, T>; allId: T[keyof T][] } {
  const resultObject = {} as Record<string, T>;
  const allId = [] as T[keyof T][];

  payloadArray.forEach((d) => {
    resultObject[`${d[keyField]}`] = d;
    allId.push(d[keyField]);
  });

  return { byId: resultObject, allId };
}
