import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';
import { useTranslation } from 'react-i18next';

import { StyledIcon, Text } from 'components';
import { theme } from 'theme';

export const PreviewImageHint = () => {
  const { t } = useTranslation();

  return (
    <Text p="0 12px" color={theme.colors.neutral007} lineHeight="1.4">
      <StyledIcon mr="8px" component={<InfoCircleOutlined />} />
      {t('message.imageReferenceOnly')}
    </Text>
  );
};
