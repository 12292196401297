import type { BoxProps } from 'components/layoutUtils';
import type { ReactNode } from 'react';

import { Box } from 'components/layoutUtils';
import { styled } from 'shared/utils/styled';
import { theme } from 'theme';

interface FieldsProps extends BoxProps {
  title?: ReactNode;
  children?: ReactNode;
}

interface FieldsWrapperProps extends BoxProps {
  children?: ReactNode;
}

export const FieldTitleWrapper = styled.div`
  line-height: 20px;
  font-weight: 500;
  color: ${theme.colors.neutral010};
  text-align: left;
  margin-bottom: 8px;
`;

/**
 *
 * @example
 * ```jsx
 * <Fields
    title={
      <Text>Title</Text>
    }
  >
    <FieldsDescription color={theme.colors.yellow007}>
      Description
    </FieldsDescription>
  </Fields>
 * ```
 */
export const Fields = ({ children, title = '', ...styledBoxRest }: FieldsProps) => {
  return (
    <Box mb="24px" width="338px" {...styledBoxRest}>
      <FieldTitleWrapper>{title}</FieldTitleWrapper>
      <div>{children}</div>
    </Box>
  );
};

export const FieldsSecondaryTitle = ({ children = '', ...styledBoxRest }: FieldsWrapperProps) => {
  return (
    <Box mb="8px" lineHeight="20px" color={theme.colors.neutral010} {...styledBoxRest}>
      {children}
    </Box>
  );
};

export const FieldsDescription = ({ children = '', ...styledBoxRest }: FieldsWrapperProps) => {
  return (
    <Box mb="8px" lineHeight="20px" color={theme.colors.neutral008} {...styledBoxRest}>
      {children}
    </Box>
  );
};

export const MultipleFieldsWrapper = styled(Box)`
  display: flex;
  > * {
    flex: 1 0 0;
    &:not(:last-child) {
      margin-right: 15px;
    }
  }
`;

export const FieldsMultipleWrapper = ({ children = '', ...styledBoxRest }: FieldsWrapperProps) => {
  return (
    <MultipleFieldsWrapper
      mb="8px"
      lineHeight="20px"
      display="flex"
      color={theme.colors.neutral008}
      {...styledBoxRest}
    >
      {children}
    </MultipleFieldsWrapper>
  );
};
