import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import type { UseMutationResult } from '@tanstack/react-query';
import type {
  TagCreateArgs,
  TagCreateResponse,
  TagDeleteArgs,
  TagDeleteResponse,
  TagEditArgs,
  TagEditResponse,
} from 'shared/types/tag';

import { tagQueryKeys } from 'shared/queries/tag';
import { createTag, deleteTag, editTag } from 'shared/services/tag';
import { sanitizeTagName } from 'utils/tag';

export const useTagCreateMutation = (): UseMutationResult<
  TagCreateResponse,
  unknown,
  TagCreateArgs
> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (args) => createTag(args),
    onMutate: (data) => {
      return { ...data, name: sanitizeTagName(data.name) };
    },
    onSuccess: useCallback(
      () => queryClient.invalidateQueries({ queryKey: tagQueryKeys.all }),
      [queryClient],
    ),
  });
};

export const useTagEditMutation = (): UseMutationResult<TagEditResponse, unknown, TagEditArgs> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (args) => editTag(args),
    onMutate: (data) => {
      return { ...data, name: sanitizeTagName(data.name) };
    },
    onSuccess: useCallback(
      () => queryClient.invalidateQueries({ queryKey: tagQueryKeys.all }),
      [queryClient],
    ),
  });
};

export const useTagDeleteMutation = (): UseMutationResult<
  TagDeleteResponse,
  unknown,
  TagDeleteArgs
> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (args) => deleteTag(args),
    onSuccess: useCallback(
      () => queryClient.invalidateQueries({ queryKey: tagQueryKeys.all }),
      [queryClient],
    ),
  });
};
