import type { TagManagerFilterLegacyEnum } from 'modules/TagManager/types';

/**
 * Central repository of tag-related queries
 * Includes typing for all arguments passed to query functions
 */
export const tagQueryKeys = {
  all: [{ module: 'tag' }] as const,
  metadata: () => [{ ...tagQueryKeys.all[0], feature: 'metadata' }] as const,
  lists: () => [{ ...tagQueryKeys.all[0], category: 'lists' }] as const,
  list: (botId: number, filterType: TagManagerFilterLegacyEnum, searchRequestValue?: string) =>
    [
      { ...tagQueryKeys.lists()[0], feature: 'list', botId, filterType, searchRequestValue },
    ] as const,
  listMetrics: (botId: number) =>
    [{ ...tagQueryKeys.lists()[0], feature: 'listMetrics', botId }] as const,
};
