import UpOutlined from '@ant-design/icons/UpOutlined';
import { useRef, useState } from 'react';
import { Trans } from 'react-i18next';

import type {
  EditorDataType,
  EditorStateObject,
} from 'components/LineMessageEditor/models/templateDataAndTypes/types';

import { Box, StyledIcon } from 'components';
import {
  isCardDataRule,
  isCarrouselDataRule,
  isFlexDataRule,
  isImageCarouselDataRule,
  isImageDataRule,
  isImageMapCarouselDataRule,
  isImageMapDataRule,
  isNewsDataRule,
  isPrizeRedeemRule,
  isTextDataRule,
  isVideoDataRule,
} from 'components/LineMessageEditor/models/templateDataAndTypes/types';
import { stringifyEditorStateObject } from 'components/LineMessageEditor/utils/editorState';
import { styled } from 'shared/utils/styled';
import { theme } from 'theme';

import { parseRichText } from './RichTextPreviewUtils';
import * as S from './Styled';

interface NotificationPreviewProps {
  message: EditorDataType | undefined;
  editorStateArray: EditorStateObject[];
}

const NotificationWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 16px 16px 11px;
  background-color: ${theme.colors.white001};
  opacity: 0.9;
  border: solid 1px ${theme.colors.neutral003};
  border-radius: 4px;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
  overflow-wrap: break-word;
  min-height: 82px;
`;

const INITIAL_HEIGHT = 82;

export const NotificationPreview = ({ message, editorStateArray }: NotificationPreviewProps) => {
  const innerLayerRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(INITIAL_HEIGHT);
  const [open, setOpen] = useState(false);

  return message ? (
    <NotificationWrapper
      style={{ height: open ? height : INITIAL_HEIGHT }}
      onClick={() => {
        if (innerLayerRef.current) {
          if (open) {
            setHeight(INITIAL_HEIGHT);
            setOpen(!open);
          } else {
            if (innerLayerRef.current.offsetHeight + 30 > height) {
              setHeight(innerLayerRef.current.offsetHeight + 30);
              setOpen(!open);
            }
          }
        }
      }}
    >
      <StyledIcon
        style={{
          transform: open ? 'rotateZ(0deg)' : 'rotateZ(180deg)',
          transition: 'transform 0.25s ease-in-out',
        }}
        position="absolute"
        top="10px"
        right="10px"
        component={<UpOutlined />}
      />
      <Box ref={innerLayerRef}>
        <Box fontWeight={700} lineHeight="20px">
          <Trans>message.notificationText</Trans>
        </Box>
        <Box lineHeight="20px">{getNotificationText(message, editorStateArray)}</Box>
      </Box>
    </NotificationWrapper>
  ) : null;
};

function getNotificationText(message: EditorDataType, editorStateArray: EditorStateObject[]) {
  if (isTextDataRule(message)) {
    const editorStateObject = editorStateArray.find((d) => d.id === message.format.richEditorId);
    if (!editorStateObject) return '';
    return parseRichText(
      stringifyEditorStateObject(editorStateObject.editorState, ''),
      message.parameters,
    );
  } else if (
    isImageMapDataRule(message) ||
    isImageMapCarouselDataRule(message) ||
    isVideoDataRule(message) ||
    isCarrouselDataRule(message) ||
    isCardDataRule(message) ||
    isImageCarouselDataRule(message) ||
    isFlexDataRule(message) ||
    isNewsDataRule(message) ||
    isPrizeRedeemRule(message)
  ) {
    return message.data.notification_text;
  } else if (isImageDataRule(message)) {
    return (
      <Trans i18nKey="message.notification.image">
        <S.NameSpan>LINE OA name</S.NameSpan>
      </Trans>
    );
  } else {
    return '';
  }
}
