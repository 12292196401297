/* eslint-disable no-restricted-imports */
import { ExclamationOutlined } from '@ant-design/icons';
import { memo } from '@chatbotgang/etude/react/memo';
import AntModal from 'antd/es/modal';

import type { ModalProps } from 'antd/es/modal';
import type { OverrideComponentCssProps } from 'shared/utils/styled/types';

import { MotifIcon } from 'icons/motif';
import { styled } from 'shared/utils/styled';
import { overrideCss } from 'shared/utils/styled/override';
import { theme } from 'theme';

export const Modal = styled(AntModal)<OverrideComponentCssProps>`
  .ant-modal-content {
    border-radius: 4px;
  }
  .ant-modal-body {
    padding: 12px 0;
  }
  .ant-modal-footer {
    border-top: 0;
  }
  ${overrideCss}
`;

const WarningModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  text-align: center;

  > :first-child {
    display: flex;
    width: min(100%, 110px);
    align-items: center;
    justify-content: center;
    aspect-ratio: 1;

    > * {
      overflow: hidden;
      width: 100%;
      height: 100%;
      aspect-ratio: 1;
    }
  }
`;

/**
 * @deprecated Use `MotifIcon` instead.
 * @see {@link MotifIcon}
 */
export const StyledExclamationIcon = styled(ExclamationOutlined)`
  display: flex;
  width: 110px;
  height: 110px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${theme.colors.yellow002};
  color: ${theme.colors.yellow006};
  font-size: 65px;
  line-height: 120px;
`;

export const WarningModal = memo((props: ModalProps) => {
  return (
    <Modal {...props}>
      <WarningModalContent>
        <div>
          <StyledExclamationIcon />
        </div>
        <div>{props.children}</div>
      </WarningModalContent>
    </Modal>
  );
});
