import { useTranslation } from 'react-i18next';

import type {
  ImageMapCarousel,
  ImageMapCarouselItem,
} from 'components/LineMessageEditor/models/templateDataAndTypes/imageMapCarousel';
import type { LinkParameter } from 'components/LineMessageEditor/models/templateDataAndTypes/types';

import { Box } from 'components/layoutUtils/Box';
import {
  isImageMapCarouselCustomUriAction,
  isImageMapCarouselMessageAction,
  isImageMapCarouselUriAction,
} from 'components/LineMessageEditor/models/templateDataAndTypes/imageMapCarousel';
import {
  CarrouselScrollWrapper,
  IMAGEMAP_PREVIEW_IMAGE_WIDTH,
  ImageMapActionsBlock,
  LineImageMapCarouselImage,
  LineImageMapCarouselImageWrapper,
} from 'components/LineMessageEditor/previewComponents/Styled';
import {
  getActionBlockStylesFromOffsets,
  parseWidthAndHeight,
} from 'components/LineMessageEditor/utils/imageMapCarousel';
import { Tooltip, TooltipDescription } from 'shared/components/Tooltip';

const getActionBlocks = (imageMapCarouselItem: ImageMapCarouselItem) => {
  const [_, ...actionBlocks] = imageMapCarouselItem.body.contents;
  return actionBlocks;
};

interface ImageMapCarouselPreviewProps {
  message: ImageMapCarousel;
  onAddUserMessageText: (text: string) => void;
}

export const ImageMapCarouselPreview = ({
  message,
  onAddUserMessageText,
}: ImageMapCarouselPreviewProps) => {
  const { t } = useTranslation();

  return (
    <Box mt="16px">
      <CarrouselScrollWrapper>
        {message.data.contents.contents.map((imageMap, index) => {
          const imageUrl = imageMap.body.contents[0].url;

          return (
            <LineImageMapCarouselImageWrapper height={imageUrl ? 'auto' : 0} key={index}>
              <LineImageMapCarouselImage src={imageUrl} />
              {getActionBlocks(imageMap).map(
                ({ width = '', height = '', offsetStart = '', offsetTop = '', action }, index) => {
                  let actionType = '';
                  let text = '';

                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  let clickCallback = () => {};

                  if (isImageMapCarouselMessageAction(action)) {
                    actionType = t('message.send');
                    text = action.text;
                    clickCallback = () => {
                      if (text) onAddUserMessageText(text);
                    };
                  } else if (isImageMapCarouselUriAction(action)) {
                    actionType = t('common.openUrl');
                    text = (message.parameters.find((p) => p.key === action.key) as LinkParameter)
                      .data.url;
                    clickCallback = () => {
                      window.open(text);
                    };
                  } else if (isImageMapCarouselCustomUriAction(action)) {
                    actionType = t('message.apiUrl');
                    text = message.parameters.find((p) => p.mappingKey === action.key)?.key ?? '';
                  }

                  const { aspectRatioValue } = parseWidthAndHeight(
                    imageMap.body.contents[0].aspectRatio ?? '1:1',
                  );

                  const styleProp = getActionBlockStylesFromOffsets(
                    {
                      width: IMAGEMAP_PREVIEW_IMAGE_WIDTH,
                      height: IMAGEMAP_PREVIEW_IMAGE_WIDTH / aspectRatioValue,
                    },
                    { width, height, offsetStart, offsetTop },
                  );

                  return (
                    <Tooltip
                      key={'actions' + index}
                      title={
                        <TooltipDescription>
                          {t('common.textsWithColon', {
                            textBeforeColon: actionType,
                            textAfterColon: text,
                          })}
                        </TooltipDescription>
                      }
                    >
                      <ImageMapActionsBlock
                        style={{
                          ...styleProp,
                        }}
                        onClick={clickCallback}
                      />
                    </Tooltip>
                  );
                },
              )}
            </LineImageMapCarouselImageWrapper>
          );
        })}
      </CarrouselScrollWrapper>
    </Box>
  );
};
