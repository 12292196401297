import { inspectMessage } from '@chatbotgang/etude/debug/inspectMessage';
import { memo } from '@chatbotgang/etude/react/memo';
import { useHandler } from '@chatbotgang/etude/react/useHandler';
import { Divider, Flex, Space } from 'antd';
import { commonFeatureFlagsApi } from 'features/featureFlag';
import { commonConfig } from 'features/featureFlag/commonConfig';

import { APP_LOADED_DATE } from 'AppConstants';

import type { CommonFeatureFlagsTypes } from 'features/featureFlag';
import type { BaseToggleFeatureFlagConfig } from 'features/featureFlag/baseTypes';
import type { ComponentProps, ReactNode } from 'react';
import type { RadioGroup } from 'shared/components/Radio';
import type { ConditionalKeys } from 'type-fest';

import { Button } from 'shared/components/Button';
import { Checkbox } from 'shared/components/Checkbox';
import { CountDown } from 'shared/components/CountDown';
import { Radio } from 'shared/components/Radio';
import { useNow } from 'utils/react/useNow';

const {
  configs,
  isToggleFeatureFlagKey,
  isSingleSelectFeatureFlagKey,
  useFeatureFlagLocalStorageStore,
  useFeatureFlagStore,
} = commonFeatureFlagsApi;

function useShouldAutoEnable(feature: BaseToggleFeatureFlagConfig) {
  const now = useNow();
  return feature.autoEnableAt !== undefined && feature.autoEnableAt < now;
}

function getAutoEnabled(feature: BaseToggleFeatureFlagConfig) {
  return feature.autoEnableAt !== undefined && feature.autoEnableAt < APP_LOADED_DATE;
}

const AutoEnableMessage = memo(function AutoEnableMessage({
  featureFlagKey,
  autoEnableAt,
}: {
  featureFlagKey: CommonFeatureFlagsTypes['ToggleKey'];
  autoEnableAt: BaseToggleFeatureFlagConfig['autoEnableAt'];
}) {
  const autoEnabled = getAutoEnabled(configs[featureFlagKey]);
  const shouldAutoEnabled = useShouldAutoEnable(configs[featureFlagKey]);
  return (
    <>
      {autoEnableAt === undefined ? null : (
        <div>
          {shouldAutoEnabled ? (
            autoEnabled ? (
              'Enabled automatically'
            ) : (
              'Please refresh to enable automatically'
            )
          ) : (
            <>
              (Will be enabled in: <CountDown targetDate={autoEnableAt} />)
            </>
          )}
        </div>
      )}
    </>
  );
});

function getLabel(featureFlagKey: CommonFeatureFlagsTypes['Key']) {
  return commonConfig[featureFlagKey].label ?? featureFlagKey;
}

const FeatureItemToggle = memo(function FeatureItemToggle<
  Key extends CommonFeatureFlagsTypes['ToggleKey'],
>({ featureFlagKey }: { featureFlagKey: Key }) {
  const label = getLabel(featureFlagKey);
  const feature = configs[featureFlagKey];
  const autoEnabled = getAutoEnabled(configs[featureFlagKey]);

  const checked: boolean = useFeatureFlagStore((state) => state.value[featureFlagKey]);

  const onChange = useHandler(() =>
    useFeatureFlagLocalStorageStore.getState().setValue((current) => ({
      ...current,
      [featureFlagKey]: !current[featureFlagKey],
    })),
  );

  return (
    <Checkbox checked={checked ?? false} onChange={onChange} disabled={autoEnabled}>
      {label}
      {feature.autoEnableAt === undefined ? null : (
        <AutoEnableMessage featureFlagKey={featureFlagKey} autoEnableAt={feature.autoEnableAt} />
      )}
    </Checkbox>
  );
});

const FeatureItemSingleSelect = memo(function FeatureItemSingleSelect<
  Key extends CommonFeatureFlagsTypes['SingleSelectKey'],
>({ featureFlagKey }: { featureFlagKey: Key }) {
  const label = getLabel(featureFlagKey);
  const feature = configs[featureFlagKey];
  const value = useFeatureFlagStore((state) => state.value[featureFlagKey]);
  const enabled = value !== null;

  const changeHandler = useHandler<ComponentProps<typeof RadioGroup>['onChange']>((e) => {
    useFeatureFlagLocalStorageStore.getState().setValue((current) => ({
      ...current,
      [featureFlagKey]: e.target.value as CommonFeatureFlagsTypes['Values'][Key],
    }));
  });

  const enable = useHandler(() => {
    useFeatureFlagLocalStorageStore.getState().setValue((current) => ({
      ...current,
      [featureFlagKey]: feature.options[0]?.value,
    }));
  });
  const disable = useHandler(() => {
    useFeatureFlagLocalStorageStore.getState().setValue((current) => ({
      ...current,
      [featureFlagKey]: null,
    }));
  });

  return (
    <Flex vertical={true}>
      <Checkbox checked={enabled} onChange={enabled ? disable : enable}>
        {label}
      </Checkbox>
      <Radio.Group onChange={changeHandler} value={value} style={{ paddingLeft: 24 }}>
        {feature.options.map(({ value: targetValue, label = targetValue }) => (
          <Radio key={targetValue} value={targetValue}>
            {label}
          </Radio>
        ))}
      </Radio.Group>
    </Flex>
  );
});

const FeatureItem = memo<{
  featureFlagKey: CommonFeatureFlagsTypes['FunctionalKey'];
}>(function FeatureItem({ featureFlagKey }) {
  if (isToggleFeatureFlagKey(featureFlagKey))
    return <FeatureItemToggle featureFlagKey={featureFlagKey} />;
  if (isSingleSelectFeatureFlagKey(featureFlagKey))
    return <FeatureItemSingleSelect featureFlagKey={featureFlagKey} />;
  featureFlagKey satisfies never;
  throw new Error(inspectMessage`Unhandled feature flag type: ${featureFlagKey}`);
});

const configsGroupedByDivider = (function configsGroupedByDivider() {
  const result: Array<{
    divider: {
      key: string;
      label: ReactNode;
    };
    configs: Partial<
      Pick<typeof configs, ConditionalKeys<typeof configs, { type: 'toggle' | 'singleSelect' }>>
    >;
  }> = [
    {
      divider: {
        key: '',
        label: 'Ungrouped',
      },
      configs: {},
    },
  ];
  Object.entries(configs).forEach(([key, value]) => {
    if (value.type === 'divider') {
      result.push({
        divider: {
          key,
          label: value.label,
        },
        configs: {},
      });
    } else {
      Object.defineProperty(result[result.length - 1].configs, key, {
        value,
        writable: true,
        enumerable: true,
        configurable: true,
      });
    }
  });
  return result;
})();

export const Feature = memo(function Feature() {
  const { clear } = useFeatureFlagLocalStorageStore(({ value, clear }) => ({
    value,
    clear,
  }));

  return (
    <Space direction="vertical" style={{ padding: 4 }}>
      <Space direction="vertical">
        <ul style={{ listStyleType: 'none' }}>
          {configsGroupedByDivider.map(({ divider, configs }) =>
            Object.keys(configs).length === 0 ? null : (
              <li key={divider.key}>
                {divider.label ? <Divider>{divider.label}</Divider> : null}
                <ul style={{ listStyleType: 'none' }}>
                  {Object.entries(configs).map(([key]) => {
                    const featureFlagKey = key as CommonFeatureFlagsTypes['FunctionalKey'];
                    return (
                      <li key={key}>
                        <FeatureItem featureFlagKey={featureFlagKey} />
                      </li>
                    );
                  })}
                </ul>
              </li>
            ),
          )}
        </ul>
        <Button onClick={clear} type="primary">
          Clear All
        </Button>
      </Space>
    </Space>
  );
});
