import type { TextDataType } from 'components/LineMessageEditor/models/templateDataAndTypes/richtext';
import type { EditorStateObject } from 'components/LineMessageEditor/models/templateDataAndTypes/types';
import type { EditorState } from 'draft-js';

import { parseRichText } from './RichTextPreviewUtils';
import * as S from './Styled';

interface RichTextPreviewProps {
  message: TextDataType;
  editorStateArray?: EditorStateObject[];
  editorState?: EditorState;
}
export const RichTextPreview = ({
  editorStateArray,
  message,
  editorState,
}: RichTextPreviewProps) => {
  return (
    <div>
      <S.LineMessageBubble>
        {parseRichText(
          editorState
            ? editorState!
                .getCurrentContent()
                .getBlocksAsArray()
                .map((block) => block.getText())
                .reduce((pre, now) => pre + '<br/>' + now)
            : editorStateArray!
                .find((d) => d.id === message.format.richEditorId)!
                .editorState.getCurrentContent()
                .getBlocksAsArray()
                .map((block) => block.getText())
                .reduce((pre, now) => pre + '<br/>' + now),
          message.parameters,
        )}
      </S.LineMessageBubble>
    </div>
  );
};
