import CloseCircleOutlined from '@ant-design/icons/CloseCircleOutlined';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import FileOutlined from '@ant-design/icons/FileOutlined';
import objectInspect from 'object-inspect';
import { extname } from 'path-browserify';
import { forwardRef, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { ComponentPropsWithRef, ReactNode } from 'react';
import type { ButtonProps } from 'shared/components/Button';
import type { ModalProps } from 'shared/components/Modal/types';
import type { OverrideComponentCssProps } from 'shared/utils/styled/types';

import { TruncatedText } from 'components/TruncatedText';
import { useHandler } from 'hooks/useEventCallback';
import { useToggle } from 'hooks/useToggle';
import { DeleteIcon } from 'icons/delete';
import { Button } from 'shared/components/Button';
import { Modal } from 'shared/components/Modal';
import { shouldNotForwardProps, styled } from 'shared/utils/styled';
import { css, cx, overrideCss } from 'shared/utils/styled/override';
import { theme } from 'theme';

import { Progress } from './Progress';

const MAX_TRUNCATED_OFFSET = 8;

export type FileDisplayProps = {
  name?: string;
  progress?: number;
  error?: ReactNode | undefined;
  del?: () => void;
  containerProps?: ComponentPropsWithRef<'div'> & OverrideComponentCssProps;
  deleteButtonProps?: ButtonProps & OverrideComponentCssProps;
} & ComponentPropsWithRef<'div'> &
  OverrideComponentCssProps;

export const Container = styled.div.withConfig({
  shouldForwardProp: shouldNotForwardProps(['styledCss']),
})<OverrideComponentCssProps>`
  --upload-layout-file-display-height: 52px;
  align-items: center;
  background-color: ${theme.colors.white001};
  border-color: ${theme.colors.neutral004};
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  display: flex;
  gap: 20px;
  height: var(--upload-layout-file-display-height);
  max-height: var(--upload-layout-file-display-height);
  max-width: 100%;
  min-height: var(--upload-layout-file-display-height);
  padding: 8px 12px;
  width: 100%;
  ${overrideCss}
`;

const ErrorWrap = styled.div<OverrideComponentCssProps>`
  color: ${theme.colors.red006};
  word-break: break-word;
  word-wrap: break-word;
  ${overrideCss}
`;

const Main = styled.div<OverrideComponentCssProps>`
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  ${overrideCss}
`;

const StyledCloseCircleOutlined = styled(CloseCircleOutlined).withConfig({
  shouldForwardProp: shouldNotForwardProps(['styledCss']),
})<OverrideComponentCssProps>`
  ${overrideCss}
`;

const StyledFileOutlined = styled(FileOutlined).withConfig({
  shouldForwardProp: shouldNotForwardProps(['styledCss']),
})<OverrideComponentCssProps>`
  ${overrideCss}
`;

const StyledDeleteIcon = styled(DeleteIcon).withConfig({
  shouldForwardProp: shouldNotForwardProps(['styledCss']),
})<OverrideComponentCssProps>`
  ${overrideCss}
`;

const StyledCloseOutlined = styled(CloseOutlined).withConfig({
  shouldForwardProp: shouldNotForwardProps(['styledCss']),
})<OverrideComponentCssProps>`
  ${overrideCss}
`;

const StyledButton = styled(Button)`
  color: ${theme.colors.neutral007};
  transition: color 0.3s ease-in-out;
  &:hover {
    color: ${theme.colors.blue005};
  }
`;

export const FileDisplay = styled(
  memo(
    forwardRef<HTMLDivElement, FileDisplayProps>(function FileDisplay(
      { name = '', progress = 0, error, del, containerProps, deleteButtonProps, ...props },
      ref,
    ) {
      const { t } = useTranslation();
      const deleteConfirmDialogToggle = useToggle();
      const clickDeleteButtonHandler = useHandler<ButtonProps['onClick']>(() => {
        if (error) {
          del?.();
          return;
        }
        deleteConfirmDialogToggle.on();
      });

      const truncatedOffset = useMemo(
        () => Math.min(extname(name).length + 3, MAX_TRUNCATED_OFFSET),
        [name],
      );

      const cssContainer = useMemo(
        () =>
          cx(
            !!error
              ? css`
                  border-color: ${theme.colors.red006};
                `
              : undefined,
            containerProps?.styledCss,
          ),
        [containerProps?.styledCss, error],
      );

      const leadingIcon = useMemo(() => {
        const Icon = error ? StyledCloseCircleOutlined : StyledFileOutlined;
        return (
          <Icon
            styledCss={css`
              color: ${error ? theme.colors.red006 : theme.colors.neutral007};
              font-size: 24px;
            `}
          />
        );
      }, [error]);

      const showDeleteInsteadOfAbort = useMemo(() => progress >= 1 || error, [progress, error]);

      const delIcon = useMemo(
        () => (
          <div style={{ fontSize: '24px' }}>
            {showDeleteInsteadOfAbort ? <StyledDeleteIcon /> : <StyledCloseOutlined />}
          </div>
        ),
        [showDeleteInsteadOfAbort],
      );

      const ok = useHandler<ModalProps['onOk']>(() => {
        deleteConfirmDialogToggle.off();
        del?.();
      });

      return (
        <div {...props} ref={ref}>
          <Container {...containerProps} styledCss={cssContainer}>
            {leadingIcon}
            <Main>
              <TruncatedText str={name} offset={truncatedOffset} />
              {!error ? <Progress progress={progress} /> : null}
            </Main>
            <StyledButton
              shape="circle"
              type="text"
              icon={delIcon}
              onClick={clickDeleteButtonHandler}
              {...deleteButtonProps}
            />
          </Container>
          {error ? <ErrorWrap>{error}</ErrorWrap> : null}
          <Modal
            title={
              showDeleteInsteadOfAbort
                ? t('upload.layout.fileDisplay.delete.confirm.title')
                : t('upload.layout.fileDisplay.abort.confirm.title')
            }
            open={deleteConfirmDialogToggle.active}
            onCancel={deleteConfirmDialogToggle.off}
            onOk={ok}
            footer={
              <>
                <Button onClick={deleteConfirmDialogToggle.off}>
                  {showDeleteInsteadOfAbort
                    ? t('upload.layout.fileDisplay.delete.confirm.action.cancel')
                    : t('upload.layout.fileDisplay.abort.confirm.action.cancel')}
                </Button>
                <Button type="primary" danger={true} onClick={ok}>
                  {showDeleteInsteadOfAbort
                    ? t('upload.layout.fileDisplay.delete.confirm.action.submit')
                    : t('upload.layout.fileDisplay.abort.confirm.action.submit')}
                </Button>
              </>
            }
          >
            <p>
              {showDeleteInsteadOfAbort
                ? t('upload.layout.fileDisplay.delete.confirm.content', {
                    filename: objectInspect(name),
                  })
                : t('upload.layout.fileDisplay.abort.confirm.content', {
                    filename: objectInspect(name),
                  })}
            </p>
          </Modal>
        </div>
      );
    }),
  ),
)`
  display: flex;
  flex-direction: column;
  gap: 4px;
  ${overrideCss}
`;
