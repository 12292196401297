import { useEffect, useState } from 'react';

import type { QuickReplyItem } from '@line/bot-sdk';
import type { EditorDataType } from 'components/LineMessageEditor/models/templateDataAndTypes/types';
import type { Dispatch, SetStateAction } from 'react';

import { getQuickReplyItems } from 'components/LineMessageEditor/utils/parseSaveDataToState';

export const useQuickReplyItems = (
  initialData?: EditorDataType[],
): {
  quickReplyItems: QuickReplyItem[];
  setQuickReplyItems: Dispatch<SetStateAction<QuickReplyItem[]>>;
} => {
  const [quickReplyItems, setQuickReplyItems] = useState<QuickReplyItem[]>([]);
  useEffect(() => {
    setQuickReplyItems(getQuickReplyItems(initialData));
  }, [initialData]);
  return { quickReplyItems, setQuickReplyItems };
};
