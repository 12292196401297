import { availableLocales } from 'config/availableLocales';
import { defineConfig } from 'features/featureFlag/defineConfig';

const debugFeatureFlags = defineConfig({
  debugDivider: {
    type: 'divider',
    label: 'Debug Tools',
  },
  'show-feature-flag-icon': {
    label: 'Show feature flag icon',
    type: 'toggle',
  },
  'quick-i18n': {
    label: 'Quick switch language',
    type: 'singleSelect',
    options: availableLocales.map(({ code, displayName }) => ({ label: displayName, value: code })),
  },
  'enabled-server-region': {
    label: 'Update server region',
    type: 'singleSelect',
    options: [
      {
        value: 'global',
        label: 'Global',
      },
      {
        value: 'jp',
        label: 'Japan',
      },
    ],
  },
  'react-query-devtool': {
    label: 'Enable react-query devtool',
    type: 'singleSelect',
    options: [
      {
        value: 'bottom-right',
        label: 'Bottom right',
      },
      {
        value: 'top-left',
        label: 'Top left',
      },
      {
        value: 'top-right',
        label: 'Top right',
      },
      {
        value: 'bottom-left',
        label: 'Bottom left',
      },
    ],
  },
});

const releasedFeatureFlags = defineConfig({
  releasedFeatures: {
    type: 'divider',
    label: 'Released Features',
  },
  'auto-segment': { type: 'toggle' },
  'integration-pages': { type: 'toggle' },
  'show-task-key': { label: 'Show task key in TaskProgressModal', type: 'toggle' },
  'show-pushed-tables-card': {
    label:
      'Show auto-reply and tag tables in Insights/Engagement. Show total used point in Insights/Notification Message',
    type: 'toggle',
  },
  'show-data-transformations-insights': {
    label: 'Use experimental data transformations on the new Insights',
    type: 'toggle',
  },
  'show-journey-v2-scheduled-status': {
    label: 'Show Customer Journey V2 Scheduled Status',
    type: 'toggle',
  },
  'show-new-line-message-editor': {
    label: 'Show new Line message editor (for developing new editor)',
    type: 'toggle',
  },
  'activate-api-client': {
    label: 'Use new typesafe API client for data fetching',
    type: 'toggle',
  },
  'show-tracelink-pagination': {
    label: 'Show Tracelink list view pagination',
    type: 'toggle',
    autoEnableAt: new Date('2024/08/21 12:00 GMT+0800'),
  },
  'enable-receipt-module': {
    label: 'Enable Receipt Register module',
    type: 'toggle',
    autoEnableAt: new Date('2024/08/21 12:00 GMT+0800'),
  },
  'show-sms-craft-phase-2': {
    label: 'Show SMS Craft Phase 2',
    type: 'toggle',
  },
  'show-feversocial-entry': {
    label: 'Show Feversocial entry',
    type: 'toggle',
    autoEnableAt: new Date('2024/08/20 12:00 GMT+0800'),
  },
  'enable-tag-manager-v2': {
    label: 'Enable tag manager V2',
    type: 'toggle',
  },
  'show-caac-login-link': {
    label: 'Show CAAC login link',
    type: 'toggle',
    autoEnableAt: new Date('2024/08/26 18:00 GMT+0800'),
  },
  'enable-table-view-v2': {
    label: 'Show table view V2 components',
    type: 'toggle',
  },
});

export const featureFlagConfigs = defineConfig({
  ...debugFeatureFlags,
  ...releasedFeatureFlags,
});
