import PlayCircleOutlined from '@ant-design/icons/PlayCircleOutlined';
import { Trans } from 'react-i18next';

import type { PopupProps } from 'components/Popup';
import type { ComponentProps } from 'react';

import { StyledIcon, Text } from 'components';
import { FileInput } from 'components/Input';
import { EditSvg } from 'icons/edit';
import * as Validator from 'lib/validator';

import * as S from '../Styled';

interface Props {
  setOpen: () => void;
  onUploadVideo?: ComponentProps<typeof FileInput>['onChange'];
  popupProps: Omit<PopupProps, 'children'>;
}

export function VideoEditOverlay(props: Props) {
  return (
    <S.ImageCoverWrapper>
      <S.ImageHoverBoxIconWrapper>
        <StyledIcon
          mb="12px"
          width={24}
          height={24}
          display="inline-block"
          fontSize={20}
          lineHeight="0"
          component={<PlayCircleOutlined />}
        />
        <br />
        <Text position="relative">
          <Trans>common.replace</Trans>
        </Text>
        <FileInput accept="video/mp4" onChange={props.onUploadVideo} />
      </S.ImageHoverBoxIconWrapper>
      <Validator.FormPopover
        popupAlign={{
          offset: [200, 0],
        }}
        placement="right"
        {...props.popupProps}
      >
        <S.ImageHoverBoxIconWrapper data-test="video-settings">
          <StyledIcon
            position="relative"
            mb="14px"
            p={0}
            width={24}
            height={24}
            fontSize={24}
            lineHeight="0"
            display="inline-block"
            component={<EditSvg />}
            onClick={props.setOpen}
          />
          <br />
          <Text position="relative" onClick={props.setOpen}>
            <Trans>common.edit</Trans>
          </Text>
        </S.ImageHoverBoxIconWrapper>
      </Validator.FormPopover>
    </S.ImageCoverWrapper>
  );
}
