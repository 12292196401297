import _get from 'lodash/get';

import type { InValid, ValidatorState } from '../context';

export const isInvalid = (invalid: InValid | undefined): boolean => {
  if (invalid === undefined) return false;
  else return !invalid.valid;
};

export const getInvalid = (
  invalidObj: ValidatorState['invalid'],
  nameList: string[],
): boolean[] => {
  return nameList.map((name) => {
    const invalid = _get(invalidObj, name);
    if (invalid) {
      return !(invalid.valid ? invalid.valid : false);
    } else {
      return false;
    }
  });
};
