import type { CardPreviewProps } from './CardPreview';

import { Box } from 'components';

import { CardPreview } from './CardPreview';
import { CarrouselScrollWrapper } from './Styled';

interface Props {
  data: CardPreviewProps[];
}

export function CarouselPreview(props: Props) {
  return (
    <Box>
      <CarrouselScrollWrapper>
        {props.data.map((card, i) => (
          <CardPreview key={i} {...card} carrouselIndex={i} />
        ))}
      </CarrouselScrollWrapper>
    </Box>
  );
}
