import styled from '@emotion/styled';

import type { LineMessageEditorInput } from 'shared/components/Editor/LineMessageEditor/types';

import { CardModulePreview } from 'shared/components/Editor/LineMessageEditor/components/LineMessageEditorPreview/CardModulePreview';
import { FlexPreview } from 'shared/components/Editor/LineMessageEditor/components/LineMessageEditorPreview/FlexPreview';
import { ImagemapCarouselModulePreview } from 'shared/components/Editor/LineMessageEditor/components/LineMessageEditorPreview/ImagemapCarouselModulePreview';
import { LineMessageEditorImageCarouselModulePreview } from 'shared/components/Editor/LineMessageEditor/components/LineMessageEditorPreview/LineMessageEditorImageCarouselModulePreview';
import { LineMessageEditorImagemapModulePreview } from 'shared/components/Editor/LineMessageEditor/components/LineMessageEditorPreview/LineMessageEditorImagemapModulePreview';
import { LineMessageEditorImageModulePreview } from 'shared/components/Editor/LineMessageEditor/components/LineMessageEditorPreview/LineMessageEditorImageModulePreview';
import { LineMessageEditorPreviewHint } from 'shared/components/Editor/LineMessageEditor/components/LineMessageEditorPreview/LineMessageEditorPreviewHint';
import { LineMessageEditorQuickReplyPreview } from 'shared/components/Editor/LineMessageEditor/components/LineMessageEditorPreview/LineMessageEditorQuickReplyPreview';
import { LineMessageEditorTextModulePreview } from 'shared/components/Editor/LineMessageEditor/components/LineMessageEditorPreview/LineMessageEditorTextModulePreview';
import { LineMessageEditorVideoModulePreview } from 'shared/components/Editor/LineMessageEditor/components/LineMessageEditorPreview/LineMessageEditorVideoModulePreview';
import { SmsModulePreview } from 'shared/components/Editor/LineMessageEditor/components/LineMessageEditorPreview/SmsModulePreview';
import {
  isCardModule,
  isCarouselModule,
  isFlexModule,
  isImageCarouselModule,
  isImagemapCarouselModule,
  isImagemapModule,
  isImageModule,
  isNewsModule,
  isPrizeModule,
  isSmsModule,
  isTextModule,
  isVideoModule,
} from 'shared/components/Editor/LineMessageEditor/utils/helper';
import { theme } from 'theme';

const LineMessageBubble = styled.div`
  box-sizing: border-box;
  display: inline-block;
  padding: 5px 10px;
  width: auto;
  min-width: 30px;
  max-width: calc(100% - 64px);
  height: auto;
  min-height: 30px;
  line-height: 1.5;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  background-color: ${theme.colors.white001};
  border-radius: 16px;
`;

const LineMessageEditorPreviewContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 12px;
`;

export const CarouselScrollContainer = styled.div`
  display: flex;
  gap: 8px;
  overflow-x: scroll;

  > div {
    min-width: 210px;
  }
`;

export const LineMessageEditorPreviewContent = ({
  messages,
  quickReply,
}: {
  messages: LineMessageEditorInput['messages'];
  quickReply: LineMessageEditorInput['quick_reply'];
}) => {
  const firstMessage = messages.at(0);
  return (
    <LineMessageEditorPreviewContentWrapper>
      {messages.map((message, index) => {
        const key = `${message.module_id}_${index}`;
        return (
          <span key={key}>
            {isSmsModule(message) ? <SmsModulePreview message={message} /> : null}
            {isTextModule(message) ? (
              <LineMessageBubble>
                <LineMessageEditorTextModulePreview message={message} />
              </LineMessageBubble>
            ) : null}
            {isImageModule(message) ? (
              <LineMessageEditorImageModulePreview message={message} />
            ) : null}
            {isImageCarouselModule(message) ? (
              <LineMessageEditorImageCarouselModulePreview message={message} />
            ) : null}
            {isVideoModule(message) ? (
              <LineMessageEditorVideoModulePreview message={message} />
            ) : null}
            {isImagemapModule(message) ? (
              <LineMessageEditorImagemapModulePreview message={message} />
            ) : null}
            {isImagemapCarouselModule(message) ? (
              <ImagemapCarouselModulePreview message={message} />
            ) : null}
            {isCardModule(message) ? (
              <CardModulePreview
                parameters={message.parameters}
                cardContent={message.data.contents}
              />
            ) : null}
            {isCarouselModule(message) ? (
              <CarouselScrollContainer>
                {message.data.contents.map((cardContent, index) => (
                  <CardModulePreview
                    key={index}
                    parameters={message.parameters}
                    cardContent={cardContent.contents}
                  />
                ))}
              </CarouselScrollContainer>
            ) : null}
            {isPrizeModule(message) || isNewsModule(message) || isFlexModule(message) ? (
              <FlexPreview
                key={index + 'FlexPreview'}
                rowIndex={index}
                message={message}
                onAddUserMessageText={() => {}}
                data-test="flex-preview-Prize"
              />
            ) : null}
          </span>
        );
      })}
      <LineMessageEditorQuickReplyPreview quickReply={quickReply} />
      {firstMessage ? <LineMessageEditorPreviewHint /> : null}
    </LineMessageEditorPreviewContentWrapper>
  );
};
