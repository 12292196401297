import { useEffect, useState } from 'react';

import type { useStore } from 'components/LineMessageEditor/models/models';
import type {
  EditorDataType,
  EditorStateObject,
} from 'components/LineMessageEditor/models/templateDataAndTypes/types';
import type { Dispatch, SetStateAction } from 'react';

import { dispatch } from 'components/LineMessageEditor/models/models';
import {
  imageDataInit,
  parseParamRichTextToStateWithSideEffects,
  parseToState,
  richTextIdReIndex,
} from 'components/LineMessageEditor/utils/parseSaveDataToState';
import { findFirstRichEditorId } from 'components/LineMessageEditor/utils/richEditorId';

type UseEditorStateArrayResult = [
  EditorStateObject[],
  Dispatch<SetStateAction<EditorStateObject[]>>,
];

/**
 * `useEditorStateArray` takes care of parsing raw {@link EditorDataType EditorDataType[]}
 * with `richTextEditor` and `paramRichTextEditor` transformed data.
 *
 * Use this hook for displaying parameter related components, e.g., `LineEditorLiveView`,
 * `LineMessageEditor`, etc.
 *
 * Retrieve the result of the tuple returned from {@link useState React.useState}.
 * The {@link EditorStateObject EditorStateObject[]} is for draft-js.
 *
 * @remarks **Place this hook AFTER {@link useStore}** to ensure the `dispatch` will work with
 * the real time data and `store`.
 *
 * @param initialData
 */
export function useEditorStateArray(initialData?: EditorDataType[]): UseEditorStateArrayResult {
  const [editorStateArray, setEditorStateArray] = useState<EditorStateObject[]>([]);

  useEffect(() => {
    if (!initialData) {
      return;
    }
    richTextIdReIndex(initialData);
    const id = findFirstRichEditorId(initialData);
    const richTextEditorStateArray = parseToState(initialData);
    const paramRichTextEditorStateArray = parseParamRichTextToStateWithSideEffects(initialData);
    setEditorStateArray(richTextEditorStateArray.concat(paramRichTextEditorStateArray));
    dispatch('setEditorData', {
      editorData: imageDataInit(initialData),
      id,
    });
    return () => {
      dispatch('setEditorData', {
        editorData: [],
        id,
      });
    };
  }, [initialData, setEditorStateArray]);

  return [editorStateArray, setEditorStateArray];
}
