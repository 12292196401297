import { useReducer, useState } from 'react';
import ReactGA4 from 'react-ga4';
import { useTranslation } from 'react-i18next';

import type { LoginSsoVariable } from 'context/authContext/types';
import type { ReactNode } from 'react';

import * as actions from 'context/authContext/actions';
import { LogoutModal } from 'context/authContext/components';
import { AuthContext, initValue } from 'context/authContext/context';
import { useUserQuery } from 'context/authContext/hooks/useUserQuery';
import { reducer } from 'context/authContext/reducer';
import * as services from 'context/authContext/services';
import { useEnhancedEffect } from 'hooks/useEnhancedEffect';
import { Loading } from 'layout/Loading';
import { cacheChannel } from 'layout/utils';
import * as Logger from 'lib/logger';

export const AuthContextProvider = (props: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initValue);
  const { data, isRejected, error, isPending, isSettled, reload } = useUserQuery();
  const [firstAttemptFinished, setFirstAttemptFinished] = useState(false);
  const { i18n } = useTranslation();

  useEnhancedEffect(() => {
    if (isSettled) {
      if (data) {
        if (data.currentChannel) {
          cacheChannel({ id: data.currentChannel.id, type: data.currentChannel.type });
        }

        dispatch(
          actions.setState({
            ...data,
          }),
        );

        if (data.user) {
          Logger.setUser(data.user, data.org ?? undefined);
        }

        setFirstAttemptFinished(true);
      } else {
        dispatch(actions.setState(initValue));
      }
    }
  }, [isSettled, data, i18n]);

  if (!firstAttemptFinished) {
    if (isPending) {
      return <Loading />;
    }
    if (isRejected && error) {
      return (
        <div style={{ color: 'red' }}>
          <p>Uh oh... There is a problem. Try refreshing the app.</p>
          <pre>{error.message}</pre>
        </div>
      );
    }
  }

  const loginWithSso = (loginSsoVariable: LoginSsoVariable) =>
    services.loginWithSso(loginSsoVariable).then(reload);
  const logout = () =>
    services.logout().then(() => {
      Logger.unSetUser();
      ReactGA4.set({
        user_properties: {
          channel_id: null,
          organization_id: null,
          user_id: null,
        },
      });
      reload();
    });

  return (
    <AuthContext.Provider value={{ state, dispatch, loginWithSso, reload, logout }}>
      {props.children}
      <LogoutModal />
    </AuthContext.Provider>
  );
};
