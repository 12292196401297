/**
 * Steps of adding new locales:
 * 1. Import new locale file
 * 2. Add up types in `AvailableLangs` and `AvailableLangsDisplay`
 * 3. Add other language setting in `availableLocales`
 * 4. Go to `src/utils/LangsLocalesUtils.ts` and add new strategy in `langStrategies`
 * 5. Use strategy in `getStaticAssetsByLang`, `getDateLocaleCode`, `loadAntDesignLocales`,
 *    `loadAntDesignLocales`, `formatLangCode`, and `makeFallbackLangs`
 */
import enUS from 'antd/es/locale/en_US';
import jaJP from 'antd/es/locale/ja_JP';
import thTH from 'antd/es/locale/th_TH';
import zhTW from 'antd/es/locale/zh_TW';
import { availableLocales } from 'config/availableLocales';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';

import { ENV, FIREBASE_LOCALE_STORAGE_NAME, inDevelopment, USE_BUILT_IN_LOCALES } from 'env';

import type { ConfigProviderProps } from 'antd/es/config-provider';

import { getDownloadURL } from 'lib/downloadFile';
import { logError } from 'lib/logger';

import { LangsLocalesUtils, langStrategies } from './utils/LangsLocalesUtils';

type Locale = ConfigProviderProps['locale'];

export type AvailableLangs = string & ('en' | 'zh-hant' | 'th' | 'ja');
export type AvailableLangsDisplay = 'English' | '繁體中文' | 'ภาษาไทย' | '日本語';

export type AssetsMap = Record<AvailableLangs, string>;

export const getStaticAssetsByLang = (lang: string, assetsMap: AssetsMap): string => {
  const utils = new LangsLocalesUtils([
    langStrategies.isZHLang.bind(undefined, lang, assetsMap['zh-hant']),
    langStrategies.isENLang.bind(undefined, lang, assetsMap['en']),
    langStrategies.isTHLang.bind(undefined, lang, assetsMap['th']),
    langStrategies.isJALang.bind(undefined, lang, assetsMap['ja']),
    langStrategies.default.bind(undefined, lang, assetsMap['en']),
  ]);
  const assetPath = utils.getResult();

  return assetPath ?? '';
};

export const getDateLocaleCode = (lang: string): string => {
  const utils = new LangsLocalesUtils([
    langStrategies.isZHLang.bind(undefined, lang, 'zh-tw'),
    langStrategies.isENLang.bind(undefined, lang, 'en'),
    langStrategies.isTHLang.bind(undefined, lang, 'th-TH'),
    langStrategies.isJALang.bind(undefined, lang, 'ja'),
    langStrategies.default.bind(undefined, lang, 'en'),
  ]);
  const formattedCode = utils.getResult();

  return formattedCode ?? 'en';
};

export const loadAntDesignLocales = (lang: string): Locale => {
  const strategies = [
    langStrategies.isZHLang.bind(this, lang, zhTW),
    langStrategies.isENLang.bind(this, lang, enUS),
    langStrategies.isTHLang.bind(this, lang, thTH),
    langStrategies.isJALang.bind(this, lang, jaJP),
    langStrategies.default.bind(this, lang, enUS),
  ];
  let antDLocales = {} as Locale;

  strategies.some((func) => {
    antDLocales = func();
    if (antDLocales) {
      return true;
    }
    return false;
  });

  return antDLocales;
};

/**
 * Unify lang code to our accepted format in `AvailableLangs`, resource lang code might be from navigator or others
 * @param lang - language code
 * @returns formatted lang code
 */
export const formatLangCode = (lang: string): AvailableLangs => {
  const strategies = [
    langStrategies.isZHLang.bind(this, lang, 'zh-hant'),
    langStrategies.isENLang.bind(this, lang, 'en'),
    langStrategies.isTHLang.bind(this, lang, 'th'),
    langStrategies.isJALang.bind(this, lang, 'ja'),
    langStrategies.default.bind(this, lang, 'en'),
  ];
  let formattedCode = '';

  strategies.some((func) => {
    formattedCode = func();
    if (formattedCode) {
      return true;
    }
    return false;
  });

  return formattedCode as AvailableLangs;
};

/**
 * Generate `fallbackLng` for i18next fallbackLng
 * @param lang -language code
 * @returns
 */
const makeFallbackLangs = (lang: string | undefined) => {
  if (typeof lang === 'undefined') {
    return ['en'];
  }

  const strategies = [
    langStrategies.isZHLang.bind(this, lang, ['zh-hant', 'en']),
    langStrategies.isENLang.bind(this, lang, ['en']),
    langStrategies.isTHLang.bind(this, lang, ['th', 'en']),
    langStrategies.isJALang.bind(this, lang, ['ja', 'en']),
    langStrategies.default.bind(this, lang, ['en']),
  ];
  let fallbackLng: string[] = [];

  strategies.some((func) => {
    fallbackLng = func();
    if (fallbackLng) {
      return true;
    }
    return false;
  });

  return fallbackLng;
};

const setHtmlLang = (lang: string): void => {
  document.documentElement.setAttribute('lang', formatLangCode(lang));
};

i18n
  .use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      debug: inDevelopment,
      detection: {
        order: ['navigator'],
        caches: [], // must set empty or i18next will generate data in local storage
        convertDetectedLanguage: formatLangCode,
      },
      lowerCaseLng: true, // all lowercase for third-part library / frontend / backend consistency, eg. zh-Hant -> zh-hant
      fallbackLng: makeFallbackLangs,
      backend: {
        backends: [
          ...(USE_BUILT_IN_LOCALES ? [] : [HttpBackend]),
          // if a namespace can't be loaded via normal http-backend loadPath, then the inMemoryLocalBackend will try to return the correct resources
          resourcesToBackend((language: string) => {
            // Fall back to local translation files
            return import(`../../../node_modules/.cache/i18n/${language}/translation.json`);
          }),
        ],
        backendOptions: [
          {
            loadPath: async (languages: string[], namespaces: string[]) => {
              const [lng] = languages;
              const [ns] = namespaces;
              const availableLocaleCodes = availableLocales.map(
                (locale) => locale.code,
              ) as string[];

              // This prevents fetching additional duplicate locale files
              if (!availableLocaleCodes.includes(lng)) return undefined;

              try {
                return await getDownloadURL(
                  FIREBASE_LOCALE_STORAGE_NAME,
                  `/maac/${ENV}/latest/json/${formatLangCode(lng)}/${ns}.json`,
                );
              } catch (error) {
                logError(error);
              }
            },
          },
        ],
      },
      keySeparator: false, // we do not use keys in form messages.welcome
      interpolation: {
        escapeValue: false, // react already safes from xss,
        format: (value, format) => {
          if (format === 'thousandth') return value.toLocaleString();
          return value;
        },
      },
      saveMissing: true,
      missingKeyHandler: (_langs, _namespace, key) => {
        logError({ missingTranslationkey: key });
      },
      missingInterpolationHandler: (_, value) => {
        logError({
          missingInterpolation: value,
        });
      },
      react: {
        transKeepBasicHtmlNodesFor: ['span'], // Allow the use of `span` in interpolated strings
      },
      /**
       * We use the i18next JSON v3 so we need this property.
       * After we migrate to v4, we can remove this property.
       * See See https://www.i18next.com/misc/migration-guide#json-format-v4-pluralization
       */
      compatibilityJSON: 'v3',
    },
    () => {
      setHtmlLang(i18n.language);
    },
  );

i18n.on('languageChanged', (lng) => {
  setHtmlLang(lng);
});

export { i18n };
