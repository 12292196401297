import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { isDeepEqual } from 'remeda';

import { TagItem } from 'shared/components/TagItem';
import { useTagMetadata } from 'shared/hooks/tag/useTagMetadata';

interface TagItemListProps {
  tagItems: number[];
  onClose: (tag: number) => void;
  closable: boolean;
}

/**
 * Ensure render is necessary by inspecting and comparing props
 */
export const TagItemList = memo(
  function TagItemList({ tagItems, onClose, closable }: TagItemListProps) {
    const { t } = useTranslation();
    const { getTagNameById, isTagMetadataLoading } = useTagMetadata({ enabled: true });

    return (
      <>
        {tagItems.map((tagId) => (
          <TagItem key={tagId} onClose={() => onClose(tagId)} closable={closable} wrapTags={true}>
            {isTagMetadataLoading ? t('common.loading') : getTagNameById(tagId)}
          </TagItem>
        ))}
      </>
    );
  },
  (prevProps: TagItemListProps, nextProps: TagItemListProps) =>
    isDeepEqual(nextProps.tagItems, prevProps.tagItems),
);
