import Select from 'antd/es/select';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { MotifIcon } from 'icons/motif';
import { TagSelectCreateOption } from 'shared/components/TagPicker/components/TagSelectStyled';
import { useTagSelect } from 'shared/components/TagPicker/hooks/useTagSelect';
import { Typography } from 'shared/components/Typography';
import { useTagMetadata } from 'shared/hooks/tag/useTagMetadata';
import { theme } from 'theme';

/**
 * Shared select component for choosing tags
 */
export const TagSelect = memo(function TagSelect({
  selectedTagList = [],
  tagList = [],
  tagCountLimit,
  isOverTagCountLimit,
  changeTagHandler,
  createTagHandler,
  disableCreateNewTagOption,
  showTitle = true,
  loading = false,
  disabled = false,
}: {
  selectedTagList?: number[];
  tagList?: number[];
  /** Use `Infinity` for unlimited tags */
  tagCountLimit: number;
  isOverTagCountLimit: boolean;
  changeTagHandler: (tagNames: string[]) => void;
  createTagHandler: (tagName: string) => void;
  disableCreateNewTagOption?: boolean;
  showTitle?: boolean;
  loading?: boolean;
  disabled?: boolean;
}) {
  const { t } = useTranslation();

  const {
    setTagSelectSearchText,
    trimmedTagSelectSearchText,
    showCreateTagOption,
    isTagLengthError,
  } = useTagSelect({
    disableCreateNewTagOption,
  });

  const { getTagNamesByIds, isTagMetadataLoading, tagMetadataItems } = useTagMetadata({
    enabled: true,
  });

  const tagMetadataItemsFiltered = useMemo(
    () =>
      tagMetadataItems
        ? tagMetadataItems
            .filter(({ id }) => !selectedTagList.some((selectedTagId) => selectedTagId === id))
            .filter(({ id }) => !tagList.some((tagListId) => tagListId === id))
        : null,
    [selectedTagList, tagList, tagMetadataItems],
  );

  const selectedTagListValue = useMemo(
    () => getTagNamesByIds(selectedTagList),
    [getTagNamesByIds, selectedTagList],
  );

  return (
    <>
      {showTitle ? (
        <Typography variant="body" style={{ display: 'block', marginBottom: '10px' }}>
          {t('tag.tagPickerTitle', { count: tagCountLimit })}
        </Typography>
      ) : null}
      <Select<string[]>
        mode="multiple"
        placeholder={disableCreateNewTagOption ? t('tag.search') : t('tag.searchOrCreate')}
        value={selectedTagListValue}
        onChange={changeTagHandler}
        onSearch={setTagSelectSearchText}
        onSelect={(selectedValue) => {
          /**
           * Only select the "Create new tag..." option when we create a new one
           */
          if (showCreateTagOption && selectedValue === trimmedTagSelectSearchText) {
            createTagHandler(trimmedTagSelectSearchText);
          }
        }}
        loading={loading || isTagMetadataLoading}
        disabled={disabled || isTagMetadataLoading}
        maxTagCount={20}
        maxTagTextLength={30}
        style={{ width: '100%' }}
        getPopupContainer={(trigger) => trigger.parentElement}
        onKeyDown={(event) => {
          event.stopPropagation();
        }}
      >
        {tagMetadataItemsFiltered
          ? tagMetadataItemsFiltered.map(({ id, name }) => (
              <Select.Option key={id} value={name}>
                {name}
              </Select.Option>
            ))
          : null}
        {showCreateTagOption ? (
          <Select.Option
            value={trimmedTagSelectSearchText}
            disabled={disabled || isTagLengthError || isOverTagCountLimit}
          >
            <TagSelectCreateOption>
              <MotifIcon un-i-motif="tag" />
              <Typography
                variant="body"
                style={{ display: 'inline-block', textOverflow: 'ellipsis', overflow: 'hidden' }}
              >
                {t('tag.createWithNewOne', { name: trimmedTagSelectSearchText })}
              </Typography>
            </TagSelectCreateOption>
            {isTagLengthError || isOverTagCountLimit ? (
              <Typography variant="body" style={{ color: theme.colors.red006 }}>
                {isTagLengthError
                  ? t('tag.reachTagLengthLimit')
                  : t('common.reachTagCountLimit', { count: tagCountLimit })}
              </Typography>
            ) : null}
          </Select.Option>
        ) : null}
      </Select>
    </>
  );
});
