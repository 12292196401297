import UpOutlined from '@ant-design/icons/UpOutlined';
import { useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { LineMessageEditorInput } from 'shared/components/Editor/LineMessageEditor/types';
import type { CustomElementTypes } from 'shared/components/Editor/types/slate-custom';

import { StyledIcon } from 'components/StyledIcon';
import { useToggle } from 'hooks/useToggle';
import { LineMessageEditorPreviewContent } from 'shared/components/Editor/LineMessageEditor/components/LineMessageEditorPreview/LineMessageEditorPreviewContent';
import { LineMessageEditorPreviewWrapper } from 'shared/components/Editor/LineMessageEditor/components/LineMessageEditorPreview/LineMessageEditorPreviewWrapper';
import { LineMessageEditorTextModulePreview } from 'shared/components/Editor/LineMessageEditor/components/LineMessageEditorPreview/LineMessageEditorTextModulePreview';
import { getColorByTagType } from 'shared/components/Editor/LineMessageEditor/utils/getColorByTagType';
import {
  isCardModule,
  isCarouselModule,
  isFlexModule,
  isImageCarouselModule,
  isImagemapCarouselModule,
  isImagemapModule,
  isImageModule,
  isNewsModule,
  isPrizeModule,
  isTextModule,
  isVideoModule,
} from 'shared/components/Editor/LineMessageEditor/utils/helper';
import { Typography } from 'shared/components/Typography';
import { shouldNotForwardProps, styled } from 'shared/utils/styled';
import { theme } from 'theme';

const INITIAL_HEIGHT = 82;

interface TagProps {
  type: CustomElementTypes;
}

const Tag = styled.span.withConfig({
  shouldForwardProp: shouldNotForwardProps(['type']),
})<TagProps>`
  color: ${({ type }) => getColorByTagType(type)};
  cursor: pointer;
`;

const NotificationWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 16px 16px 11px;
  background-color: ${theme.colors.white001};
  opacity: 0.9;
  border: solid 1px ${theme.colors.neutral003};
  border-radius: 4px;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
  overflow-wrap: break-word;
  min-height: 82px;
`;

interface UpOutlinedIconProps {
  open: boolean;
}

const UpOutlinedIcon = styled(StyledIcon).withConfig({
  shouldForwardProp: shouldNotForwardProps(['open']),
})<UpOutlinedIconProps>`
  position: absolute;
  top: 10px;
  right: 10px;
  transition: transform 0.25s ease-in-out;
  transform: ${({ open }) => (open ? 'rotateZ(0deg)' : 'rotateZ(180deg)')};
`;

export const LineMessageEditorPreview = ({
  messages = [],
  quickReply = { items: [] },
  headingMessage,
}: {
  messages: LineMessageEditorInput['messages'];
  quickReply?: LineMessageEditorInput['quick_reply'];
  headingMessage?: React.ReactNode;
}) => {
  const { t } = useTranslation();
  const firstMessage = messages.at(0);
  const innerLayerRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(INITIAL_HEIGHT);
  const { active: notificationOpen, toggle: setNotificationOpen } = useToggle(false);

  const handleNotificationClick = () => {
    if (!innerLayerRef.current) return;

    const newHeight = innerLayerRef.current.offsetHeight + 30;
    if (notificationOpen || newHeight <= height) {
      setHeight(INITIAL_HEIGHT);
    } else {
      setHeight(newHeight);
    }

    setNotificationOpen();
  };

  return (
    <LineMessageEditorPreviewWrapper>
      {firstMessage ? (
        <NotificationWrapper
          style={{ height: notificationOpen ? height : INITIAL_HEIGHT }}
          onClick={handleNotificationClick}
        >
          <UpOutlinedIcon open={notificationOpen} component={<UpOutlined />} />
          <div ref={innerLayerRef}>
            <Typography variant="body" fontWeight={700}>
              {t('message.notificationText')}
            </Typography>
            <Typography variant="body">
              {isTextModule(firstMessage) ? (
                <LineMessageEditorTextModulePreview message={firstMessage} />
              ) : null}
              {isImageModule(firstMessage) ? (
                <Trans i18nKey="message.notification.image">
                  <Tag type="name">LINE OA name</Tag>
                </Trans>
              ) : null}
              {isImageCarouselModule(firstMessage) ||
              isVideoModule(firstMessage) ||
              isImagemapModule(firstMessage) ||
              isImagemapCarouselModule(firstMessage) ||
              isCardModule(firstMessage) ||
              isCarouselModule(firstMessage) ||
              isPrizeModule(firstMessage) ||
              isNewsModule(firstMessage) ||
              isFlexModule(firstMessage)
                ? firstMessage.data.notification_text
                : null}
            </Typography>
          </div>
        </NotificationWrapper>
      ) : null}
      {headingMessage}
      <LineMessageEditorPreviewContent messages={messages} quickReply={quickReply} />
    </LineMessageEditorPreviewWrapper>
  );
};
