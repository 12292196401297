import i18next from 'i18next';
import { immerable } from 'immer';
import omit from 'lodash/omit';

import { PARAM_PLACEHOLDER } from 'components/LineMessageEditor/constants';

import type {
  CardButtonType,
  CardRichEditorIdsInjection,
  ParamRichTextFormat,
  ParamRichTextParameter,
  TagAction,
  TagActionData,
} from './card';
import type {
  AbstractEditorData,
  CustomParameter,
  CustomParameterData,
  ImageSourceType,
  LinkParameter,
  LinkParameterData,
  ParamRichTextParameterData,
  ShareButtonParameter,
  ShareButtonParameterData,
  Utm,
} from './types';
import type { LineFieldType } from 'components/LineMessageEditor/models/templateDataAndTypes/LineFieldType';

import { generateMappingKey, isMappingKey } from 'components/LineMessageEditor/utils/transformKey';

import {
  CardData,
  createCustomParameterUriButton,
  createMessageButton,
  createShareButtonParameterButton,
  createUriButton,
} from './card';
import {
  createCustomParameter,
  isCustomParameter,
  isLinkParameter,
  isShareButtonParameter,
} from './types';

export interface CarrouselDataType {
  notification_text: string;
  contents: Array<CardData>;
}

type CarrouselParameter =
  | LinkParameter
  | CustomParameter
  | ParamRichTextParameter
  | ShareButtonParameter;

/**
 * The state of Carrousel
 * @see https://immerjs.github.io/immer/complex-objects
 */
export class CarrouselData
  implements
    AbstractEditorData<
      CarrouselDataType,
      ParamRichTextFormat,
      TagActionData,
      | LinkParameterData
      | CustomParameterData
      | ParamRichTextParameterData
      | ShareButtonParameterData
    >
{
  [immerable] = true;
  module_id: LineFieldType.Carrousel = 5;
  data: CarrouselDataType = {
    notification_text: '',
    contents: [],
  };
  parameters: Array<CarrouselParameter> = [];
  actions: TagAction[] = [];
  quick_reply = { items: [] };
  format: ParamRichTextFormat['format'] = { tagId: 0 };

  constructor({ data, ...props }: Partial<CarrouselData & CardRichEditorIdsInjection> = {}) {
    Object.assign(this, omit(props, ['richEditorIds']));
    if (data) {
      this.data = data;
      this.data.contents = this.data.contents.map(
        (c) => new CardData({ ...c, richEditorIds: props.richEditorIds }),
      );
    } else if (!data && props.richEditorIds !== undefined) {
      this.data.contents = [new CardData({ richEditorIds: props.richEditorIds })];
    }
  }

  getCardData(index: number): CardData {
    return this.data.contents[index];
  }

  addButton(index: number): void {
    const targetCard = this.getCardData(index);
    const buttonIndex = targetCard.contents.footer.contents.length + 1;
    const newButton = createMessageButton({
      label: `${i18next.t('glossary.button')} #${buttonIndex}`,
      key: generateMappingKey('messageButton'),
    });
    targetCard.addButton(newButton.button);
    this.actions.push(newButton.action);
  }

  deleteButton(key: string, index: number): void {
    const targetCard = this.getCardData(index);
    targetCard.deleteButton(key);
    this.actions = this.actions.filter((a) => a.key !== key);
    this.parameters = this.parameters.filter((p) => p.key !== key && p.mappingKey !== key);
  }

  addCarrousel(payload?: CardRichEditorIdsInjection): void {
    this.data.contents.push(new CardData(payload));
  }

  removeCarrousel(index: number): void {
    // First we need to find existing keys from the card (one element in the carrousel) and remove the parameters which containing the keys
    const targetCard = this.getCardData(index);

    const keyListToRemove = targetCard.contents.footer.contents.map((button) => button.key);

    this.parameters = this.parameters.filter(
      (param) =>
        !keyListToRemove.includes(param.key) && !keyListToRemove.includes(param.mappingKey ?? ''),
    );

    // Then remove the card
    this.data.contents.splice(index, 1);
  }

  switchFooterActionType(
    index: number,
    type: CardButtonType,
    carrouselIndex: number,
    previousType: CardButtonType,
  ): void {
    // Should remove old parameters before switching type
    const key = this.getFooterKey(carrouselIndex, index);
    this.removeOldParameters(key, previousType);

    switch (type) {
      case 'message':
        this.convertToMessage(carrouselIndex, index);
        break;
      case 'uri':
        this.convertToURI(carrouselIndex, index);
        break;
      case 'customUri':
        this.convertToCustomUri(carrouselIndex, index);
        break;
      case 'shareButton':
        this.convertToShareButton(carrouselIndex, index);
        break;
      default:
        break;
    }
  }

  setUrlWithKey(
    isParam: boolean,
    carrouselIndex: number,
    url: string,
    aspectRatio: string,
    isAnimated: boolean,
  ): void {
    this.getCardData(carrouselIndex).setUrlWithKey(isParam, url, aspectRatio, isAnimated);
    if (isParam) {
      this.carrouselImageToParam(carrouselIndex, url);
    } else {
      this.carrouselImageToLocal(carrouselIndex, url, aspectRatio, isAnimated);
    }
  }

  switchCarrouselImageSourceType(
    carrouselIndex: number,
    currentType: ImageSourceType,
    previousType: ImageSourceType,
  ): void {
    if (currentType === previousType) {
      return;
    }
    if (currentType === 'local') {
      this.carrouselImageToLocal(carrouselIndex, '', '', false);
    }
    if (currentType === 'param') {
      this.carrouselImageToParam(carrouselIndex, '');
    }
  }

  private carrouselImageToLocal(
    carrouselIndex: number,
    url: string,
    aspectRatio: string,
    isAnimated: boolean,
  ): void {
    this.parameters = this.parameters.filter((p) => {
      return p.mappingKey !== this.getCardData(carrouselIndex).contents.hero?.key;
    });
    this.getCardData(carrouselIndex).setUrl(url, aspectRatio, isAnimated);
    this.getCardData(carrouselIndex).setMappingKey('');
  }

  private carrouselImageToParam(carrouselIndex: number, key: string): void {
    this.getCardData(carrouselIndex).setUrl(PARAM_PLACEHOLDER.image, '1:1', false);
    const customHeroParameterIndex = this.parameters.findIndex(
      (p) =>
        isCustomParameter(p) &&
        p.mappingKey === this.getCardData(carrouselIndex).contents.hero?.key,
    );
    if (customHeroParameterIndex !== -1) {
      // Update the existing parameter
      this.parameters[customHeroParameterIndex].key = key;
    } else {
      // Create a new parameter
      const mappingKey = generateMappingKey('customHero');
      this.getCardData(carrouselIndex).setMappingKey(mappingKey);
      this.parameters.push(createCustomParameter(key, mappingKey));
    }
  }

  /**
   * convert button to uri button.
   * all previous data will be lost.
   */
  convertToURI(carrouselIndex: number, index: number): void {
    const footerButton = this.getCardData(carrouselIndex).getFooterButton(index);
    const uriButton = createUriButton({
      label: footerButton.action.label,
      key: generateMappingKey('standardLink'),
    });
    this.getCardData(carrouselIndex).setFooterButton(index, uriButton.button);
    this.parameters.push(uriButton.parameter);
  }

  /**
   * convert button to customUri button.
   * all previous data will be lost.
   */
  convertToCustomUri(carrouselIndex: number, index: number): void {
    const footerButton = this.getCardData(carrouselIndex).getFooterButton(index);
    const customURIButton = createCustomParameterUriButton({
      label: footerButton.action.label,
      key: generateMappingKey('customUri'),
    });
    this.getCardData(carrouselIndex).setFooterButton(index, customURIButton.button);
    this.parameters.push(customURIButton.parameter);
  }

  convertToShareButton(carrouselIndex: number, index: number): void {
    const footerButton = this.getCardData(carrouselIndex).getFooterButton(index);
    const shareButton = createShareButtonParameterButton({
      label: footerButton.action.label,
      key: generateMappingKey('shareButton'),
    });
    this.getCardData(carrouselIndex).setFooterButton(index, shareButton.button);
    this.parameters.push(shareButton.parameter);
  }

  /**
   * convert button to message button.
   * all previous data will be lost.
   */
  convertToMessage(carrouselIndex: number, index: number): void {
    const footerButton = this.getCardData(carrouselIndex).getFooterButton(index);
    const messageButton = createMessageButton({
      label: footerButton.action.label,
      key: generateMappingKey('messageButton'),
    });
    this.getCardData(carrouselIndex).setFooterButton(index, messageButton.button);
    this.actions.push(messageButton.action);
  }

  hasParameterButtonType(buttonType: CardButtonType): boolean {
    return ['uri', 'customUri', 'shareButton'].includes(buttonType);
  }

  /**
   * Remove parameter by key when switching action type
   *
   * @param key the original key of footer button
   * @param previousType previous type of footer button
   */
  removeOldParameters(key: string, previousType: CardButtonType): void {
    if (previousType === 'message') {
      this.actions = this.actions.filter((a) => a.key !== key);
    }
    if (this.hasParameterButtonType(previousType)) {
      this.parameters = this.parameters.filter((p) => p.key !== key && p.mappingKey !== key);
    }
  }

  /**
   * Set tagList to specify id action
   *
   * @param key The unique key by uri or message
   * @param tagList
   */
  setTag(key: string, tagList: string[]): void {
    this.parameters.forEach((para) => {
      if (
        (para.data.function === 'link' ||
          (isMappingKey('shareButton', key) && para.data.function === 'sharelink')) &&
        para.key === key
      ) {
        para.data.tag_list = tagList;
      }
    });
  }

  setNotificationText(text: string): void {
    this.data.notification_text = text;
  }

  setMessage(carrouselIndex: number, index: number, value: string): void {
    const button = this.getCardData(carrouselIndex).getFooterButton(index);
    if (button.action.type === 'message') {
      const action = this.findActionByKey(button.key);
      if (action) {
        action.trigger_code = value;
      }
      button.action.text = value;
    }
  }

  setUri(
    carrouselIndex: number,
    index: number,
    value: string,
    openExternal: boolean,
    utm: Utm,
  ): void {
    const key = this.getFooterKey(carrouselIndex, index);
    const parameter = this.findParameterByKey(key);
    if (parameter && isLinkParameter(parameter)) {
      parameter.data.url = value;
      parameter.data.open_external_browser = openExternal;
      parameter.data.utm_source = utm.utm_source;
      parameter.data.utm_medium = utm.utm_medium;
      parameter.data.utm_content = utm.utm_content;
      parameter.data.utm_campaign = utm.utm_campaign;
    }
  }

  setCustomUri(carrouselIndex: number, index: number, value: string): void {
    const key = this.getFooterKey(carrouselIndex, index);
    const parameter = this.findParameterByMappingKey(key);
    if (parameter) {
      parameter.key = value;
    }
  }

  setShareButton(carrouselIndex: number, index: number, value: string): void {
    const key = this.getFooterKey(carrouselIndex, index);
    const parameter = this.findParameterByKey(key);
    if (parameter && isShareButtonParameter(parameter)) {
      parameter.data.name = value;
    }
  }

  getFooterKey(carrouselIndex: number, index: number): string {
    return this.getCardData(carrouselIndex).getFooterButton(index).key;
  }

  private findActionByKey(key: string): TagAction | undefined {
    return this.actions.find((action) => action.key === key);
  }

  private findParameterByKey(key: string): CarrouselParameter | undefined {
    return this.parameters.find((param) => param.key === key);
  }
  private findParameterByMappingKey(key: string): CarrouselParameter | undefined {
    return this.parameters.find((param) => param.mappingKey === key);
  }
}
