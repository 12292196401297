import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TAG_COUNT_LIMIT_DEFAULT } from 'AppConstants';

import type { UseMutationResult } from '@tanstack/react-query';
import type { Dispatch, SetStateAction } from 'react';
import type { TagPickerProps } from 'shared/components/TagPicker/types';
import type { TagCreateArgs, TagCreateResponse } from 'shared/types/tag';

import { useHandler } from 'hooks/useEventCallback';
import { useWatch } from 'hooks/useWatch';
import { useTagMetadata } from 'shared/hooks/tag/useTagMetadata';
import { useTagCreateMutation } from 'shared/hooks/tag/useTagMutations';
import { useMessage } from 'shared/hooks/ui/useMessage';

/**
 * Business logic for the tag picker; handles tag query and create
 */
export const useTagPicker = ({
  tagList,
  onTagListChange,
  tagCountLimit = TAG_COUNT_LIMIT_DEFAULT,
  disabled,
}: Pick<TagPickerProps, 'tagList' | 'onTagListChange' | 'tagCountLimit' | 'disabled'>): {
  selectedTagList: number[];
  setSelectedTagList: Dispatch<SetStateAction<number[]>>;
  isOverTagCountLimit: boolean;
  changeTagHandler: (tagNames: string[]) => void;
  createTagHandler: (tagName: string) => void;
  tagCreateMutation: UseMutationResult<TagCreateResponse, unknown, TagCreateArgs>;
  tagPickerAddHandler: () => void;
  tagPickerCancelHandler: () => void;
} => {
  const { t } = useTranslation();

  const { message } = useMessage();

  const tagCreateMutation = useTagCreateMutation();

  const { getTagIdsByNames } = useTagMetadata({ enabled: true });

  /**
   * These are the tags selected by the user within the TagPicker UI
   */
  const [selectedTagList, setSelectedTagList] = useState<number[]>([]);

  /**
   * Business logic related to the tag count limit
   */
  const isOverTagCountLimit = useMemo(
    () =>
      tagCountLimit !== Infinity ? selectedTagList.length + tagList.length >= tagCountLimit : false,
    [tagCountLimit, tagList.length, selectedTagList.length],
  );

  /**
   * Handler function for changing/updating a tag list (used with the TagSelect component)
   */
  const canChangeTagList = useCallback(
    (changedTagCount: number) =>
      tagCountLimit !== Infinity ? changedTagCount + tagList.length <= tagCountLimit : true,
    [tagCountLimit, tagList.length],
  );

  const changeTagHandler = useCallback(
    (tagNames: string[]) => {
      const changedTagIds = getTagIdsByNames(tagNames);

      if (canChangeTagList(changedTagIds.length)) {
        setSelectedTagList(changedTagIds);
      } else {
        message.error(t('common.reachTagCountLimit', { count: tagCountLimit }));
      }
    },
    [canChangeTagList, getTagIdsByNames, message, t, tagCountLimit],
  );

  /**
   * Clear tags when the tag picker is disabled
   */
  const memorizedChangeHandler = useHandler(onTagListChange);

  useWatch([disabled], () => {
    if (!disabled) return;
    memorizedChangeHandler([]);
  });

  /**
   * Handler function for creating a tag
   */
  const createTagHandler = useCallback(
    (tagName: string) => {
      tagCreateMutation.mutate(
        {
          name: tagName,
          available_days: null,
        },
        {
          onSuccess: (data: TagCreateResponse) => {
            setSelectedTagList((state) => [...state, data.id]);
          },
          onError: () => {
            message.error(t('common.failToCreate'));
          },
        },
      );
    },
    [message, t, tagCreateMutation],
  );

  /**
   * Handler function for clicking "add" on the TagPicker UI
   */
  const tagPickerAddHandler = useCallback(() => {
    onTagListChange([...tagList, ...selectedTagList]);
    setSelectedTagList([]);
  }, [onTagListChange, selectedTagList, tagList]);

  /**
   * Handler function for clicking "cancel" on the TagPicker UI
   */
  const tagPickerCancelHandler = useCallback(() => {
    setSelectedTagList([]);
  }, []);

  return {
    selectedTagList,
    setSelectedTagList,
    isOverTagCountLimit,
    changeTagHandler,
    createTagHandler,
    tagCreateMutation,
    tagPickerAddHandler,
    tagPickerCancelHandler,
  };
};
