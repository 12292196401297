import { memo, useMemo } from 'react';

import type { EditorDataType } from 'components/LineMessageEditor/models/templateDataAndTypes/types';
import type { LineMessageEditorInput } from 'shared/components/Editor/LineMessageEditor/types';

import { useQuickReplyItems } from 'components/LineMessageEditor/components/QuickReplyEditor/hooks/useQuickReply';
import { useEditorStateArray } from 'components/LineMessageEditor/hooks/useEditorStateArray';
import { Context, dispatch, useStore } from 'components/LineMessageEditor/models/models';
import { LineMessagePreview } from 'components/LineMessageEditor/previewComponents/LineMessagePreview';
import { LineMessageEditorPreview } from 'shared/components/Editor/LineMessageEditor/components/LineMessageEditorPreview';
import { transformServerMessageToUiFormat } from 'shared/components/Editor/LineMessageEditor/hooks/useLineMessageEditorForm';
import { useShowNewLineMessageEditor } from 'shared/components/Editor/LineMessageEditor/hooks/useShowNewLineMessageEditor';

interface LineEditorLiveViewProps {
  initialData?: EditorDataType[];
  headingMessage?: React.ReactNode;
}

export const LineEditorLiveView = memo(function LineEditorLiveView({
  initialData,
  headingMessage,
}: LineEditorLiveViewProps) {
  const showNewLineMessageEditor = useShowNewLineMessageEditor();
  const messages = useMemo(
    () =>
      transformServerMessageToUiFormat(
        initialData ? (initialData as LineMessageEditorInput['messages']) : [],
      ),
    [initialData],
  );

  return showNewLineMessageEditor ? (
    <LineMessageEditorPreview messages={messages} headingMessage={headingMessage} />
  ) : (
    <LineEditorLiveViewOld initialData={initialData} headingMessage={headingMessage} />
  );
});

export const LineEditorLiveViewOld = memo(function LineEditorLiveViewOld({
  initialData,
  headingMessage,
}: LineEditorLiveViewProps) {
  const store = useStore((d) => {
    return { ...d };
  });
  const [editorStateArray] = useEditorStateArray(initialData);
  const { quickReplyItems } = useQuickReplyItems(initialData);

  return (
    <Context.Provider value={{ store, dispatch }}>
      <LineMessagePreview
        editorStateArray={editorStateArray}
        ml="0px"
        quickReplyItems={quickReplyItems}
        disableAnimation={true}
        headingMessage={headingMessage}
      />
    </Context.Provider>
  );
});
