import LogoutOutlined from '@ant-design/icons/LogoutOutlined';
import PlayCircleOutlined from '@ant-design/icons/PlayCircleOutlined';
import { transparentize } from 'polished';
import { useCallback, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { VideoEditorData } from 'components/LineMessageEditor/models/templateDataAndTypes/video';

import { StyledIcon, Text } from 'components';
import { Flex } from 'components/layoutUtils';
import { transformKey } from 'components/LineMessageEditor/utils/transformKey';
import { Tooltip, TooltipDescription } from 'shared/components/Tooltip';
import { styled } from 'shared/utils/styled';
import { theme } from 'theme';

type VideoPreviewProps = {
  message: VideoEditorData;
};

const VideoWrapper = styled.div`
  position: relative;
  margin: 10px 0 0 16px;
  width: 90%;
  border-radius: 6px;
`;

const Video = styled.video`
  width: 100%;
`;

const VideoCover = styled.img`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
`;

const VideoMask = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: ${transparentize(0.5, theme.colors.neutral010)};
  > div {
    color: ${theme.colors.white001};
  }
`;

export const VideoPreview = ({ message }: VideoPreviewProps) => {
  const {
    data: { video },
    parameters,
  } = message;
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isEnded, setIsEnded] = useState(false);

  const { t } = useTranslation();
  const handlePlayClick = useCallback(async () => {
    await videoRef.current?.play();
    setIsPlaying(true);
  }, []);

  if (video.expired) {
    return null;
  }

  const url =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    message.parameters.find((d: any) => d.key === transformKey(video.externalLink.linkUri))?.data
      .url ?? '';

  return (
    <VideoWrapper>
      <Video
        ref={videoRef}
        src={video.originalContentUrl}
        controls={isPlaying}
        onEnded={() => {
          setIsPlaying(false);
          setIsEnded(true);
        }}
        data-test="video-preview-local-upload-video"
      />
      {!isPlaying && !isEnded ? <VideoCover src={video.previewImageUrl} alt="" /> : null}
      {!isPlaying ? (
        <VideoMask>
          <Flex
            mb="11px"
            alignItems="center"
            onClick={handlePlayClick}
            style={{ cursor: 'pointer' }}
          >
            <StyledIcon
              mr="10px"
              p={0}
              width={14}
              height={14}
              fontSize={14}
              component={<PlayCircleOutlined />}
            />
            <Text fontSize={13}>
              <Trans>common.play</Trans>
            </Text>
          </Flex>
          {video.externalLink.label !== '' ? (
            <Flex alignItems="center">
              <a
                href={parameters[0].data.url}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: theme.colors.white001 }}
              >
                <StyledIcon
                  mr="10px"
                  p={0}
                  width={14}
                  height={14}
                  fontSize={14}
                  component={<LogoutOutlined />}
                />
                <Tooltip
                  title={
                    <TooltipDescription>
                      <Trans values={{ textBeforeColon: t('common.openUrl'), textAfterColon: url }}>
                        common.textsWithColon
                      </Trans>
                    </TooltipDescription>
                  }
                >
                  <Text
                    fontSize={13}
                    onClick={() => {
                      if (url) window.open(url);
                    }}
                  >
                    {video.externalLink.label}
                  </Text>
                </Tooltip>
              </a>
            </Flex>
          ) : null}
        </VideoMask>
      ) : null}
    </VideoWrapper>
  );
};
