import GiftOutlined from '@ant-design/icons/GiftOutlined';
import cn from 'classnames';
import { useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { FlexBox, FlexText } from '@line/bot-sdk';
import type { PrizeRedeemData } from 'components/LineMessageEditor/models/templateDataAndTypes/prizeRedeem';

import { Box, Flex, StyledIcon, Text } from 'components';
import { Context } from 'components/LineMessageEditor/models';
import * as Validator from 'lib/validator';
import { theme } from 'theme';
import { lowerFirst } from 'utils/string/changeCase';

import { RowToolBox } from '../RowToolBox';
import * as S from '../Styled';

import { PrizeRedeemPopContent } from './PrizeRedeemPopContent';

interface PrizeRedeemEditorProps {
  message: PrizeRedeemData;
  rowIndex: number;
}

export const PrizeRedeemEditor = ({ message, rowIndex }: PrizeRedeemEditorProps) => {
  const { t } = useTranslation();
  const { store, dispatch } = useContext(Context);
  const [isOpen, setIsOpen] = useState(false);
  const {
    format: { prizeName },
    data: {
      contents: { body },
    },
  } = message;

  const redeemText = ((body?.contents[1] as FlexBox).contents[0] as FlexText).text ?? '';
  const hintText = (body?.contents[2] as FlexText).text ?? '';

  return (
    <Box mb="32px">
      <Validator.FormPopover
        popupAlign={{
          offset: [100, 0],
        }}
        placement="right"
        popupVisible={isOpen}
        popup={<PrizeRedeemPopContent message={message} rowIndex={rowIndex} />}
        onPopupVisibleChange={(v) => setIsOpen(v)}
      >
        <S.ImageWrapper
          onClick={() => {
            dispatch('setEditingRow', { rowIndex });
            setIsOpen(!isOpen);
          }}
          width="231px"
          height="auto"
          minHeight="128px"
          className={cn({
            'is-error': false,
            'is-drag-mode': store.isDragMode,
          })}
          data-test="prize-redeem-editor-popover"
        >
          <Flex alignItems="center" flexDirection="column" textAlign="center" p="20px 0">
            {prizeName ? (
              <>
                <Box p="0 15px" color={theme.colors.neutral010} fontWeight="bold">
                  {prizeName}
                </Box>
                <Box
                  mt="12px"
                  width="176px"
                  height="36px"
                  lineHeight="36px"
                  background={theme.colors.blue006}
                  color={theme.colors.white001}
                  borderRadius="18px"
                >
                  {redeemText}
                </Box>
                <Box mt="8px" lineHeight="1.2" color={theme.colors.neutral006} fontSize="12px">
                  {hintText}
                </Box>
              </>
            ) : (
              <>
                <StyledIcon
                  component={<GiftOutlined />}
                  color={theme.colors.neutral008}
                  fontSize="16px"
                />
                <Box mt="10px" color={theme.colors.neutral008}>
                  <Text sentenceCase={true}>
                    <Trans values={{ field: lowerFirst(t('glossary.prize')) }}>
                      common.selectField
                    </Trans>
                  </Text>
                </Box>
              </>
            )}
          </Flex>
          <RowToolBox
            moduleType={store.editorData[rowIndex].module_id}
            onRemoveClick={() => {
              dispatch('deleteEditorData', { rowIndex });
            }}
          />
        </S.ImageWrapper>
      </Validator.FormPopover>
    </Box>
  );
};
