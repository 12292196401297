import { Children, useEffect, useState } from 'react';

import type { BoxProps } from 'components/layoutUtils';

import { splitToRenderLines, toReaderFriendlyFormat } from 'components/CodePreview/helper';
import { Box } from 'components/layoutUtils';
import { styled } from 'shared/utils/styled';
import { theme } from 'theme';

interface CodePreviewProps extends BoxProps {
  code?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
}

export const CodePreview = ({ code, data, ...boxProps }: CodePreviewProps) => {
  const [codeLines, setCodeLines] = useState<string[]>([]);

  useEffect(() => {
    if (data) {
      setCodeLines(toReaderFriendlyFormat(data));
    }
    if (code) {
      setCodeLines(splitToRenderLines(code));
    }
  }, [code, data]);

  return (
    <Box {...boxProps}>
      <table>
        <tbody>
          {Children.toArray(
            codeLines.map((row, index) => (
              <tr>
                <LineNumber lineNumber={index + 1} />
                <CodeInner>{row}</CodeInner>
              </tr>
            )),
          )}
        </tbody>
      </table>
    </Box>
  );
};

const StyleLineNumber = styled.td`
  width: 1%;
  min-width: 50px;
  padding-right: 10px;
  padding-left: 10px;
  font-family: ui-monospace, SFMono-Regular, 'SF Mono', Menlo, Consolas, 'Liberation Mono',
    monospace;
  font-size: 12px;
  line-height: 20px;
  color: ${theme.colors.neutral006};
  text-align: right;
  white-space: nowrap;
  vertical-align: top;
  cursor: pointer;
  user-select: none;

  &::before {
    content: attr(data-line-number);
  }
`;

const LineNumber = ({ lineNumber }: { lineNumber: number }) => (
  <StyleLineNumber data-line-number={lineNumber} />
);

const CodeInner = styled.td`
  padding-right: 10px;
  padding-left: 10px;
  line-height: 20px;
  vertical-align: top;
  overflow: visible;
  font-family: ui-monospace, SFMono-Regular, 'SF Mono, Menlo', Consolas, 'Liberation Mono',
    monospace;
  font-size: 12px;
  word-wrap: normal;
  white-space: pre;
`;
