import DollarOutlined from '@ant-design/icons/DollarOutlined';
import ShopOutlined from '@ant-design/icons/ShopOutlined';
import { useTranslation } from 'react-i18next';

import type { InfoCardProps } from 'components/Card';
import type { BoxProps } from 'components/layoutUtils';

import { CardWrapper, InfoCard } from 'components/Card';
import { ClicksSvg, ShopCartSvg } from 'icons';
import { styled } from 'shared/utils/styled';
import { theme } from 'theme';

type WidgetTemplate = Pick<ReportWidgetProps, WidgetTemplateType>;

interface ReportWidgetProps extends Omit<InfoCardProps, 'color' | 'bgColor'>, BoxProps {
  iconColor: string;
  iconBgColor: string;
}

type ReportNewWidgetWithoutProps = Omit<ReportWidgetProps, WidgetTemplateType>;

type WidgetTemplateType = 'icon' | 'iconColor' | 'iconBgColor' | 'mainText';

export const ReportWidget = ({
  icon,
  iconBgColor,
  mainText,
  mainTextTooltip,
  iconColor,
  memoText,
  counts,
  prefix,
  suffix,
  ...wrapperLayoutProps
}: ReportWidgetProps) => {
  const { t } = useTranslation();
  const cardProps = {
    icon,
    color: iconColor,
    mainText: t(mainText),
    mainTextTooltip,
    bgColor: iconBgColor,
    memoText,
    counts,
    prefix,
    suffix,
  };

  return (
    <CardWrapper {...wrapperLayoutProps}>
      <InfoCard {...cardProps} />
    </CardWrapper>
  );
};

const clickWidgetData: WidgetTemplate = {
  mainText: 'glossary.clickCount',
  icon: <ClicksSvg />,
  iconBgColor: theme.colors.blue002,
  iconColor: theme.colors.blue006,
};

const ordersWidgetData: WidgetTemplate = {
  mainText: 'glossary.orders',
  icon: <ShopOutlined />,
  iconBgColor: theme.colors.cyan001,
  iconColor: theme.colors.cyan006,
};

const revenueWidgetData: WidgetTemplate = {
  mainText: 'glossary.revenue',
  icon: <DollarOutlined />,
  iconBgColor: theme.colors.yellow001,
  iconColor: theme.colors.yellow006,
};

const addToCartWidgetData: WidgetTemplate = {
  mainText: 'common.addToCart',
  icon: <ShopCartSvg />,
  iconBgColor: theme.colors.purple001,
  iconColor: theme.colors.purple006,
};

export const ReportClickWidget = (props: ReportNewWidgetWithoutProps) => {
  return <ReportWidget {...props} {...clickWidgetData} />;
};
export const ReportOrdersWidget = (props: ReportNewWidgetWithoutProps) => {
  return <ReportWidget {...props} {...ordersWidgetData} />;
};
export const ReportRevenueWidget = (props: ReportNewWidgetWithoutProps) => {
  return <ReportWidget {...props} {...revenueWidgetData} />;
};
export const ReportAddToCartWidget = (props: ReportNewWidgetWithoutProps) => {
  return <ReportWidget {...props} {...addToCartWidgetData} />;
};

export const ReportWidgetRow = styled.div`
  display: flex;
  border-top: 1px solid ${theme.colors.neutral003};
  padding-top: 20px;
  margin-top: 16px;
  padding-bottom: 24px;

  > div {
    flex: 1;
    &:not(:first-child) {
      margin-left: 20px;
    }
  }
`;
