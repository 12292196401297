import { styled } from 'shared/utils/styled';

const LineMessageEditorPreviewContainer = styled.div`
  position: sticky;
  top: 0;
  width: ${({ theme }) => theme.sizes.linePreviewWidth};
  height: ${({ theme }) => theme.sizes.linePreviewHeight};
  perspective: 500px;
  vertical-align: top;
  display: inline-block;
`;

const LineMessageEditorPreview = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border: 2px solid ${({ theme }) => theme.colors.neutral003};
  border-radius: 50px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.white001};
  padding: 72px 20px;
  &::before {
    content: '';
    width: 71px;
    height: 6px;
    background-color: ${({ theme }) => theme.colors.neutral002};
    position: absolute;
    top: 35px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 6.5px;
  }
  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 48px;
    border: 6px solid ${({ theme }) => theme.colors.neutral001};
    z-index: 0;
  }
`;

const LineMessageEditorPreviewContent = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
  word-break: normal;
  background-color: #d6deea;
  overflow: auto;
  box-sizing: border-box;
  border-radius: 4px;
`;

const LineMessageEditorPreviewHomeButton = styled.div`
  width: 50px;
  height: 50px;
  position: absolute;
  left: 50%;
  bottom: 12px;
  transform: translateX(-50%);
  box-sizing: border-box;
  border-radius: 50%;
  border: 8px solid ${({ theme }) => theme.colors.neutral001};
`;

export const LineMessageEditorPreviewWrapper = ({
  children,
  ...props
}: {
  children: React.ReactNode;
}) => {
  return (
    <LineMessageEditorPreviewContainer {...props}>
      <LineMessageEditorPreview>
        <LineMessageEditorPreviewContent>{children}</LineMessageEditorPreviewContent>
        <LineMessageEditorPreviewHomeButton />
      </LineMessageEditorPreview>
    </LineMessageEditorPreviewContainer>
  );
};
