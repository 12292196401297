import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';

import type { UseQueryResult } from '@tanstack/react-query';
import type { TagMetadataQueryData, TagMetadataResponse } from 'shared/types/tag';

import { tagQueryKeys } from 'shared/queries/tag';
import { getTagMetadataItem } from 'shared/services/tag';
import { normalizer } from 'utils/array/arrayNormalizer';

/**
 * Note 1: the `select` function normalizes the API response
 * Note 2: there is room to optimize this query and make it less aggressive
 */
export const useTagMetadataQuery = ({
  enabled,
}: {
  enabled: boolean;
}): UseQueryResult<TagMetadataQueryData> =>
  useQuery({
    queryKey: tagQueryKeys.metadata(),
    queryFn: async ({ signal }) => getTagMetadataItem(signal),
    select: useCallback(
      (data: TagMetadataResponse) => ({
        items: data,
        records: normalizer(data, 'id').byId,
      }),
      [],
    ),
    enabled,
    keepPreviousData: true,
  });
