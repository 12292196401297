import type { FlexBox, FlexImage } from '@line/bot-sdk';
import type { ImageMap } from 'components/LineMessageEditor/models/templateDataAndTypes/imageMap';
import type { ImageMapFlex } from 'components/LineMessageEditor/models/templateDataAndTypes/imageMapFlex';
import type { ImageMapActionType } from 'components/LineMessageEditor/models/templateDataAndTypes/types';

import { LineFieldType } from 'components/LineMessageEditor/models/templateDataAndTypes/LineFieldType';

export const parseFlexToImageMap = (imageMapFlex: ImageMapFlex): ImageMap => {
  const { module_id, data, actions, format, ...rest } = imageMapFlex;
  const ratio =
    parseFloat(
      (data.contents?.body?.contents?.[0] as FlexImage).aspectRatio?.split(':')[0] ?? '1',
    ) /
    parseFloat((data.contents?.body?.contents?.[0] as FlexImage).aspectRatio?.split(':')[1] ?? '1');

  return {
    module_id: LineFieldType.ImageMap,
    data: {
      base_url: (data.contents?.body?.contents?.[0] as FlexImage).url ?? '',
      notification_text: data.notification_text,
      animated: !!(data.contents?.body?.contents?.[0] as FlexImage).animated,
      base_size: {
        width: 1040,
        height: 1040 / ratio,
      },
      actions: parseFlexActionToImageMapAction(
        ratio,
        (data.contents?.body?.contents.filter((d) => d.type === 'box') as FlexBox[]) ?? [],
        format,
      ),
    },
    actions: [],
    format,
    ...rest,
  };
};

const parseFlexActionToImageMapAction = (
  ratio: number,
  flexBox: FlexBox[],
  format: ImageMap['format'],
): ImageMapActionType[] => {
  return flexBox.map((d) => {
    if (d.action?.type === 'message') {
      return {
        type: 'message',
        text: d.action.text,
        key: `message${format.tagId++}`,
        area: {
          x: (parseFloat(d.offsetStart?.replace('%', '') ?? '0') / 100) * 1040,
          y: (parseFloat(d.offsetTop?.replace('%', '') ?? '0') / 100) * (1040 / ratio),
          width: (parseFloat(d.width?.replace('%', '') ?? '0') / 100) * 1040,
          height: (parseFloat(d.height?.replace('%', '') ?? '0') / 100) * (1040 / ratio),
        },
      };
    } else
      return {
        type: 'uri',
        // @ts-expect-error `uri` does not exist on PostbackAction
        linkUri: d.action.uri,
        area: {
          x: (parseFloat(d.offsetStart?.replace('%', '') ?? '0') / 100) * 1040,
          y: (parseFloat(d.offsetTop?.replace('%', '') ?? '0') / 100) * (1040 / ratio),
          width: (parseFloat(d.width?.replace('%', '') ?? '0') / 100) * 1040,
          height: (parseFloat(d.height?.replace('%', '') ?? '0') / 100) * (1040 / ratio),
        },
      };
  });
};
