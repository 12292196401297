import LogoutOutlined from '@ant-design/icons/LogoutOutlined';
import PlayCircleOutlined from '@ant-design/icons/PlayCircleOutlined';
import { transparentize } from 'polished';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { VideoModule } from 'shared/components/Editor/LineMessageEditor/types';

import { StyledIcon } from 'components';
import { ExternalLink } from 'components/ExternalLink';
import { isCustomParameterWithLink } from 'shared/components/Editor/LineMessageEditor/utils/helper';
import { Tooltip, TooltipDescription } from 'shared/components/Tooltip';
import { Typography } from 'shared/components/Typography';
import { styled } from 'shared/utils/styled';
import { theme } from 'theme';

const VideoContainer = styled.div`
  position: relative;
  border-radius: 6px;
`;

const Video = styled.video`
  width: 100%;
`;

const VideoCover = styled.img`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
`;

const VideoMask = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: ${transparentize(0.5, theme.colors.neutral010)};
  > div {
    color: ${theme.colors.white001};
  }
`;

export const LineMessageEditorVideoModulePreview = ({ message }: { message: VideoModule }) => {
  const {
    data: { video },
  } = message;

  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isEnded, setIsEnded] = useState(false);

  const { t } = useTranslation();

  const handlePlayClick = useCallback(async () => {
    await videoRef.current?.play();
    setIsPlaying(true);
  }, []);

  if (video.expired || video.previewImageUrl === '') {
    return null;
  }

  const url = isCustomParameterWithLink(message.parameters[0]?.data)
    ? message.parameters[0].data.url
    : '';

  return (
    <VideoContainer>
      <Video
        ref={videoRef}
        src={video.originalContentUrl}
        controls={isPlaying}
        onEnded={() => {
          setIsPlaying(false);
          setIsEnded(true);
        }}
        data-test="video-preview-local-upload-video"
      />
      {!isPlaying && !isEnded ? <VideoCover src={video.previewImageUrl} alt="" /> : null}
      {!isPlaying ? (
        <VideoMask>
          <div
            style={{
              cursor: 'pointer',
              marginBottom: '11px',
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={handlePlayClick}
          >
            <StyledIcon
              mr="10px"
              p={0}
              width={14}
              height={14}
              fontSize={14}
              component={<PlayCircleOutlined />}
            />
            <Typography variant="body">{t('common.play')}</Typography>
          </div>
          {video.externalLink.label !== '' ? (
            <ExternalLink
              href={url}
              style={{
                color: theme.colors.white001,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <StyledIcon
                  mr="10px"
                  p={0}
                  width={14}
                  height={14}
                  fontSize={14}
                  component={<LogoutOutlined />}
                />
                <Tooltip
                  title={
                    <TooltipDescription>
                      {t('common.textsWithColon', {
                        textBeforeColon: t('common.openUrl'),
                        textAfterColon: url,
                      })}
                    </TooltipDescription>
                  }
                >
                  <Typography variant="body">{video.externalLink.label}</Typography>
                </Tooltip>
              </div>
            </ExternalLink>
          ) : null}
        </VideoMask>
      ) : null}
    </VideoContainer>
  );
};
