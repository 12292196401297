import { PRIZE_API_PATH } from 'modules/Prize/constants';

import type { Report } from 'components/Report';
import type { ExportTaskMessage } from 'components/Types';
import type {
  CreatePrizePayload,
  DeleteCodesResponse,
  MyCouponsMetrics,
  Prize,
  PrizeDetail,
  PrizeFormValues,
  PrizeList,
  PrizePurchasedState,
  PrizeRecords,
} from 'modules/Prize/types';
import type { DeferFn, PromiseFn } from 'react-async';
import type { AsyncTask } from 'shared/models/common';

import { AuthAxios as axios } from 'lib/axios';
import { dayjs } from 'lib/dayjs';
import { i18n } from 'modules/G11n/i18n';
import {
  BarcodeType,
  isGameCoupon,
  isIntegrationCoupon,
  isNewParameters,
  isOfflineCoupon,
  isOnlineCoupon,
  isPointsCoupon,
  PrizePointsDefaultValues,
} from 'modules/Prize/utils';
import { stripSpaces } from 'utils/string/whiteSpaces';
import { replaceUrlOrigin } from 'utils/url/replaceUrlOrigin';

export const dataToFormValue = (prizeDetail: PrizeDetail): PrizeFormValues => {
  const {
    name,
    validity_ended_at,
    category,
    code_mode,
    file_id,
    fixed_code,
    messages,
    tags,
    redeem_tags,
    image_url,
    report,
    description,
  } = prizeDetail;

  /**
   * This conversion function supports the new (2022) and old, deprecated API
   * When the old API is disabled remove the `PrizeDeprecatedParameters` type
   */
  return {
    prizeName: name,
    validityEndDate: dayjs(validity_ended_at),
    prizeCategory: category,
    redemptionDetail: {
      redemptionMode: code_mode,
      fileId: file_id,
      uploadedCodeCount: report.total_count,
      fixedCode: fixed_code,
      showBarcode: isOfflineCoupon(prizeDetail)
        ? prizeDetail.parameter.settings.show_barcode
        : false,
      showRedeemButton: isOfflineCoupon(prizeDetail)
        ? prizeDetail.parameter.settings.show_redeem_button
        : true,
      barcodeType: isOfflineCoupon(prizeDetail)
        ? (prizeDetail.parameter.settings.barcode_type ?? BarcodeType['Code 128'])
        : BarcodeType['Code 128'],
    },
    messages: messages ?? undefined,
    gameCoupon: {
      id: isGameCoupon(prizeDetail)
        ? isNewParameters(prizeDetail.parameter)
          ? prizeDetail.parameter.settings.activity_id
          : prizeDetail.parameter.activity_id
        : 0,
      gameId: isGameCoupon(prizeDetail)
        ? isNewParameters(prizeDetail.parameter)
          ? prizeDetail.parameter.settings.game_id
          : prizeDetail.parameter.game_id
        : '',
      times: isGameCoupon(prizeDetail)
        ? isNewParameters(prizeDetail.parameter)
          ? prizeDetail.parameter.settings.increase_quota
          : prizeDetail.parameter.increase_quota
        : PrizePointsDefaultValues.min,
    },
    integrationCoupon: {
      reason: isIntegrationCoupon(prizeDetail)
        ? isNewParameters(prizeDetail.parameter)
          ? prizeDetail.parameter.settings.note
          : prizeDetail.parameter.note
        : '',
      point: isIntegrationCoupon(prizeDetail)
        ? isNewParameters(prizeDetail.parameter)
          ? prizeDetail.parameter.settings.point_quantity
          : prizeDetail.parameter.point_quantity
        : PrizePointsDefaultValues.min,
      validity: (() => {
        if (!isIntegrationCoupon(prizeDetail)) {
          return dayjs().add(7, 'days');
        }
        if (isNewParameters(prizeDetail.parameter)) {
          return prizeDetail.parameter.settings.point_expired_datetime
            ? dayjs(prizeDetail.parameter.settings.point_expired_datetime)
            : dayjs().add(7, 'days');
        }
        return prizeDetail.parameter.point_expired_datetime
          ? dayjs(prizeDetail.parameter.point_expired_datetime)
          : dayjs().add(7, 'days');
      })(),
    },
    pointsCoupon: {
      memo: isPointsCoupon(prizeDetail) ? prizeDetail.parameter.settings.points_memo : '',
      count: isPointsCoupon(prizeDetail)
        ? prizeDetail.parameter.settings.points_count
        : PrizePointsDefaultValues.min,
      expireDateActive: isPointsCoupon(prizeDetail)
        ? Boolean(prizeDetail.parameter.settings.points_expire_date)
        : false,
      expireDate: isPointsCoupon(prizeDetail)
        ? prizeDetail.parameter.settings.points_expire_date
          ? dayjs(prizeDetail.parameter.settings.points_expire_date)
          : dayjs().add(7, 'days')
        : dayjs().add(7, 'days'),
      category: isPointsCoupon(prizeDetail) ? prizeDetail.parameter.settings.points_category : '',
    },
    tags: tags.map((tag) => tag.id),
    redeemTags: redeem_tags.map((tag) => tag.id),
    action: {
      actionType: isOnlineCoupon(prizeDetail)
        ? 'uri'
        : isIntegrationCoupon(prizeDetail)
          ? 'message'
          : isPointsCoupon(prizeDetail)
            ? prizeDetail.parameter.settings.action.type
            : 'uri',
      actionLabel: isOnlineCoupon(prizeDetail)
        ? isNewParameters(prizeDetail.parameter)
          ? prizeDetail.parameter.settings.action.label
          : prizeDetail.parameter.button_name
        : isIntegrationCoupon(prizeDetail)
          ? isNewParameters(prizeDetail.parameter)
            ? prizeDetail.parameter.settings.action.label
            : prizeDetail.parameter.action.label
          : isPointsCoupon(prizeDetail)
            ? prizeDetail.parameter.settings.action.label
            : '',
      actionUri: isOnlineCoupon(prizeDetail)
        ? isNewParameters(prizeDetail.parameter)
          ? (prizeDetail.parameter.settings.action.uri ?? '')
          : prizeDetail.parameter.button_url
        : isPointsCoupon(prizeDetail)
          ? (prizeDetail.parameter.settings.action.uri ?? '')
          : '',
      actionText: isIntegrationCoupon(prizeDetail)
        ? isNewParameters(prizeDetail.parameter)
          ? prizeDetail.parameter.settings.action.text
          : prizeDetail.parameter.action.text
        : isPointsCoupon(prizeDetail)
          ? (prizeDetail.parameter.settings.action.text ?? '')
          : '',
      openExternalBrowser: isOnlineCoupon(prizeDetail)
        ? isNewParameters(prizeDetail.parameter)
          ? Boolean(prizeDetail.parameter.settings.action.open_external_browser)
          : Boolean(prizeDetail.parameter.open_external_browser)
        : isPointsCoupon(prizeDetail)
          ? Boolean(prizeDetail.parameter.settings.action.open_external_browser)
          : false,
      utm: {
        source: isOnlineCoupon(prizeDetail)
          ? isNewParameters(prizeDetail.parameter)
            ? (prizeDetail.parameter.settings.action.utm_source ?? '')
            : prizeDetail.parameter.utm_source
          : isPointsCoupon(prizeDetail)
            ? (prizeDetail.parameter.settings.action.utm_source ?? '')
            : '',
        medium: isOnlineCoupon(prizeDetail)
          ? isNewParameters(prizeDetail.parameter)
            ? (prizeDetail.parameter.settings.action.utm_medium ?? '')
            : prizeDetail.parameter.utm_medium
          : isPointsCoupon(prizeDetail)
            ? (prizeDetail.parameter.settings.action.utm_medium ?? '')
            : '',
        campaign: isOnlineCoupon(prizeDetail)
          ? isNewParameters(prizeDetail.parameter)
            ? (prizeDetail.parameter.settings.action.utm_campaign ?? '')
            : prizeDetail.parameter.utm_campaign
          : isPointsCoupon(prizeDetail)
            ? (prizeDetail.parameter.settings.action.utm_campaign ?? '')
            : '',
        content: isOnlineCoupon(prizeDetail)
          ? isNewParameters(prizeDetail.parameter)
            ? (prizeDetail.parameter.settings.action.utm_content ?? '')
            : prizeDetail.parameter.utm_content
          : isPointsCoupon(prizeDetail)
            ? (prizeDetail.parameter.settings.action.utm_content ?? '')
            : '',
      },
    },
    bindingActive: isPointsCoupon(prizeDetail) ? Boolean(prizeDetail.parameter.binding) : false,
    bindingMessage: isPointsCoupon(prizeDetail)
      ? (prizeDetail.parameter.binding?.message ??
        i18n.t('prizeManagement.pointsBindingMessageDefaultText'))
      : i18n.t('prizeManagement.pointsBindingMessageDefaultText'),
    bindingAction: {
      actionType: isPointsCoupon(prizeDetail)
        ? (prizeDetail.parameter.binding?.action.type ?? 'uri')
        : 'uri',
      // parameter.binding?.action.type ?? 'uri',
      actionLabel: isPointsCoupon(prizeDetail)
        ? (prizeDetail.parameter.binding?.action.label ?? '')
        : '',
      // parameter.binding?.action.label ?? '',
      actionUri: isPointsCoupon(prizeDetail)
        ? (prizeDetail.parameter.binding?.action.uri ?? '')
        : '',
      // parameter.binding?.action?.uri ?? '',
      actionText: isPointsCoupon(prizeDetail)
        ? (prizeDetail.parameter.binding?.action.text ?? '')
        : '',
      // parameter.binding?.action?.text ?? '',
      openExternalBrowser: isPointsCoupon(prizeDetail)
        ? Boolean(prizeDetail.parameter.binding?.action.open_external_browser)
        : false,
      // parameter.binding?.action?.open_external_browser ?? false,
      utm: {
        source: isPointsCoupon(prizeDetail)
          ? (prizeDetail.parameter.binding?.action.utm_source ?? '')
          : '',
        medium: isPointsCoupon(prizeDetail)
          ? (prizeDetail.parameter.binding?.action.utm_medium ?? '')
          : '',
        campaign: isPointsCoupon(prizeDetail)
          ? (prizeDetail.parameter.binding?.action.utm_campaign ?? '')
          : '',
        content: isPointsCoupon(prizeDetail)
          ? (prizeDetail.parameter.binding?.action.utm_content ?? '')
          : '',
      },
    },
    imageUrl: image_url,
    description,
  };
};

export const formValueToPayload = ({
  botId,
  prizeName,
  prizeCategory,
  validityEndDate,
  redemptionDetail,
  gameCoupon,
  imageUrl,
  description,
  tags,
  redeemTags,
  messages,
  integrationCoupon,
  pointsCoupon,
  action,
  bindingActive,
  bindingMessage,
  bindingAction,
}: Partial<PrizeFormValues> & { botId: number }): CreatePrizePayload => {
  const redemptionMode = redemptionDetail?.redemptionMode ?? 'no_code';

  // TODO: this can be removed after all deprecated data is migrated
  const withNewApi = false;

  const getPrizeDetailParameter = (): CreatePrizePayload['parameter'] => {
    switch (prizeCategory) {
      case 'offline_coupon': {
        return {
          settings: {
            show_barcode:
              redemptionMode !== 'no_code' ? Boolean(redemptionDetail?.showBarcode) : false,
            show_redeem_button: Boolean(redemptionDetail?.showRedeemButton),
            barcode_type:
              redemptionMode !== 'no_code' && Boolean(redemptionDetail?.showBarcode)
                ? redemptionDetail?.barcodeType
                : undefined,
          },
        };
      }
      case 'online_coupon':
        if (withNewApi) {
          return {
            settings: {
              action: {
                type: 'uri',
                label: action?.actionLabel ?? '',
                uri: action?.actionUri ?? '',
                utm_source: action?.utm?.source ?? '',
                utm_medium: action?.utm?.medium ?? '',
                utm_campaign: action?.utm?.campaign ?? '',
                utm_content: action?.utm?.content ?? '',
                open_external_browser: action?.openExternalBrowser ?? false,
              },
            },
          };
        }
        return {
          button_name: action?.actionLabel ?? '',
          button_url: action?.actionUri ?? '',
          open_external_browser: action?.openExternalBrowser ?? false,
          utm_source: action?.utm?.source ?? '',
          utm_medium: action?.utm?.medium ?? '',
          utm_campaign: action?.utm?.campaign ?? '',
          utm_content: action?.utm?.content ?? '',
        };
      case 'game_coupon':
        if (withNewApi) {
          return {
            settings: {
              activity_id: gameCoupon?.id ?? 0,
              game_id: gameCoupon?.gameId ?? '',
              increase_quota: gameCoupon?.times ?? PrizePointsDefaultValues.min,
            },
          };
        }
        return {
          activity_id: gameCoupon?.id ?? 0,
          game_id: gameCoupon?.gameId ?? '',
          increase_quota: gameCoupon?.times ?? PrizePointsDefaultValues.min,
        };
      case 'integration_coupon':
        if (withNewApi) {
          return {
            settings: {
              note: integrationCoupon?.reason ?? '',
              point_quantity: integrationCoupon?.point ?? PrizePointsDefaultValues.min,
              point_expired_datetime: integrationCoupon?.validity.toISOString() ?? '',
              action: {
                type: 'message',
                label: action?.actionLabel ?? '',
                text: action?.actionText ?? '',
              },
            },
          };
        }
        return {
          note: integrationCoupon?.reason ?? '',
          point_quantity: integrationCoupon?.point ?? PrizePointsDefaultValues.min,
          point_expired_datetime: integrationCoupon?.validity.toISOString() ?? '',
          action: {
            type: 'message',
            label: action?.actionLabel ?? '',
            text: action?.actionText ?? '',
          },
        };
      case 'points_coupon':
        return {
          settings: {
            points_memo: pointsCoupon?.memo ?? '',
            points_count: pointsCoupon?.count ?? 0,
            points_expire_date: pointsCoupon?.expireDateActive
              ? (pointsCoupon?.expireDate.toISOString() ?? '')
              : '',
            points_category: pointsCoupon?.category ?? '',
            action: {
              type: action?.actionType ?? 'uri',
              label: action?.actionLabel ?? '',
              text: action?.actionText ?? '',
              uri: action?.actionUri ?? '',
              open_external_browser: action?.openExternalBrowser ?? false,
              utm_source: action?.utm?.source ?? '',
              utm_medium: action?.utm?.medium ?? '',
              utm_campaign: action?.utm?.campaign ?? '',
              utm_content: action?.utm?.content ?? '',
            },
          },
          binding: bindingActive
            ? {
                message: bindingMessage ?? '',
                action: {
                  type: bindingAction?.actionType ?? 'uri',
                  label: bindingAction?.actionLabel ?? '',
                  text: bindingAction?.actionText ?? '',
                  uri: bindingAction?.actionUri ?? '',
                  open_external_browser: bindingAction?.openExternalBrowser ?? false,
                  utm_source: bindingAction?.utm?.source ?? '',
                  utm_medium: bindingAction?.utm?.medium ?? '',
                  utm_campaign: bindingAction?.utm?.campaign ?? '',
                  utm_content: bindingAction?.utm?.content ?? '',
                },
              }
            : null,
        };
      default:
        return {};
    }
  };

  return {
    bot_id: botId,
    name: prizeName ?? '',
    category: prizeCategory ?? 'online_coupon',
    purchase_started_at: null,
    purchase_ended_at: null,
    validity_ended_at: validityEndDate?.toISOString() ?? '',
    code_mode: redemptionMode,
    fixed_code: redemptionDetail?.fixedCode ?? '',
    file_id: redemptionDetail?.fileId ?? '',
    image_url: imageUrl ?? '',
    description: description ?? '',
    parameter: getPrizeDetailParameter(),
    tags: tags ?? [],
    redeem_tags: redeemTags ?? [],
    messages: messages ?? null,
  };
};

/* eslint-disable @typescript-eslint/explicit-module-boundary-types -- Too many async functions to provide types for in this file */
const createPrize = (data: CreatePrizePayload) =>
  axios
    .post<PrizeDetail & Partial<AsyncTask>>(`${PRIZE_API_PATH}/setting/`, data)
    .then((resp) => resp.data);

export const createPrizeDefer: DeferFn<PrizeDetail & Partial<AsyncTask>> = ([data]) =>
  createPrize(data);

const updatePrize = (id: number, data: Partial<CreatePrizePayload>) =>
  axios.patch<PrizeDetail>(`${PRIZE_API_PATH}/setting/${id}/`, data).then((resp) => resp.data);

export const updatePrizeDefer: DeferFn<PrizeDetail> = ([id, data]) => updatePrize(id, data);

const deletePrize = (id: number) =>
  axios.delete<null>(`${PRIZE_API_PATH}/setting/${id}/`).then((resp) => resp.data);

export const deletePrizeDefer: DeferFn<null> = ([id]) => deletePrize(id);

const getPrize = (id: number | string) =>
  axios.get<PrizeDetail>(`${PRIZE_API_PATH}/setting/${id}/`).then((resp) => resp.data);

export const getPrizeAsync: PromiseFn<PrizeDetail> = ({ id }) => getPrize(id);

export const getPrizes = (botId: number, searchValue: string) =>
  axios
    .get<PrizeList>(`${PRIZE_API_PATH}/setting/`, {
      params: {
        bot_id: botId,
        name_q: stripSpaces(searchValue) !== '' ? searchValue : undefined,
      },
    })
    .then((resp) => resp.data);

const getMorePrizes = (next: string) =>
  axios.get<PrizeList>(replaceUrlOrigin(next)).then((resp) => resp.data);

export const getMorePrizesDefer: DeferFn<PrizeList> = ([next]) => getMorePrizes(next);

const getAllPrizes = async (botId: number) => {
  let prizeList: Prize[] = [];
  const getRestPrizes = async (path: string) => {
    const { results, next } = await getMorePrizes(path);
    prizeList = [...prizeList, ...results];
    if (next) {
      await getRestPrizes(next);
    }
  };
  const { results, next } = await getPrizes(botId, '');
  prizeList = [...prizeList, ...results];
  if (next) {
    await getRestPrizes(next);
  }
  return prizeList;
};

export const getAllPrizesAsync: PromiseFn<Prize[]> = ({ botId }) => getAllPrizes(botId);

export const getAllPrizesDefer: DeferFn<Prize[]> = ([botId]) => getAllPrizes(botId);

const getPurchasedState = (botId: number) =>
  axios
    .get<PrizePurchasedState[]>(`${PRIZE_API_PATH}/setting/category/`, {
      params: {
        bot_id: botId,
      },
    })
    .then((resp) => resp.data);

export const getPurchasedStateAsync: PromiseFn<PrizePurchasedState[]> = ({ botId }) =>
  getPurchasedState(botId);

const getMyCouponsMetrics = (botId: number) =>
  axios
    .get<MyCouponsMetrics>(`${PRIZE_API_PATH}/my-coupons/`, {
      params: {
        bot_id: botId,
      },
    })
    .then((resp) => resp.data);

export const getMyCouponsMetricsAsync: PromiseFn<MyCouponsMetrics> = ({ botId }) =>
  getMyCouponsMetrics(botId);

export type GetPrizeRecordsParam = {
  codeQuery?: string;
  uniqueUrlQuery?: string;
  memberNameQuery?: string;
  lineUidQuery?: string;
  customerIdQuery?: string;
  page: number;
  pageSize: number;
};

const getPrizeRecords = (
  prizeId: number,
  {
    codeQuery,
    uniqueUrlQuery,
    memberNameQuery,
    lineUidQuery,
    customerIdQuery,
    page,
    pageSize,
  }: GetPrizeRecordsParam,
) =>
  axios
    .get<PrizeRecords>(`${PRIZE_API_PATH}/setting/${prizeId}/prize/`, {
      params: {
        code_q: codeQuery === '' ? undefined : codeQuery,
        unique_url_q: uniqueUrlQuery === '' ? undefined : uniqueUrlQuery,
        member_name_q: memberNameQuery === '' ? undefined : memberNameQuery,
        line_id_q: lineUidQuery === '' ? undefined : lineUidQuery,
        bind_id_q: customerIdQuery === '' ? undefined : customerIdQuery,
        page,
        page_size: pageSize,
      },
    })
    .then((resp) => resp.data);

export const getPrizeRecordsAsync: PromiseFn<PrizeRecords> = ({
  prizeId,
  codeQuery,
  uniqueUrlQuery,
  memberNameQuery,
  lineUidQuery,
  customerIdQuery,
  page,
  pageSize,
}) =>
  getPrizeRecords(prizeId, {
    codeQuery,
    uniqueUrlQuery,
    memberNameQuery,
    lineUidQuery,
    customerIdQuery,
    page,
    pageSize,
  });

const addPrizeCodes = (prizeId: number, { fileId }: { fileId: string }) =>
  axios
    .post<AsyncTask>(`${PRIZE_API_PATH}/setting/${prizeId}/prize/create/`, {
      file_id: fileId,
    })
    .then((resp) => resp.data);

export const addPrizeCodesDefer: DeferFn<AsyncTask> = ([prizeId, fileId]) =>
  addPrizeCodes(prizeId, { fileId });

const deletePrizeCodes = (prizeId: number, { codes }: { codes: number[] }) =>
  axios
    .post<DeleteCodesResponse>(`${PRIZE_API_PATH}/setting/${prizeId}/prize/delete/`, {
      prizes: codes,
    })
    .then((resp) => resp.data);

export const deletePrizeCodesDefer: DeferFn<DeleteCodesResponse> = ([prizeId, codes]) =>
  deletePrizeCodes(prizeId, { codes });

const exportPrizeRecords = (prizeId: number) =>
  axios
    .post<ExportTaskMessage>(`${PRIZE_API_PATH}/setting/${prizeId}/prize/export/`)
    .then((resp) => resp.data);

export const exportPrizeRecordsDefer: DeferFn<ExportTaskMessage> = ([prizeId]) =>
  exportPrizeRecords(prizeId);

export const getPerformance = (prizeId: number) =>
  axios.get<Report>(`${PRIZE_API_PATH}/setting/${prizeId}/report/`).then((resp) => resp.data);
