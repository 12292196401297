export const ShopCartSvg = () => (
  <svg width="1em" height="1em" viewBox="0 0 96 96" version="1.1">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Artboard" transform="translate(0.000000, -192.000000)" fillRule="nonzero">
        <g id="icon_shop-cart" transform="translate(0.000000, 192.000000)">
          <rect fill="#000000" opacity="0" x="0" y="0" width="96" height="96" />
          <path
            d="M24.16,47.12 C24.1039078,46.9274049 24.0624563,46.7308448 24.036,46.532 L16.756,12 L7.99999997,12 C6.57079176,12.0002188 5.25005529,11.237871 4.53538802,10.0001768 C3.82072075,8.76248264 3.82072075,7.23751739 4.53538802,5.99982323 C5.25005529,4.76212906 6.57079176,3.99978123 7.99999997,4.00000003 L20,4.00000003 C21.8908781,3.99989904 23.5235337,5.32387677 23.914,7.17400003 L27.04,22 L82.008,22 C87.848,22 91.886,26.9660001 90.722,32.69 L85.118,60.27 C84.078,65.392 79.2099999,69.364 73.996,69.334 L37.96,69.13 C32.76,69.1 27.93,65.124 26.878,60.016 L24.158,47.12 L24.16,47.12 Z M32.994,50 L34.714,58.4 C35.002,59.814 36.5900001,61.122 38.004,61.13 L74.04,61.334 C75.428,61.3419999 76.996,60.062 77.278,58.678 L79.04,50 L32.994,50 Z M31.356,42 L80.666,42 L82.882,31.098 C83.0420001,30.318 82.782,30 82.008,30 L28.9,30 L31.354,42 L31.356,42 Z M33.9999997,92 C31.1418752,92.0000001 28.500859,90.4752087 27.0717965,88.0000001 C25.6427341,85.5247914 25.6427341,82.4752086 27.0717965,79.9999999 C28.500859,77.5247913 31.1418752,75.9999999 33.9999997,76 C38.418278,76 42,79.581722 41.9999997,84 C42,88.418278 38.418278,92 33.9999997,92 L33.9999997,92 Z M77.9999997,92 C75.1418752,92.0000001 72.5008589,90.4752087 71.0717965,88.0000001 C69.6427341,85.5247914 69.6427341,82.4752086 71.0717965,79.9999999 C72.5008589,77.5247913 75.1418752,75.9999999 77.9999997,76 C82.418278,76 86,79.581722 85.9999997,84 C86,88.418278 82.418278,92 77.9999997,92 L77.9999997,92 Z"
            id="Shape"
            fill="currentColor"
          />
        </g>
      </g>
    </g>
  </svg>
);
