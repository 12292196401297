import AppstoreOutlined from '@ant-design/icons/AppstoreOutlined';
import cn from 'classnames';
import throttle from 'lodash/throttle';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import {
  APNG_FILE_LIMIT,
  IMAGEMAP_CAROUSEL_UI_IMAGE_WIDTH,
  MAX_APNG_FILE_COUNT,
  MAX_IMAGE_DIMENSIONS_VALUE,
  MAX_SIZE,
} from 'components/LineMessageEditor/constants';

import type {
  ImageMapCarousel,
  ImageMapCarouselItem,
} from 'components/LineMessageEditor/models/templateDataAndTypes/imageMapCarousel';
import type { ChangeEvent, UIEvent } from 'react';

import { Body, Box, Loading, StyledIcon, Text } from 'components';
import { FileInput } from 'components/Input';
import { Flex } from 'components/layoutUtils';
import { useImageMapCarouselImageUpload } from 'components/LineMessageEditor/components/ImageMapCarouselEditor/hooks/useImageMapCarouselImageUpload';
import { ImageMapActiveBlock } from 'components/LineMessageEditor/components/ImageMapCarouselEditor/ImageMapActiveBlock';
import { ImageMapPopContent } from 'components/LineMessageEditor/components/ImageMapCarouselEditor/ImageMapPopContent';
import {
  CarouselAddZone,
  CarouselScrollWrapper,
  CarouselThumbnail,
  CarouselThumbnailWrapper,
  ImageMapCarouselScaledImage,
  ImageWrapper,
  UploadImageLabel,
} from 'components/LineMessageEditor/components/Styled';
import { Context } from 'components/LineMessageEditor/models';
import { countAPNGFileInEditorModule } from 'components/LineMessageEditor/utils/countAPNGFileInEditorModule';
import {
  getActionBlockStylesFromOffsets,
  parseWidthAndHeight,
} from 'components/LineMessageEditor/utils/imageMapCarousel';
import { useAuth } from 'context/authContext';
import { useIsMounted } from 'hooks/useIsMounted';
import { LineMessageHorizontalDrag, LineMessageImagemap } from 'icons';
import * as Validator from 'lib/validator';
import { uploadImageHelper } from 'lib/validator/helper/validatorHelpers';
import { generateDataTestId } from 'shared/components/Editor/LineMessageEditor/utils/generateDataTestId';
import { useMessage } from 'shared/hooks/ui/useMessage';
import { theme } from 'theme';
import { checkAPNG } from 'utils/detection/checkAPNG';
import { lowerFirst } from 'utils/string/changeCase';

import { ImageEditOverlay, RowToolBox } from '..';

const IMAGE_WIDTH_LIMIT = '4096px x 4096px';
const CAROUSEL_CARD_ID_PREFIX = 'imagemap-carousel-card-';

const CARD_MARGIN_LEFT = 40;
const ADD_CARD_WIDTH = 83;

function generateCarouselCardId(rowIndex: number, index: number) {
  return `${CAROUSEL_CARD_ID_PREFIX}-${rowIndex}-${index}`;
}

function scrollToCarouselTarget(index: number, rowIndex: number) {
  const target = document.getElementById(generateCarouselCardId(rowIndex, index));
  if (target) {
    window.requestAnimationFrame(() => {
      target.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
    });
  }
}

interface SortablePointProps {
  rowIndex: number;
  activeIndex: number;
  carrouselIndex: number;
  imgUrl: string;
  setActiveIndex: (index: number) => void;
}

const SortablePoint = (props: SortablePointProps) => {
  return (
    <CarouselThumbnailWrapper
      onClick={() => {
        scrollToCarouselTarget(props.carrouselIndex, props.rowIndex);
      }}
      key={props.carrouselIndex}
    >
      <Text fontSize={14} lineHeight="20px" mb="1px" color={theme.colors.neutral005}>
        {props.carrouselIndex + 1}
      </Text>
      <CarouselThumbnail active={props.carrouselIndex === props.activeIndex}>
        {!!props.imgUrl ? (
          <img
            style={{
              objectFit: 'contain',
            }}
            src={props.imgUrl}
            width={24}
            height={24}
            alt=""
          />
        ) : (
          <LineMessageImagemap width={20} height={20} />
        )}
      </CarouselThumbnail>
      <StyledIcon
        color={theme.colors.neutral007}
        width={24}
        height={24}
        className="icon-horizontal-drag"
        component={<LineMessageHorizontalDrag />}
        style={{ cursor: 'ew-resize' }}
      />
    </CarouselThumbnailWrapper>
  );
};

const SortableItem = SortableElement((props: SortablePointProps) => <SortablePoint {...props} />);

interface SortableContainerProps {
  contents: ImageMapCarouselItem[];
  rowIndex: number;
  activeIndex: number;
  setActiveIndex: (index: number) => void;
}

const SortableList = SortableContainer((props: SortableContainerProps) => {
  return (
    <Flex pt={32} justifyContent="center">
      {props.contents.map((content, index) => {
        return (
          <SortableItem
            rowIndex={props.rowIndex}
            carrouselIndex={index}
            activeIndex={props.activeIndex}
            setActiveIndex={props.setActiveIndex}
            imgUrl={content.body.contents[0].url}
            index={index}
            key={`Row-${props.rowIndex}-ImageMapCarousel-${index}-Drag`}
          />
        );
      })}
    </Flex>
  );
});

interface ImageMapEditorProps {
  notificationText: string;
  isCustomBlocks: boolean;
  message: ImageMapCarouselItem;
  rowIndex: number;
  carouselIndex: number;
  activeBlockIndex: number;
  parameters: ImageMapCarousel['parameters'];
}

export const ImageMapEditor = ({
  notificationText,
  isCustomBlocks,
  message,
  rowIndex,
  carouselIndex,
  activeBlockIndex,
  parameters,
}: ImageMapEditorProps) => {
  const { t } = useTranslation();

  const { store, dispatch } = useContext(Context);
  const authContext = useAuth();
  const isMounted = useIsMounted();
  const { message: messageToast } = useMessage();

  const wrapperEl = useRef(null);
  const imageBoxEl = useRef(null);
  const uploadImageFile = useRef<File>();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const messageImageUrl = useMemo(() => message.body.contents[0].url, [message.body.contents]);

  const { aspectRatioValue } = useMemo(() => {
    const [imageMapImage] = message.body.contents;
    return parseWidthAndHeight(imageMapImage.aspectRatio ?? '1:1');
  }, [message.body.contents]);

  const actionBlockStyles = useMemo(() => {
    const [_, ...imageMapBlocks] = message.body.contents;
    return imageMapBlocks.map(({ width = '', height = '', offsetStart = '', offsetTop = '' }) => {
      return getActionBlockStylesFromOffsets(
        {
          width: IMAGEMAP_CAROUSEL_UI_IMAGE_WIDTH,
          height: IMAGEMAP_CAROUSEL_UI_IMAGE_WIDTH / aspectRatioValue,
        },
        { width, height, offsetStart, offsetTop },
      );
    });
  }, [aspectRatioValue, message.body.contents]);

  const { update, invalid, validate, value } = Validator.useField({
    name: uploadImageHelper({
      rowIndex: rowIndex,
      editorType: 'ImageMapCarouselEditor',
      entityKey: '',
      editorIndex: carouselIndex,
    }),
    rules: [Validator.Rules.required],
    checkOnChange: true,
    value: messageImageUrl === '' ? '' : 'imageUpload',
    enableReinitialize: true,
  });

  const onImageUploaded = (uploadUrl: string, originalSize: { width: number; height: number }) => {
    const newAspectRatio = `${originalSize.width}:${originalSize.height}`;
    const { aspectRatioValue: newAspectRatioValue } = parseWidthAndHeight(newAspectRatio);

    dispatch('setImageMapCarouselImageAspectRatio', {
      rowIndex,
      carouselIndex,
      aspectRatio: newAspectRatio,
    });

    // Wait for the UI animation to complete
    setTimeout(() => {
      if (isMounted() === false) return;

      update('imageUpload');
      dispatch('setImageMapCarouselImageUrl', {
        rowIndex,
        url: uploadUrl,
        carouselIndex,
        resetContent: aspectRatioValue !== newAspectRatioValue,
      });
      setIsOpen(true);
      setIsLoading(false);
    }, 300);
  };

  const uploadImage = useImageMapCarouselImageUpload(uploadImageFile, onImageUploaded);

  const onUploadImage = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files !== null && e.currentTarget.files.length === 1) {
      const file = e.currentTarget.files[0];

      try {
        const isApng = await checkAPNG(file);

        if (isApng) {
          if (countAPNGFileInEditorModule(store.editorData[rowIndex]) >= MAX_APNG_FILE_COUNT) {
            messageToast.warning(t('message.apngCountLimit'));
            return;
          }
          if (file.size > APNG_FILE_LIMIT) {
            messageToast.error(t('common.fileSizeExceedWithSize', { size: '300 kb' }));
            return;
          }
        }

        const img = new Image();
        img.src = window.URL.createObjectURL(file);
        uploadImageFile.current = file;

        img.onload = async () => {
          if (file.size > MAX_SIZE) {
            messageToast.error(t('common.fileSizeExceedWithSize', { size: '1 mb' }));
            return;
          }

          if (
            img.naturalWidth > MAX_IMAGE_DIMENSIONS_VALUE ||
            img.naturalHeight > MAX_IMAGE_DIMENSIONS_VALUE
          ) {
            messageToast.error(t('common.maximumSize', { size: IMAGE_WIDTH_LIMIT }));
            return;
          }

          if (img.naturalHeight / img.naturalWidth > 3) {
            messageToast.error(t('validation.cardEditor.imageSize'));
            return;
          }

          dispatch('setImageMapCarouselImageAnimated', {
            rowIndex,
            carouselIndex,
            isAnimated: isApng,
          });
          setIsLoading(true);
          await uploadImage();
        };
      } catch (error) {
        messageToast.error(t('common.failToFetchModule', { module: t('glossary.image') }));
      }
    }
  };

  useEffect(() => {
    validate(value);
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ImageWrapper
      ref={wrapperEl}
      onClick={() => {
        dispatch('setEditingRow', { rowIndex });
      }}
      width={`${IMAGEMAP_CAROUSEL_UI_IMAGE_WIDTH}px`}
      style={{
        ...(messageImageUrl ? null : { minHeight: IMAGEMAP_CAROUSEL_UI_IMAGE_WIDTH }),
        height: messageImageUrl
          ? IMAGEMAP_CAROUSEL_UI_IMAGE_WIDTH / aspectRatioValue
          : IMAGEMAP_CAROUSEL_UI_IMAGE_WIDTH,
      }}
      className={cn({
        'is-error': Validator.isInvalid(invalid),
        'is-drag-mode': store.isDragMode,
      })}
    >
      {messageImageUrl !== '' ? (
        <>
          <ImageMapCarouselScaledImage src={messageImageUrl} ref={imageBoxEl} />{' '}
          {isOpen ? (
            <ImageMapActiveBlock
              activeBlockIndex={activeBlockIndex}
              actionBlockStyles={actionBlockStyles}
            />
          ) : null}
        </>
      ) : null}
      <UploadImageLabel type="imageMap" bgUrl="">
        <FileInput
          accept="image/jpeg,image/png"
          onChange={onUploadImage}
          data-test={generateDataTestId(
            'imagemap-editor-popover-local-upload-image-input',
            carouselIndex,
          )}
        />
        {isLoading ? <Loading /> : null}
      </UploadImageLabel>
      {messageImageUrl !== '' ? (
        <ImageEditOverlay
          index={carouselIndex}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          onUploadImage={onUploadImage}
          popOverProps={{
            title: <Body fontWeight={500}>{t('message.imageMapCarousel.settings')}</Body>,
            content: (
              <Box width="350px" maxHeight="540px" overflow="auto">
                <Validator.FieldScopeProvider>
                  <ImageMapPopContent
                    notificationText={notificationText}
                    isCustomBlocks={isCustomBlocks}
                    message={message}
                    rowIndex={rowIndex}
                    carouselIndex={carouselIndex}
                    activeBlockIndex={activeBlockIndex}
                    parameters={parameters}
                    imageMapRef={imageBoxEl}
                    currentChannel={authContext.state.currentChannel}
                  />
                </Validator.FieldScopeProvider>
              </Box>
            ),
          }}
        />
      ) : null}
      {!isLoading && messageImageUrl === '' ? (
        <Flex alignItems="center" flexDirection="column">
          <StyledIcon
            mb={2}
            component={<AppstoreOutlined />}
            fontSize={21}
            color={theme.colors.neutral006}
          />
          <Text color={theme.colors.neutral008} mb="8px">
            <Trans values={{ object: lowerFirst(t('glossary.image')) }}>common.uploadObject</Trans>
          </Text>
          <Text color={theme.colors.neutral007} textAlign="center" lineHeight="1.4">
            {t('message.imageMapCarousel.imageLimitWithApng', {
              fileSize: '1 mb',
              widthHeight: IMAGE_WIDTH_LIMIT,
            })}
          </Text>
        </Flex>
      ) : null}
      <RowToolBox
        moduleType={store.editorData[rowIndex].module_id}
        carouselIndex={carouselIndex}
        onRemoveClick={() => {
          dispatch('deleteImageMapCarouselItem', { rowIndex, carouselIndex });
        }}
      />
    </ImageWrapper>
  );
};

interface ImageMapCarouselProps {
  message: ImageMapCarousel;
  rowIndex: number;
}

export const ImageMapCarouselEditor = ({ message, rowIndex }: ImageMapCarouselProps) => {
  const { t } = useTranslation();

  const { dispatch } = useContext(Context);
  const { message: messageToast } = useMessage();

  const [activeIndex, setActiveIndex] = useState(0);

  const carouselCount = useMemo(
    () => message.data.contents.contents.length,
    [message.data.contents],
  );

  const calcActiveIndex = throttle((e: UIEvent<HTMLDivElement>) => {
    try {
      const eTarget = e.target as HTMLDivElement;
      setActiveIndex(
        Math.round(
          (eTarget.scrollLeft / (eTarget.scrollWidth - ADD_CARD_WIDTH - CARD_MARGIN_LEFT)) *
            carouselCount,
        ),
      );
    } catch (error) {
      setActiveIndex(0);
    }
  }, 150);

  return (
    <>
      <CarouselScrollWrapper
        onScroll={(e) => {
          e.persist();
          calcActiveIndex(e);
        }}
      >
        {message.data.contents.contents.map((imageMap, index) => {
          return (
            <Box
              id={generateCarouselCardId(rowIndex, index)}
              mr={`${CARD_MARGIN_LEFT}px`}
              key={`imageMapCarouselRow-${rowIndex}-${index}`}
              onClick={() => {
                setActiveIndex(index);
              }}
            >
              <ImageMapEditor
                notificationText={message.data.notification_text}
                isCustomBlocks={message.customBlocks[index]}
                message={imageMap}
                rowIndex={rowIndex}
                carouselIndex={index}
                activeBlockIndex={message.activeBlockIndices[index]}
                parameters={message.parameters}
              />
            </Box>
          );
        })}
        <CarouselAddZone
          height={272}
          onClick={() => {
            if (carouselCount >= 10) {
              messageToast.warning(t('validation.carrousel.cardAmountLimit'));
              return;
            }
            dispatch('addImageMapCarouselItem', { rowIndex });
            setTimeout(() => {
              scrollToCarouselTarget(carouselCount, rowIndex);
            }, 0);
          }}
          data-test="add-imagemap-carousel"
        >
          +
        </CarouselAddZone>
      </CarouselScrollWrapper>
      <SortableList
        contents={message.data.contents.contents}
        rowIndex={rowIndex}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        distance={1}
        helperClass="sortableHelper"
        lockAxis="x"
        axis="x"
        onSortEnd={({ oldIndex, newIndex }) => {
          scrollToCarouselTarget(newIndex, rowIndex);
          dispatch('sortImageMapCarouselItem', {
            rowIndex,
            oldCarouselIndex: oldIndex,
            newCarouselIndex: newIndex,
          });
        }}
      />
    </>
  );
};
