import PictureOutlined from '@ant-design/icons/PictureOutlined';
import cn from 'classnames';
import debug from 'debug';
import { useContext, useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { MAX_IMAGE_SIZE } from 'components/LineMessageEditor/constants';

import type { ImageData } from 'components/LineMessageEditor/models/templateDataAndTypes/types';
import type { EditorType } from 'lib/validator/helper/validatorHelpers';
import type { ReactNode } from 'react';

import { Loading, StyledIcon, Text } from 'components';
import { FileInput } from 'components/Input';
import { Flex } from 'components/layoutUtils';
import { useImageEditorUploadImage } from 'components/LineMessageEditor/hooks/useImageEditorUploadImage';
import { Context } from 'components/LineMessageEditor/models';
import { isCarrouselDataRule } from 'components/LineMessageEditor/models/templateDataAndTypes/types';
import * as Validator from 'lib/validator';
import { uploadImageHelper } from 'lib/validator/helper/validatorHelpers';
import { theme } from 'theme';
import { lowerFirst } from 'utils/string/changeCase';

import * as S from './Styled';
import { RowToolBox } from '.';

const log = debug('imageEditor');

export interface ImageEditorProps {
  message: ImageData;
  rowIndex: number;
  /** Embed inside other module, default false */
  isEmbed?: boolean;
  /** For carrousel index */
  carrouselIndex?: number;
  /** For 彈性 */
  onUploadDone?: (param: { url: string; aspectRatio: string; isAnimated: boolean }) => void;
  /** insert element to hover layer */
  uploadedHoverLayerIconButton?: ReactNode;
  /** For 彈性 */
  onDelete?: () => void;
  /** required */
  required?: boolean;
  /** 覆用的 **/
  editorType?: EditorType;
}

export const ImageEditor = ({
  rowIndex,
  message,
  isEmbed = false,
  required = false,
  carrouselIndex,
  onUploadDone,
  uploadedHoverLayerIconButton,
  onDelete,
  editorType = 'ImageEditor',
}: ImageEditorProps) => {
  const { store, dispatch } = useContext(Context);
  const { t } = useTranslation();
  const row = useMemo(() => store.editorData[rowIndex], [rowIndex, store.editorData]);
  const { update, invalid, validate, value } = Validator.useField({
    name: uploadImageHelper({
      rowIndex: rowIndex,
      editorIndex: carrouselIndex,
      editorType,
      entityKey: '',
    }),
    rules: required ? [Validator.Rules.required] : [],
    checkOnChange: true,
    value: message.data.content_url === '' ? '' : 'imageUpload',
    enableReinitialize: true,
  });
  const { isLoading, handleUploadImage } = useImageEditorUploadImage({
    row,
    imageEditorProps: { rowIndex, carrouselIndex, editorType, onUploadDone },
    validatorProps: { update },
  });

  log(rowIndex, store);

  useEffect(() => {
    validate(value);
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <S.ImageWrapper
      className={cn({
        'is-error': Validator.isInvalid(invalid),
        'is-drag-mode': store.isDragMode,
      })}
      onClick={() => {
        dispatch('setEditingRow', { rowIndex });
      }}
      {...(isEmbed
        ? {
            mb: 0,
            width: 272,
            height: 281,
          }
        : { height: 156 })}
    >
      <S.UploadImageLabel type="image" bgUrl={message.data.content_url}>
        <FileInput
          data-test={`image-input-${rowIndex}-${carrouselIndex}`}
          accept="image/jpeg,image/png"
          onChange={handleUploadImage}
        />
        {isLoading ? <Loading /> : null}
      </S.UploadImageLabel>
      {message.data.content_url !== '' ? (
        <S.ImageCoverWrapper>
          <S.ImageHoverBoxIconWrapper>
            <StyledIcon mb="8px" component={<PictureOutlined />} display="inline-block" />
            <br />
            <Text position="relative">{t('message.paramImageEditor.editImage')}</Text>
            <FileInput accept="image/jpeg,image/png" onChange={handleUploadImage} />
          </S.ImageHoverBoxIconWrapper>
          {uploadedHoverLayerIconButton !== undefined ? uploadedHoverLayerIconButton : null}
        </S.ImageCoverWrapper>
      ) : null}
      {!isLoading && message.data.content_url === '' ? (
        <Flex alignItems="center" flexDirection="column">
          <StyledIcon
            mb={2}
            fontSize={19}
            width={21}
            height={21}
            color={theme.colors.neutral006}
            component={<PictureOutlined />}
          />
          <Text color={theme.colors.neutral008} mb="8px">
            <Trans values={{ object: lowerFirst(t('glossary.image')) }}>common.uploadObject</Trans>
          </Text>
          <Text mb="8px" color={theme.colors.neutral007} textAlign="center" lineHeight="1.4">
            {isEmbed
              ? t('message.card.imageLimitWithApng', {
                  fileSize: '1 mb',
                })
              : t('message.image.imageLimitWithApng', {
                  fileSize: '1 mb',
                  widthHeight: MAX_IMAGE_SIZE,
                })}
          </Text>
        </Flex>
      ) : null}
      <RowToolBox
        moduleType={store.editorData[rowIndex].module_id}
        onRemoveClick={() => {
          if (onDelete) {
            onDelete();
            return;
          }
          if (isCarrouselDataRule(row) && row.data.contents.length > 1) {
            dispatch('removeCarrousel', { rowIndex, index: carrouselIndex });
            return;
          }
          dispatch('deleteEditorData', { rowIndex });
        }}
      />
    </S.ImageWrapper>
  );
};
